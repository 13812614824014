// depenmdencies.
import { Fragment, useRef } from 'react'
import styled from 'styled-components'
// components.
import { Body } from '../../../Typography'
// utils.
import { theme } from '../../../../styleguide'
import { useDimensions } from '../../../../js/hooks'

// helpers.
const baseUnit = theme.fontSize.x16

const CHART_HEIGHT = baseUnit * 29
const CHART_LABEL_PLACEHOLDER = baseUnit * 8
const CHART_AXIS_PADDING = baseUnit * 1.5

const buildDataSource = (epp, items, dynamicColumns) => {
  const dynamicData = dynamicColumns.map(({ id, label }) => {
    const hasValue = items?.find(({ ref }) => ref.id === id) ?? {}
    return { label, amount: hasValue.value || 0 }
  })
  const dataArray = [...dynamicData, { label: 'Uso de EPP', amount: epp }]
  return dataArray.sort((a, b) => b.amount - a.amount).filter((el) => el !== null)
}

// partials.
const Wrapper = styled.div({
  alignItems: (props) => (props.isEmpty ? 'center' : 'flex-end'),
  display: 'flex',
  flexDirection: 'row',
  height: (props) => `${props.height}px`,
  justifyContent: (props) => (props.isEmpty ? 'center' : 'flex-start'),
  padding: (props) => (props.isEmpty ? 0 : `${CHART_AXIS_PADDING}px`),
  paddingBottom: (props) => (props.isEmpty ? 0 : `${CHART_LABEL_PLACEHOLDER}px`),
  position: 'relative',
  width: '100%',
})

const AxisY = styled.i({
  borderLeft: `1px solid ${theme.opacity.ash80}`,
  display: 'block',
  height: `calc(100% - ${CHART_LABEL_PLACEHOLDER - CHART_AXIS_PADDING}px)`,
  left: CHART_AXIS_PADDING,
  pointerEvents: 'none',
  position: 'absolute',
  top: 0,
})

const AxisX = styled.i({
  borderTop: `1px solid ${theme.opacity.ash80}`,
  display: 'block',
  left: 0,
  pointerEvents: 'none',
  position: 'absolute',
  bottom: CHART_LABEL_PLACEHOLDER,
  width: '100%',
})

const SemiAxisX = styled.i({
  borderTop: `1px solid ${theme.opacity.ash20}`,
  bottom: (props) => `${props.bottom}px`,
  display: 'block',
  left: CHART_AXIS_PADDING,
  pointerEvents: 'none',
  position: 'absolute',
  width: `calc(100% - ${CHART_AXIS_PADDING}px)`,
})

const Bar = styled.span({
  alignItems: 'flex-end',
  background: theme.colors.primary,
  display: 'flex',
  height: (props) => `${props.height}px` || 0,
  justifyContent: 'center',
  left: (props) => props.left || 0,
  margin: (props) => `0 ${props.width / (baseUnit / 2)}px`,
  position: 'relative',
  width: (props) => `${(props.width / (baseUnit / 2)) * 6}px`,
})

const StyledLabel = styled(Body)({
  bottom: 0,
  left: -(CHART_AXIS_PADDING * 1.5),
  position: 'absolute',
  transform: 'rotate(-60deg) translate(-72%, 0%)',
  width: CHART_LABEL_PLACEHOLDER,
})

const StyledAmount = styled(Body)({
  position: 'absolute',
  top: 0,
  transform: 'translateY(-100%)',
  width: '100%',
})

const StyledAxisLabel = styled(Body)({
  bottom: (props) => `${props.bottom}px`,
  left: 0,
  paddingRight: baseUnit / 4,
  pointerEvents: 'none',
  position: 'absolute',
  transform: 'translateY(64%)',
  width: CHART_AXIS_PADDING,
})

// main component.
const BarChart = ({ epp, items = [], dynamicColumns = [] }) => {
  const wrapperRef = useRef(null)

  const { isMobile, width: viewPortWidth } = useDimensions()

  const data = buildDataSource(epp, items, dynamicColumns)

  const CHART_WIDTH = wrapperRef?.current ? wrapperRef?.current?.clientWidth - 16 : 800
  const CHART_RESPONSIVE_HEIGHT =
    viewPortWidth <= 1440 ? CHART_HEIGHT * 1.2 : isMobile ? CHART_HEIGHT / 1.5 : CHART_HEIGHT

  const getMaxY = data
    .map(({ amount }) => amount)
    .reduce((max, count) => {
      return Math.max(max, count)
    }, -Infinity)

  const nexFive = Math.ceil(getMaxY / 5)
  const axisSteps = Array.from({ length: nexFive })

  const calcHeightPosition = (num) => {
    const availableHeight = CHART_RESPONSIVE_HEIGHT - CHART_LABEL_PLACEHOLDER - CHART_AXIS_PADDING
    const maxSteps = nexFive * 5
    const targetHeight = (num * 100) / maxSteps

    return (targetHeight * availableHeight) / 100
  }

  const processedItems = data.map(({ label, amount }) => {
    const height = calcHeightPosition(amount)
    return { label, amount, height }
  })

  return (
    <Wrapper ref={wrapperRef} isEmpty={!items.length} height={CHART_RESPONSIVE_HEIGHT}>
      {items.length ? (
        <>
          <AxisY />
          <AxisX />

          {axisSteps.map((el, i) => {
            const value = (i + 1) * 5
            const elBottom = CHART_LABEL_PLACEHOLDER + calcHeightPosition(value)

            return (
              <Fragment key={i}>
                <StyledAxisLabel size="minimal" align="right" color="grey" bottom={elBottom}>
                  {value}
                </StyledAxisLabel>
                <SemiAxisX bottom={elBottom} />
              </Fragment>
            )
          })}

          {processedItems.map(({ label, amount, height }, i) => {
            const width =
              CHART_WIDTH / processedItems.length < baseUnit * 6
                ? CHART_WIDTH / processedItems.length
                : baseUnit * 6

            return (
              <Bar key={i} left={width * i} height={height} width={width}>
                <StyledAmount type="caption" align="center">
                  {amount}
                </StyledAmount>
                <StyledLabel size="minimal" align="right" color="grey" nowrap>
                  {label}
                </StyledLabel>
              </Bar>
            )
          })}
        </>
      ) : (
        <Body color="grey" align="center">
          No se encontraron resultados.
        </Body>
      )}
    </Wrapper>
  )
}

export default BarChart
