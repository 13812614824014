// dependencies.
import styled from 'styled-components'
// components.
import { Headline, Label } from '../../components/Typography'
import { Icon } from '../../components/Icon'
// utils.
import setBackgroundColor from '../../js/setBackgroundColor'
import { useDimensions } from '../../js/hooks'
// constants.
import { PUBLIC } from '../../constants'
import { useAppSelector } from '../../js/store/hooks'
import { selectUserData } from '../../js/auth/authSelectors'

/**************************/
/*                        */
/*    Topbar Component    */
/*                        */
/**************************/

// partials.
const TopbarWrapper = styled.div(
  {
    alignItems: 'center',
    background: (props) => props.background,
    boxSizing: 'border-box',
    display: 'flex',
    gap: (props) => (props.isMobile ? '8px' : '16px'),
    height: (props) => (props.isMobile ? '48px' : '72px'),
    justifyContent: (props) => (props.isMobile ? 'space-between' : 'flex-start'),
    padding: (props) => (props.isMobile ? '8px 16px' : '16px'),
    position: 'fixed',
    width: '100%',
    zIndex: 0,
  },
  `@media print { display: none; }`
)

const MenuLogoWrapper = styled.div({
  alignItems: 'center',
  boxSizing: 'border-box',
  display: 'flex',
})

const StyledImg = styled.img({
  height: (props) => (props.isMobile ? '24px' : '32px'),
  margin: (props) => (props.condensed ? '0 8px 0 -8px' : 0),
  minWidth: '110px',
  objectFit: 'contain',
  objectPosition: 'right center',
  transition: 'all 100ms ease',
  width: '110px',
})

const ActionItem = styled.button({
  alignItems: 'center',
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  display: 'flex',
  gap: '16px',
})

const ActionWrapper = ({ children, onClick }) => {
  if (onClick)
    return (
      <ActionItem onClick={onClick} tabindex={0}>
        {children}
      </ActionItem>
    )
  return <>{children}</>
}

// main component.
const Topbar = ({
  label,
  iconL,
  iconR,
  actionLabel,
  condensed,
  onActionLPress,
  onActionRPress,
}) => {
  // contexts.
  const userData = useAppSelector(selectUserData)
  // hooks.
  const { isMobile } = useDimensions()

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // build props object.
  const commonProps = { color: 'white' }

  // return content.
  return (
    <TopbarWrapper
      background={setBackgroundColor(userData.isAuditor ? 'auditor' : userData.access)}
      isMobile={isMobile}
    >
      {!!iconL && (
        <ActionWrapper onClick={onActionLPress}>
          <Icon name={iconL} {...commonProps} size={16} />
        </ActionWrapper>
      )}

      <Headline size="smallest" {...commonProps}>
        {label}
      </Headline>

      <MenuLogoWrapper>
        <StyledImg src={`${PUBLIC}/logo.png`} condensed={condensed} isMobile={isMobile} />
      </MenuLogoWrapper>

      {(!!actionLabel || !!iconR) && (
        <ActionWrapper onClick={onActionRPress}>
          {!!actionLabel && <Label {...commonProps}>{actionLabel}</Label>}

          {!!iconR && <Icon name={iconR} {...commonProps} size={24} />}
        </ActionWrapper>
      )}
    </TopbarWrapper>
  )
}

export default Topbar
