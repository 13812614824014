// dependencies.
import { useEffect, useState } from 'react'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
// components.
import AdminTemplate from '../../../templates/AdminPanel'
import { Drawer } from '../../../components/Modal'
import { Form } from '../../../components/Form'
import { Button } from '../../../components/Button'
import Header from '../../../components/Layout/Header'
import Search from '../../../components/Filters/Search'
// utils.
import notifyToast from '../../../js/notifyToast'
import { db, getUserById } from '../../../js/firebase/firebase'
import { Loader } from '../../../components/Icon'
import { useDimensions } from '../../../js/hooks'
// constants.
import { EDIT_AUDITOR_USER_FORM, NEW_AUDITOR_USER_FORM } from '../constants'
import FloatingButton from '../../../components/Button/MobileButton'
import { useAppDispatch, useAppSelector } from '../../../js/store/hooks'
import { selectFilteredAuditorUsers, selectUsersStatus } from '../../../js/users/usersSelectors'
import { fetchUsers, setFilters, userUpdated } from '../../../js/users/usersSlice'
import {
  AuditorUsersMobileItem,
  AuditorUsersDesktopItem,
  AuditorUsersDesktopWrapper,
  AuditorUsersDesktopSort,
  AuditorUsersMobileWrapper,
} from '../../../components/screens/Auditors'
import { selectAuditorUsersForSelect } from '../../../js/audits/auditsSelectors'
import { clearFilters } from '../../../js/audits/auditsSlice'

/******************************/
/*                            */
/*    Auditor Users Screen    */
/*                            */
/******************************/

// main component.
const StopAuditorsScreen = ({ navMenu, navTitle }) => {
  // hooks.
  const dispatch = useAppDispatch()
  const { isMobile } = useDimensions()
  // states.
  const [modalContent, setModalContent] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  // data.
  const loaded = useAppSelector(selectUsersStatus)
  const filteredUsers = useAppSelector(selectFilteredAuditorUsers())
  const auditorsSelectOptions = useAppSelector(selectAuditorUsersForSelect)

  useEffect(() => {
    if (loaded === 'idle') dispatch(fetchUsers())
  }, [dispatch, loaded])

  useEffect(() => {
    dispatch(clearFilters())
  }, [])

  // ------------------------------------------------------------------------------------------- //
  // MODALS AND DRAWERS                                                                          //
  // ------------------------------------------------------------------------------------------- //

  // open modal.
  const handleModalOpen = (open) => setModalOpen(open)

  // handle add new user modal.
  const handleNewUserModal = () => {
    setModalContent({
      title: 'Agregar nuevo auditor',
      component: (
        <Form
          items={NEW_AUDITOR_USER_FORM(auditorsSelectOptions)}
          onClick={(obj) => handleSaveUserClick(obj)}
        />
      ),
    })
    setModalOpen(true)
  }

  // handle edit selected user modal.
  const handleEditUserModal = (obj) => {
    const selectedUser = { ...obj }
    setModalContent({
      title: 'Actualizar usuario',
      component: (
        <Form
          items={EDIT_AUDITOR_USER_FORM}
          defaultValues={selectedUser}
          onClick={(user) => handleSaveUserClick(user)}
        />
      ),
    })
    setModalOpen(true)
  }

  // ------------------------------------------------------------------------------------------- //
  // UPDATE DATABASE DATA                                                                        //
  // ------------------------------------------------------------------------------------------- //

  // handle user information update.
  const handleSaveUserClick = async (obj) => {
    const { uid, isAuditor, stopAuditBudget } = obj
    const userRef = doc(db, 'users', uid)
    const editIsAuditor = isAuditor !== null || isAuditor !== '' || isAuditor !== undefined

    if (editIsAuditor) {
      const userExists = await getUserById(uid)
      if (!userExists) {
        notifyToast('No existe un usuario con el id seleccionado', 'warning')
        return
      }
    }

    await updateDoc(userRef, { isAuditor, stopAuditBudget })
      .then(async () => {
        const updatedUserDoc = await getDoc(userRef)
        const updatedUser = { id: updatedUserDoc.id, ...updatedUserDoc.data() }
        dispatch(userUpdated(updatedUser))
        notifyToast('Usuario actualizado correctamente', 'success')
      })
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))

    setModalOpen(false)
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <>
      <AdminTemplate
        navigation={navMenu}
        sectionTitle={navTitle}
        modalOpen={modalOpen}
        onOverlayClick={() => setModalOpen(false)}
      >
        <Header justifyContent="space-between">
          <Search
            placeholder="Buscar por nombre..."
            onChange={(term) => {
              dispatch(setFilters({ searchTerm: term }))
            }}
          />
          {isMobile ? (
            <FloatingButton onClick={() => handleNewUserModal()} />
          ) : (
            <Button onClick={() => handleNewUserModal()}>Agregar Usuario</Button>
          )}
        </Header>
        {loaded === 'succeeded' ? (
          isMobile ? (
            <AuditorUsersMobileWrapper isEmpty={!filteredUsers && !filteredUsers.length}>
              {filteredUsers.map((user, i) => (
                <AuditorUsersMobileItem
                  key={user.id}
                  item={user}
                  isEven={i % 2 === 0}
                  onEdit={() => handleEditUserModal(user)}
                />
              ))}
            </AuditorUsersMobileWrapper>
          ) : (
            <AuditorUsersDesktopWrapper isEmpty={!!filteredUsers && !filteredUsers.length}>
              <AuditorUsersDesktopSort
                items={filteredUsers}
                onSort={(e) => dispatch(setFilters({ sort: e }))}
              />
              {filteredUsers.map((user, i) => (
                <AuditorUsersDesktopItem
                  key={user.id}
                  item={user}
                  isEven={i % 2 === 0}
                  onEdit={() => handleEditUserModal(user)}
                />
              ))}
            </AuditorUsersDesktopWrapper>
          )
        ) : (
          <Loader />
        )}
      </AdminTemplate>
      <Drawer title={modalContent.title} isOpen={modalOpen} onChange={handleModalOpen}>
        {modalContent.component}
      </Drawer>
    </>
  )
}

export default StopAuditorsScreen
