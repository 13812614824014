// dependencies.
import styled from 'styled-components'
// components.
import { Body, Pill } from '../../../Typography'
// utils.
import { theme } from '../../../../styleguide'
import auditStatusFormat from '../../../../js/auditStatusFormat'
import IconFeedback from '../../../Icon/IconFeedback'
// constants.

// helpers
const baseUnit = theme.fontSize.x16

// partials.
const Wrapper = styled.li({
  backgroundColor: theme.colors.white,
  border: `1px solid ${theme.opacity.ash30}`,
  borderRadius: baseUnit / 2,
  display: 'flex',
  filter: `drop-shadow(${theme.shadows.smaller})`,
  flexDirection: 'column',
  gap: baseUnit / 2,
  minHeight: baseUnit * 4,
  padding: baseUnit / 2,
  width: '100%',
})

const Header = styled.div({
  alignItems: 'center',
  borderBottom: `1px solid ${theme.opacity.ash30}`,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingBottom: baseUnit / 2 - 1,
  width: '100%',
})

const Content = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: baseUnit / 2,
  justifyContent: 'space-between',
  width: '100%',
})

const Footer = styled.div({
  alignItems: 'center',
  border: `1px solid ${theme.opacity.ash50}`,
  borderRadius: baseUnit / 2,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  margin: 0,
  marginTop: baseUnit / 4,
  padding: '2px',
  width: '100%',
})

const StyledBody = styled(Body)({
  alignItems: 'center',
  display: 'flex',
  gap: baseUnit / 2,
})

// main component.
const UsersMobileItem = ({ item }) => {
  // only show if there's items.
  if (!item) return null

  const { auditor, location, locationP, status, feedback } = item

  // return content.
  return (
    <Wrapper>
      <Header>
        <Body size="smaller" nowrap>
          Auditor: <strong>{auditor}</strong>
        </Body>
      </Header>

      <Content>
        <StyledBody size="smaller" nowrap>
          Ubicación: <strong>{location}</strong>
        </StyledBody>
        {locationP ? (
          <StyledBody size="smaller" nowrap>
            Área: <strong>{locationP}</strong>
          </StyledBody>
        ) : null}
      </Content>

      <Footer>
        <StyledBody size="smaller" nowrap>
          {feedback ? (
            <>
              Resultado:
              <IconFeedback size={24} type={feedback} />
            </>
          ) : null}
        </StyledBody>
        <Pill type={status !== 0 ? 2 : 0}>{auditStatusFormat(status)}</Pill>
      </Footer>
    </Wrapper>
  )
}

export default UsersMobileItem
