// dependencies.
import styled from 'styled-components'
import { useEffect, useRef, useState } from 'react'
// components.
import { Icon } from '../../components/Icon'
import { Body } from '../../components/Typography'
// utils.
import { theme } from '../../styleguide'
import { useDimensions, useWindowEvent } from '../../js/hooks'

/******************************/
/*                            */
/*    Show Frame Component    */
/*                            */
/******************************/

// partials.
const StyledList = styled.div({
  background: (props) => (props.isTablet ? theme.colors.black : theme.colors.smoke),
  borderLeft: (props) => (props.isTablet ? 'none' : `1px solid ${theme.colors.ash}`),
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: (props) => (props.isTablet ? '100vh' : '100%'),
  left: 0,
  opacity: (props) => (props.isTablet ? (props.isActive ? 1 : 0) : 1),
  overflow: 'hidden',
  padding: (props) => (props.isTablet ? '48px 0 0' : '40px 16px'),
  position: (props) => (props.isTablet ? 'fixed' : 'relative'),
  top: (props) => (props.isTablet ? (props.isActive ? 0 : '100%') : 'unset'),
  transition: 'all 300ms ease',
  width: '100%',
  zIndex: 10,
})

const Progress = styled.span({
  background: theme.colors.primary,
  display: 'block',
  height: '4px',
  left: 0,
  position: 'absolute',
  top: 0,
  transition: 'all 300ms ease',
  width: (props) => `${(100 / props.steps) * props.progress}%`,
})

const IndexIndicator = styled(Body)({
  left: (props) => (props.isTablet ? '50%' : 0),
  padding: '16px',
  position: 'absolute',
  top: 0,
  transform: (props) => (props.isTablet ? 'translateX(-50%)' : 'none'),
})

const CloseStack = styled.button({
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',
  display: 'block',
  padding: '16px',
  position: 'absolute',
  right: 0,
  top: 0,
  zIndex: 5,
})

const Control = styled.button({
  alignSelf: 'flex-end',
  background: 'transparent',
  border: 'none',
  bottom: (props) => (props.isTablet ? '20px' : 'unset'),
  cursor: 'pointer',
  display: 'block',
  height: (props) => (props.isTablet ? '48px' : '56px'),
  left: (props) => (props.direction === 'left' ? 0 : props.isTablet ? '40px' : 'unset'),
  padding: '8px 16px',
  position: (props) => (props.isTablet ? 'fixed' : 'absolute'),
  right: (props) => (props.direction === 'right' ? (props.isTablet ? 'unset' : 0) : 'unset'),
  top: (props) => (props.isTablet ? '2px' : '40%'),
  width: (props) => (props.isTablet ? '48px' : '56px'),
  zIndex: (props) => (props.isTablet ? 10 : 1),
})

const Carousel = styled.div({
  minHeight: (props) => (props.isTablet ? '100%' : 'calc(100% - 160px)'),
  maxWidth: '100%',
  overflow: 'hidden',
  padding: (props) => (props.isTablet ? 0 : '16px 40px 40px'),
  width: (props) => `${props.width + 80}px`,
  zIndex: (props) => (props.isTablet ? 1 : 2),
})

const Slider = styled.ul({
  display: 'flex',
  height: '100%',
  listStyle: 'none',
  margin: 0,
  marginLeft: (props) => `${props.offsetLeft}px`,
  padding: 0,
  transition: 'all 300ms ease',
  width: (props) => `${props.width}px`,
})

const Slide = styled.li({
  alignItems: 'flex-start',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  margin: 0,
  maxWidth: '100%',
  opacity: (props) => (props.isActive ? 1 : 0),
  padding: 0,
  transition: 'all 300ms ease',
  width: (props) => `${props.width}px`,

  '> *': {
    height: '100%',

    '> *': {
      borderRadius: (props) => props.isTablet && '16px 16px 0 0',
      height: '100%',
    },
  },
})

// main component.
const ShowFrame = ({ items, activeStack, activeIndex = 0, onCloseClick }) => {
  const swipeableRef = useRef(null)

  const [slideIndex, setSlideIndex] = useState(activeIndex)

  const { isTablet, width } = useDimensions()
  const startXRef = useRef(null)

  const minSwipeDistance = 50

  const handleTouchStart = (e) => {
    startXRef.current = e.touches[0].clientX
  }

  const handleTouchEnd = (e) => {
    if (startXRef.current !== null) {
      const endX = e.changedTouches[0].clientX
      const distance = startXRef.current - endX

      if (Math.abs(distance) > minSwipeDistance) {
        if (distance > 0) {
          handleMoveRight()
        } else {
          handleMoveLeft()
        }
      }

      startXRef.current = null
    }
  }

  const handleMoveLeft = () => {
    setSlideIndex((prevIndex) => (prevIndex - 1 + slideCount) % slideCount)
  }

  const handleMoveRight = () => {
    setSlideIndex((prevIndex) => (prevIndex + 1) % slideCount)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowRight') handleMoveRight()
    else if (event.key === 'ArrowLeft') handleMoveLeft()
  }

  useWindowEvent('keydown', handleKeyDown)

  const slideCount = items.length
  const slideWidth = isTablet ? width : 480
  const sliderUlWidth = slideCount * slideWidth

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  useEffect(() => {
    if (!activeStack) {
      onCloseClick()
    }
    setSlideIndex(0)
  }, [activeStack])

  useEffect(() => {
    setSlideIndex(activeIndex)
  }, [activeIndex])

  // return content.
  return (
    <StyledList
      ref={swipeableRef}
      zEl={items.length - 1}
      isActive={!!items.length}
      isTablet={isTablet}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      {items.length > 0 && (
        <>
          <Progress steps={items.length - 1} progress={slideIndex} />

          <IndexIndicator type="caption" color="grey" isTablet={isTablet}>
            {slideIndex + 1} / {items.length}
          </IndexIndicator>

          <CloseStack onClick={onCloseClick}>
            <Icon name="close" size={isTablet ? 16 : 24} color={isTablet ? 'white' : 'black'} />
          </CloseStack>

          {items.length > 1 && (
            <>
              <Control direction="right" isTablet={isTablet} onClick={() => handleMoveRight()}>
                <Icon
                  name="caret-right"
                  size={isTablet ? 16 : 24}
                  color={isTablet ? 'white' : 'black'}
                />
              </Control>

              <Control direction="left" isTablet={isTablet} onClick={() => handleMoveLeft()}>
                <Icon
                  name="caret-left"
                  size={isTablet ? 16 : 24}
                  color={isTablet ? 'white' : 'black'}
                />
              </Control>
            </>
          )}

          <Carousel isTablet={isTablet} width={slideWidth}>
            <Slider isTablet={isTablet} width={sliderUlWidth} offsetLeft={-slideWidth * slideIndex}>
              {items.map((item, i) => (
                <Slide key={i} isActive={slideIndex === i} isTablet={isTablet} width={slideWidth}>
                  {item}
                </Slide>
              ))}
            </Slider>
          </Carousel>
        </>
      )}
    </StyledList>
  )
}

export default ShowFrame
