// dependencies.
import styled from 'styled-components'
import { CSVLink } from 'react-csv'
import { writeFile, utils } from 'xlsx'
// components.
import { Button } from '../../Button'
import { Body } from '../../Typography'
// utils.
import { theme } from '../../../styleguide'
import { useDimensions } from '../../../js/hooks'
import dateFormat from '../../../js/dateFormat'
import { getNameFull } from '../../../js/usernameUtils'
import { DATE_TIME_FORMAT } from '../../../constants'
import cardStatusFormat from '../../../js/cardStatusFormat'
import otStatusFormat from '../../../js/reportOtStatus'

/************************/
/*                      */
/*    Search Heading    */
/*                      */
/************************/

// helpers.
const baseUnit = theme.fontSize.x16

// partials.
const Wrapper = styled.div({
  display: 'flex',
  flexDirection: (props) => (props.column ? 'column' : 'row'),
  gap: baseUnit,
  width: (props) => (props.isMobile ? '100%' : props.column ? '100%' : 'fit-content'),
  alignItems: 'center',
})

const ButtonContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
  width: (props) => (props.column ? '100%' : 'fit-content'),
})

const StyledButton = styled(Button)({
  padding: 0,
  height: baseUnit * 3,
  minWidth: baseUnit * 3,
  width: (props) => (props.isMobile ? '100%' : props.column ? '100%' : 'fit-content'),
})

// main component.
const Export = ({ label, header = [], items = [], filename = 'reporte' }) => {
  // hooks.
  const { isMobile } = useDimensions()

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //
  // Prepare data for CSV
  const csvHeaders = header.map((h) => h.label)
  const csvData = items.map((item) => {
    const {
      author,
      anomaly,
      comments,
      createdDate,
      description,
      extremeDatePlanDate,
      extremeDateReal,
      improvementMade,
      mttr,
      otStatus,
      proposedImprovement,
      responsible,
      status,
      type,
      location,
      id,
    } = item
    return [
      id,
      type,
      location,
      getNameFull(responsible?.firstname, responsible?.lastname),
      anomaly?.label,
      description,
      proposedImprovement,
      improvementMade,
      dateFormat(createdDate, DATE_TIME_FORMAT),
      extremeDatePlanDate ? dateFormat(extremeDatePlanDate) : '',
      extremeDateReal ? dateFormat(extremeDateReal) : '',
      extremeDateReal ? 'OK' : '',
      cardStatusFormat(status),
      getNameFull(author?.firstname, author?.lastname) || 'Anónimo',
      otStatus ? otStatusFormat(otStatus) : '',
      mttr,
      comments,
    ]
  })

  const handleExportXLSX = () => {
    const data = [csvHeaders].concat(csvData)
    const ws = utils.aoa_to_sheet(data)
    const wb = utils.book_new()
    utils.book_append_sheet(wb, ws, 'Tarjetas')
    writeFile(wb, `Reporte-${new Date().toLocaleDateString()}.xlsx`)
  }

  // return content.
  return (
    <Wrapper column={!!label} isMobile={isMobile}>
      {label ? (
        <Body type="caption" color="grey">
          {label}
        </Body>
      ) : (
        <Body type="caption" color="white">
          Exportar:
        </Body>
      )}

      <ButtonContainer column={!!label}>
        {csvHeaders && csvData && (
          <StyledButton
            isMobile={isMobile}
            column={!!label}
            onClick={() => handleExportXLSX()}
            background="success"
          >
            XLSX
          </StyledButton>
        )}

        {csvHeaders && csvData && (
          <CSVLink
            className="link-reset"
            style={{
              color: 'inherit',
              textDecoration: 'none',
              width: isMobile ? '100%' : label ? '100%' : 'fit-content',
            }}
            filename={`${filename}.csv`}
            data={csvData}
            headers={csvHeaders}
          >
            <StyledButton column={!!label} background="success">
              CSV
            </StyledButton>
          </CSVLink>
        )}
      </ButtonContainer>
    </Wrapper>
  )
}

export default Export
