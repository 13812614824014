// depenmdencies.
import styled from 'styled-components'
// components.
import { Body } from '../../../Typography'
// utils.
import { theme } from '../../../../styleguide'
import { useDimensions } from '../../../../js/hooks'
import { BOTTOMBAR_HEIGHT } from '../../../../constants'

// helpers
const baseUnit = theme.fontSize.x16

// partials.
export const ChartsContainer = styled.div({
  alignItems: 'stretch',
  background: theme.colors.smoke,
  borderBottom: `2px solid ${theme.opacity.ash20}`,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: baseUnit,
  height: 'fit-content',
  marginBottom: (props) => `${props.isMobile ? BOTTOMBAR_HEIGHT : 0}px`,
  padding: baseUnit,
})

const ChartCardWrapper = styled.div({
  alignItems: (props) => props.alignItems,
  background: theme.colors.white,
  borderRadius: `${baseUnit}px`,
  display: 'flex',
  filter: `drop-shadow(${theme.shadows.smaller})`,
  flexDirection: 'column',
  height: 'auto',
  justifyContent: 'flex-start',
  overflow: 'hidden',
  padding: baseUnit,
  width: (props) =>
    props.isTablet || !props.width ? '100%' : `calc(${props.width} - ${baseUnit}px)`,
})

export const ChartCard = ({ label, alignItems = 'center', width, children }) => {
  const { isTablet } = useDimensions()

  return (
    <ChartCardWrapper isTablet={isTablet} width={width} alignItems={alignItems}>
      {label && (
        <Body type="caption" color="grey">
          {label}
        </Body>
      )}
      {children}
    </ChartCardWrapper>
  )
}
