// dependencies.
import styled from 'styled-components'
// components.
import { Label } from '../../components/Typography'
import { Icon } from '../../components/Icon'
// utils.
import setBackgroundColor from '../../js/setBackgroundColor'
import { useHideOnScroll } from '../../js/hooks'
import { theme } from '../../styleguide'
import { useAppSelector } from '../../js/store/hooks'
import { selectUserData } from '../../js/auth/authSelectors'
import useAccessRedirect from '../../js/hooks/useAccessRedirect'

/******************************/
/*                            */
/*    Navigation Component    */
/*                            */
/******************************/

// partials.
const NavigationWrapper = styled.div(
  {
    alignItems: 'center',
    background: (props) => props.background,
    bottom: (props) => (props.isHidden ? '-478px' : 0),
    boxShadow: theme.shadows.normal,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    gap: 0,
    height: '48px',
    left: 0,
    overflowX: 'auto',
    overflowY: 'hidden',
    padding: 0,
    position: 'fixed',
    transition: 'all 200ms ease',
    width: '100%',
    zIndex: 4,
  },
  `@media print { display: none; }`
)

const Item = styled(Label)({
  alignItems: 'center',
  borderTop: (props) =>
    props.isActive ? `4px solid ${theme.colors.primary}` : '4px solid transparent',
  cursor: (props) => (props.isActive ? 'text' : 'pointer'),
  display: 'flex',
  flexDirection: 'column',
  fontSize: '12px',
  fontWeight: (props) => (props.isActive ? '900' : '400'),
  gap: '2px',
  height: '100%',
  padding: '4px 12px 0',
  position: 'relative',
  whiteSpace: 'nowrap',
  width: '100%',
})

const Divider = styled.hr({
  backgroundColor: theme.opacity.white10,
  border: 'none',
  display: 'block',
  height: '80%',
  minWidth: '1px',
  pointerEvents: 'none',
  width: '2px',
})

// main component.
const BottomBar = ({ items }) => {
  // hooks.
  const userData = useAppSelector(selectUserData)
  const activePath = useAccessRedirect(userData)
  const { isVisible } = useHideOnScroll()

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <NavigationWrapper
      background={setBackgroundColor(userData.isAuditor ? 'auditor' : userData.access)}
      isHidden={!isVisible}
    >
      {items.map((item, i) => {
        const isActive = item.path === activePath
        if (item.type === 'divider' || item.type === 'spacer') return null
        if (item.type === 'blockspacer') return <Divider key={i} />

        return (
          <Item
            key={i}
            isActive={isActive}
            color={isActive ? 'white' : 'ash'}
            size="smaller"
            to={!isActive && item.path}
          >
            {<Icon key={i} name={item.icon} color={isActive ? 'primary' : 'ash'} size={18} />}
            {item.label}
          </Item>
        )
      })}
    </NavigationWrapper>
  )
}
export default BottomBar
