// dependencies.
import { useEffect, useRef, useState } from 'react'
// components.
import AdminTemplate from '../../../templates/AdminPanel'
import {
  AuditoriesMobileItem,
  AuditoriesMobileSort,
  AuditoriesMobileWrapper,
  AuditoriesDesktopItem,
  AuditoriesDesktopSort,
  AuditoriesDesktopWrapper,
} from '../../../components/screens/Auditories'
import { PreviewFrame, PREVIEWFRAME_HEIGHT } from '../../../components/Modal'
import { Loader } from '../../../components/Icon'
import { FilterList } from '../../../components/Typography/FilterChip'
import Header from '../../../components/Layout/Header'
import ToggleFilterButton from '../../../components/Filters/ToggleFilterAsideButton'
// import { BranchSelect } from '../../components/Form'
import FilterResponsible from '../../../components/Filters/Responsible'
import ToggleSortingButton from '../../../components/Filters/ToggleSortingTooltipButton'
import Search from '../../../components/Filters/Search'
// utils.
import { useDimensions } from '../../../js/hooks'
import { useAppDispatch, useAppSelector } from '../../../js/store/hooks'
import { selectUsersData } from '../../../js/users/usersSelectors'
import {
  selectSearchTerm,
  selectFilteredAudits,
  selectAuditorsForSelect,
  selectActiveFilters,
  selectLocationFilter,
} from '../../../js/audits/auditsSelectors'
// constants.
import {
  NAVIGATION_WIDTH,
  NAVIGATION_CONDENSED_WIDTH,
  FILTERS_ASIDE_WIDTH,
  HEADER_HEIGHT_DESKTOP,
} from '../../../constants'
import { selectLocationsData, selectLocationsTree } from '../../../js/locations/locationsSelectors'
import { clearFilters, removeFilter, setFilters } from '../../../js/audits/auditsSlice'
import FilterTimeRange from '../../../components/Filters/TimeRange'
import AuditCard from '../../../components/Card/AuditCard/AuditCard'
import useFetchAuditsData from '../../../js/cards/hooks/useFetchAuditsData'
import {
  selectFilteredEpps,
  selectFilteredItems,
  selectFilteredItemsConduct,
  selectFilteredItemsOperation,
  selectFilteredPersons,
} from '../../../js/auditData/auditDataSelectors'
import FilterAuditedPerson from '../../../components/Filters/AuditedPerson'
import FilterLocation from '../../../components/Filters/Location'

/******************************/
/*                            */
/*    Auditories Screen    */
/*                            */
/******************************/

// main component.
const StopAuditoriesScreen = ({ navMenu, navTitle }) => {
  // hooks.
  const scrollRef = useRef()
  const { isMobile } = useDimensions()
  const loaded = useFetchAuditsData()
  // states.
  const [modalOpen, setModalOpen] = useState(false)
  const [focusedObject, setFocusedObject] = useState(null)
  const [offsetTop, setOffsetTop] = useState(0)
  const [filtersOpen, setFiltersOpen] = useState(false)
  const [sortingOpen, setSortingOpen] = useState(false)
  const [navIsCondensed, setNavIsCondensed] = useState(false)
  // data.
  const dispatch = useAppDispatch()
  const users = useAppSelector(selectUsersData)
  const locations = useAppSelector(selectLocationsData)
  const locationsTree = useAppSelector(selectLocationsTree)
  const auditorsSelectOptions = useAppSelector(selectAuditorsForSelect)
  const epps = useAppSelector(selectFilteredEpps())
  const persons = useAppSelector(selectFilteredPersons())
  const conductItems = useAppSelector(selectFilteredItemsConduct())
  const operationItems = useAppSelector(selectFilteredItemsOperation())
  const auditItems = useAppSelector(selectFilteredItems())
  const { done: filteredAudits } = useAppSelector(
    selectFilteredAudits(auditItems, users, locations, persons, epps)
  )

  const activeFilters = useAppSelector(selectActiveFilters(users, locationsTree))
  const searchTerm = useAppSelector(selectSearchTerm)
  const locationFilter = useAppSelector(selectLocationFilter)

  // ------------------------------------------------------------------------------------------- //

  // update positions on scroll.
  useEffect(() => {
    dispatch(clearFilters())

    if (typeof scrollRef.current === 'undefined') return // prevent build from failing.
    scrollRef.current.addEventListener('scroll', () => setOffsetTop(scrollRef.current.scrollTop))

    return () =>
      scrollRef.current &&
      scrollRef.current.removeEventListener('scroll', () =>
        setOffsetTop(scrollRef.current.scrollTop)
      )
  }, [])

  const maybeMoveOnScroll = offsetTop > HEADER_HEIGHT_DESKTOP
  const dektopOffsetTranslateY = HEADER_HEIGHT_DESKTOP - offsetTop

  // open preview frame on card-focus.
  const handleFocusedobject = (bool, obj) => {
    if (bool) return setFocusedObject(obj)
    else return setFocusedObject(null)
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  const dynamicColumns = [
    ...new Set(
      conductItems.map(({ id, name }) => {
        return { id, label: name }
      })
    ),
    ...new Set(
      operationItems.map(({ id, name }) => {
        return { id, label: name }
      })
    ),
  ]

  // return content.
  return (
    <>
      <AdminTemplate
        ref={scrollRef}
        sectionTitle={navTitle}
        navigation={navMenu}
        isMobile={isMobile}
        modalOpen={modalOpen}
        filtersOpen={filtersOpen}
        setFiltersOpen={setFiltersOpen}
        filterComponents={[
          <Search
            key="in-audits-search"
            label="Buscar auditoría"
            placeholder="Número..."
            defaultValue={searchTerm}
            onChange={(term) => {
              dispatch(setFilters({ searchTerm: term }))
            }}
          />,
          <FilterLocation
            key="in-cards-location"
            label="Ubicación"
            placeholder="Buscar ubicaciones"
            value={locationFilter}
            locationsSelectTree={locationsTree}
            items={filteredAudits}
            onLocationFilter={(locationId) => dispatch(setFilters({ locationId }))}
          />,
          <FilterResponsible
            key="in-audits-responsible"
            label="Observador"
            placeholder="Observador"
            responsibles={auditorsSelectOptions}
            onResponsibleFilter={(auditorId) => dispatch(setFilters({ auditorId }))}
          />,
          <FilterAuditedPerson
            key="in-audits-audited-person"
            label="Persona/Empresa auditada"
            placeholder="Seleccione..."
            persons={persons}
            onFilter={(personId) => dispatch(setFilters({ personId }))}
          />,
          <FilterTimeRange
            granularity={['m', 'y']}
            key="in-audits-date-range"
            label="Período de tiempo"
            onFilter={({ fromDate, toDate }) => dispatch(setFilters({ fromDate, toDate }))}
          />,
        ]}
        onOverlayClick={() => setModalOpen(false)}
        onCondensedChange={(isCondensed) => setNavIsCondensed(isCondensed)}
      >
        <Header justifyContent="flex-start">
          <ToggleFilterButton
            isActive={filtersOpen}
            onChange={(isOpen) => setFiltersOpen(isOpen)}
          />
          {isMobile ? (
            <ToggleSortingButton onChange={(isOpen) => setSortingOpen(isOpen)} />
          ) : (
            <FilterList
              items={activeFilters}
              onFilterClick={(filterValue) => dispatch(removeFilter(filterValue))}
              onClearAllClick={() => dispatch(clearFilters())}
            />
          )}
        </Header>

        {loaded === 'succeeded' ? (
          isMobile ? (
            <AuditoriesMobileWrapper isEmpty={filteredAudits.length <= 0}>
              <AuditoriesMobileSort
                items={filteredAudits}
                responsibles={users}
                isOpen={sortingOpen}
                onSort={(e) => dispatch(setFilters({ sort: e }))}
              />
              {filteredAudits.map((card) => (
                <AuditoriesMobileItem key={card.id} item={card} />
              ))}
            </AuditoriesMobileWrapper>
          ) : (
            <AuditoriesDesktopWrapper isEmpty={filteredAudits.length <= 0}>
              <AuditoriesDesktopSort
                dynamicColumns={dynamicColumns}
                responsibles={users}
                style={{
                  ...(maybeMoveOnScroll
                    ? {
                        maxWidth: `calc(100% - ${
                          filtersOpen
                            ? navIsCondensed
                              ? `${NAVIGATION_CONDENSED_WIDTH + FILTERS_ASIDE_WIDTH}px`
                              : `${NAVIGATION_WIDTH + FILTERS_ASIDE_WIDTH}px`
                            : navIsCondensed
                              ? `${NAVIGATION_CONDENSED_WIDTH}px`
                              : `${NAVIGATION_WIDTH}px`
                        })`,
                        position: 'fixed',
                      }
                    : {}),
                }}
                onSort={(e) => dispatch(setFilters({ sort: e }))}
              />
              {filteredAudits.map((card, i) => (
                <AuditoriesDesktopItem
                  key={card.id}
                  dynamicColumns={dynamicColumns}
                  item={card}
                  isEven={i % 2 === 0}
                  onFocus={(bool) => handleFocusedobject(bool, card)}
                />
              ))}
              <PreviewFrame
                onCloseClick={() => handleFocusedobject(false, {})}
                items={
                  focusedObject && focusedObject.lenght > 0
                    ? [
                        <AuditCard
                          key={focusedObject.id}
                          type={focusedObject.type}
                          audit={focusedObject}
                        />,
                      ]
                    : []
                }
                style={
                  offsetTop > 0
                    ? {
                        height: maybeMoveOnScroll
                          ? '100vh'
                          : PREVIEWFRAME_HEIGHT(dektopOffsetTranslateY),
                        top: maybeMoveOnScroll ? 0 : `${dektopOffsetTranslateY}px`,
                      }
                    : {
                        height: PREVIEWFRAME_HEIGHT(),
                        top: HEADER_HEIGHT_DESKTOP,
                      }
                }
              />
            </AuditoriesDesktopWrapper>
          )
        ) : (
          <Loader />
        )}
      </AdminTemplate>
    </>
  )
}

export default StopAuditoriesScreen
