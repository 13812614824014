import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
// Asumiendo que estos componentes existen en tu proyecto
import { Body } from '../Typography'
import { theme } from '../../styleguide'

const radioSize = 20
const paddingSize = 4

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`

const RadioWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 8px;
  padding: 8px 0;
`

const RadioButton = styled.div`
  align-items: center;
  background: ${(props) => props.background};
  border: 2px solid ${(props) => props.color};
  border-radius: 50%;
  color: ${(props) => props.color};
  cursor: pointer;
  display: flex;
  flex: none;
  height: ${radioSize + paddingSize * 2}px;
  justify-content: center;
  order: 0;
  padding: ${paddingSize}px;
  position: relative;
  width: ${radioSize + paddingSize * 2}px;
`

const RadioDot = styled.div`
  background: ${(props) => props.color};
  border-radius: 50%;
  height: ${radioSize - 6}px;
  width: ${radioSize - 6}px;
`

const OptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const RadioGroup = ({ options, defaultValue = '', disabled = false, onChange, label }) => {
  const [selected, setSelected] = useState(defaultValue)

  const handleSelect = (value) => {
    if (disabled) return
    setSelected(value)
  }

  useEffect(() => {
    if (defaultValue !== '') setSelected(defaultValue)
  }, [defaultValue])

  useEffect(() => {
    if (!onChange) return
    onChange(selected)
  }, [selected])

  const { colors } = theme
  const color = disabled ? 'ash' : 'black'
  const background = disabled ? 'smoke' : 'white'

  return (
    <Container>
      {label && <Body type="caption">{label}</Body>}
      <OptionsContainer>
        {options.map((option) => (
          <RadioWrapper key={option.value} onClick={() => handleSelect(option.value)}>
            <RadioButton color={colors[color]} background={colors[background]}>
              {selected === option.value && <RadioDot color={colors[color]} />}
            </RadioButton>
            <Body type="caption">{option.label}</Body>
          </RadioWrapper>
        ))}
      </OptionsContainer>
    </Container>
  )
}

export default RadioGroup
