// dependencies.
import { useEffect, useState } from 'react'
import imageCompression from 'browser-image-compression'
import styled from 'styled-components'
// components.
import Checkbox from './Checkbox'
import Input from './Input'
import { Icon } from '../Icon'
import { Button } from '../Button'
import { Body, Headline } from '../Typography'
// utils.
import { useDimensions } from '../../js/hooks'
import { validateCi } from '../../js/validateInput'
import notifyToast from '../../js/notifyToast'
import { theme } from '../../styleguide'
import RadioGroup from './RadioGroup'

/************************/
/*                      */
/*    Form Component    */
/*                      */
/************************/

// helpers.
const getRequiredFields = (fields) => {
  return fields.reduce((acc, field) => {
    if (field.required && field.name) {
      acc.push(field.name)
    }
    if (field.items) {
      const subFields = field.items.flat()
      acc.push(...getRequiredFields(subFields))
    }
    return acc
  }, [])
}

const findValueByKey = (obj, key) => {
  for (const prop in obj) {
    if (prop === key && obj[prop] !== null) {
      return obj[prop]
    } else if (typeof obj[prop] === 'object' && obj[prop] !== null) {
      for (const prop2 in obj[prop]) {
        if (prop2 === key && obj[prop][prop2] !== null) {
          return obj[prop][prop2]
        }
      }
    }
  }
  return null
}

// partials.
const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: (props) => (props.isMobile ? '8px' : '16px'),
  height: (props) => props.height || 'unset',
  margin: '0 auto',
  maxWidth: '416px',
  width: '100%',
})

const InputGroup = styled.div({
  display: 'flex',
  gap: (props) => (props.isMobile ? '4px 8px' : '4px 16px'),
  flexWrap: 'wrap',
  margin: 0,
  maxWidth: '416px',
  width: '100%',
})

const MediaWrapper = styled.div({
  background: theme.colors.smoke,
  border: `2px solid ${theme.colors.grey}`,
  borderRadius: 8,
  color: theme.colors.black,
  alignItems: 'center',
  display: 'flex',
  margin: '16px 0 0',
  flexDirection: 'row',
  gap: 16,
  padding: 8,
  width: '100%',
})

const SectionLabel = styled(Headline)({
  flexGrow: 0,
  height: 'fit-content',
  margin: (props) => (props.isMobile ? '16px 0 0' : '24px 0 8px'),
})

const SubmitButton = styled(Button)({
  margin: '64px 0 8px',
})

const FormInput = ({ item, defaultValue, isMobile, onChange, cardType = null }) => {
  const { disabled, required, label, name, options, placeholder, type, hidden } = item
  const commonProps = { defaultValue, disabled, required, name, onChange, hidden }

  if (type === 'headline') {
    return <SectionLabel isMobile={isMobile}>{label}</SectionLabel>
  }
  if (type === 'checkbox') {
    return <Checkbox {...commonProps} label={placeholder} />
  }
  if (type === 'anomaly') {
    if (item.anomalyType === cardType) {
      return <Checkbox {...commonProps} label={placeholder} />
    }
    return
  }
  if (type === 'radioGroup') {
    return <RadioGroup label={placeholder} options={options} {...commonProps} />
  }
  return <Input {...commonProps} options={options} placeholder={placeholder} type={type} />
}

// main component.
const Form = ({
  items,
  height,
  className,
  defaultValues,
  submitLabel = 'Guardar',
  onClick,
  onFileUpload,
  onFileDelete,
}) => {
  // hooks.
  const { isMobile } = useDimensions()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isFileUploading, setIsFileUploading] = useState(false)
  const [isVideoUploading, setIsVideoUploading] = useState(false)

  // states.
  const [values, setValues] = useState(() => {
    const initialValues = items.reduce((initialEmptyValues, item) => {
      // construct an object with all keys from items with null values.
      if (item.name) initialEmptyValues[item.name] = null

      // if it's an array field, initialize each sub-item's keys with null values.
      if (item.type === 'array') {
        initialEmptyValues[item.name] = {}
        item.items.forEach((subItem) => {
          if (item.name && subItem.name) initialEmptyValues[item.name][subItem.name] = null
          if (subItem.length > 1) {
            subItem.forEach((subSubItem) => {
              if (item.name && subSubItem.name)
                initialEmptyValues[item.name][subSubItem.name] = null
            })
          }
        })
      }

      return initialEmptyValues
    }, {})

    return defaultValues ?? initialValues
  })
  const [disableSubmit, setDisableSubmit] = useState(true)
  const [cardType, setCardType] = useState(null)

  const handleSubmit = async () => {
    if (isSubmitting) {
      return // Prevent multiples submissions.
    }

    setIsSubmitting(true)

    try {
      await onClick(values)
    } catch (error) {
      console.error('Error al enviar el formulario:', error)
    } finally {
      setIsSubmitting(false)
    }
  }

  const [thumbnailUrl, setThumbnailUrl] = useState()
  const [videoThumbnailUrl, setVideoThumbnailUrl] = useState(false)
  const handleImageChange = async (e) => {
    if (e) {
      const selectedFile = e.target.files[0]
      if (selectedFile) {
        const maxSize = 50 * 1024 * 1024 // 50 MB
        if (selectedFile.size > maxSize) {
          notifyToast(
            'El archivo es demasiado grande. Por favor, selecciona un archivo de menos de 50MB.'
          )
          return
        }

        // Check if the file is a video
        const isFileVideo = selectedFile.type.startsWith('video/')
        let compressedFile = null
        if (isFileVideo) {
          compressedFile = selectedFile
          setIsVideoUploading(true)
        } else {
          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1280,
          }
          compressedFile = await imageCompression(selectedFile, options)
          setIsFileUploading(true)
        }

        try {
          const thumbnailUrl = await URL.createObjectURL(compressedFile)
          if (isFileVideo) {
            setVideoThumbnailUrl(thumbnailUrl)
            await onFileUpload(compressedFile, true)
          } else {
            setThumbnailUrl(thumbnailUrl)
            await onFileUpload(compressedFile)
          }
        } catch (error) {
          console.error('File upload error:', error)
        } finally {
          if (isFileVideo) {
            setIsVideoUploading(false)
          } else {
            setIsFileUploading(false)
          }
        }
      } else {
        // Handle case when the user cancels file selection
        setThumbnailUrl(null)
        onFileUpload(null)
        setVideoThumbnailUrl(null)
      }
    } else {
      setThumbnailUrl(null)
      onFileUpload(null)
      setVideoThumbnailUrl(null)
    }
  }

  const onFileChange = (item, value, i) => {
    handleImageChange(value)
    if (value) {
      value.preventDefault()
    }
  }

  const removeVideo = () => {
    onFileDelete(true)
    setVideoThumbnailUrl(null)
    setIsVideoUploading(false)
  }

  const removeImage = () => {
    onFileDelete(false)
    setThumbnailUrl(null)
    setIsFileUploading(false)
  }

  // ------------------------------------------------------------------------------------------- //
  // UPDATE DATABASE DATA                                                                        //
  // ------------------------------------------------------------------------------------------- //

  // handle form data update.
  const handleInputChange = (item, value, index, parent = null) => {
    if (item.type === 'radioGroup') {
      const parentKey = parent
      const radioGroupValues = values[parentKey] || []

      // Buscar si ya existe un objeto para este `ref`
      const existingIndex = radioGroupValues.findIndex((r) => r.ref === item.name)
      const updatedRadioGroupValues =
        existingIndex >= 0
          ? radioGroupValues.map((r, i) => (i === existingIndex ? { ref: item.name, value } : r))
          : [...radioGroupValues, { ref: item.name, value }]

      setValues({ ...values, [parentKey]: updatedRadioGroupValues })
    } else if (item.name === 'fullname' && !!item.options) {
      if (value) {
        const selected = items[index].options.filter((option) => option.label === value)
        const updatedValues = { ...values, ...selected[0], fullname: selected[0]?.label }

        setValues(updatedValues)
      }
    } else if (item.name === 'cardType') {
      if (value) {
        if (items[index].options) {
          const cardType = items[index].options.filter((b) => b.name === value)
          if (cardType.length > 0) {
            setCardType(cardType[0].name)
            const updatedValues = { ...values, type: cardType[0].name }
            setValues(updatedValues)
          }
        } else {
          setValues(value)
        }
      }
    } else {
      const buildValue =
        parent && values && parent in values
          ? { [parent]: { ...values[parent], [item.name || index]: value } }
          : parent
            ? { [parent]: {} }
            : { [item.name || index]: value }

      setValues({ ...values, ...buildValue })
    }
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // check required items.
  useEffect(() => {
    if (!items) return
    if (isFileUploading) {
      setDisableSubmit(true)
      return
    }

    const requiredFields = getRequiredFields(items)
    const checkValue = requiredFields.every((key) => findValueByKey(values, key))
    let checkDocument = true
    const document = findValueByKey(values, 'document')
    if (document) {
      checkDocument = validateCi(document)
    }
    setDisableSubmit(!checkValue || !checkDocument)
  }, [items, values, isFileUploading])

  // only show if there's items.
  if (!items) return null

  // return content.
  return (
    <Wrapper isMobile={isMobile} height={height} className={className}>
      {items.map((item, i) => {
        if (item.type === 'array') {
          return (
            item.items.length > 0 &&
            item.items.map((subItem, j) => {
              if (subItem.length > 1) {
                return (
                  <InputGroup key={j} isMobile={isMobile}>
                    {subItem.map((groupItem, k) => {
                      return (
                        <FormInput
                          key={k}
                          item={groupItem}
                          defaultValue={
                            (values &&
                              item.name in values &&
                              groupItem.name in values[item.name] &&
                              values[item.name][groupItem.name]) ||
                            ''
                          }
                          isMobile={isMobile}
                          onChange={(value) => handleInputChange(groupItem, value, k, item.name)}
                          cardType={groupItem.type === 'anomaly' ? cardType : null}
                        />
                      )
                    })}
                  </InputGroup>
                )
              }

              return (
                <FormInput
                  key={j}
                  item={subItem}
                  defaultValue={
                    (values &&
                      item.name in values &&
                      subItem.name in values[item.name] &&
                      values[item.name][subItem.name]) ||
                    ''
                  }
                  isMobile={isMobile}
                  onChange={(value) => handleInputChange(subItem, value, j, item.name)}
                />
              )
            })
          )
        }
        if (item.type === 'file') {
          return (
            <InputGroup key={i}>
              <input
                name="file"
                id="fileInput"
                hidden="hidden"
                iconL="upload"
                type="file"
                accept="image/*"
                capture="user"
                onChange={(value) => onFileChange(item, value, i)}
              />

              {(!thumbnailUrl || isFileUploading) && (
                <MediaWrapper>
                  <Icon name="image" size={34} />
                  <label
                    htmlFor="fileInput"
                    style={{ fontSize: '24px', fontFamily: 'Roboto, sans-serif' }}
                  >
                    {isFileUploading
                      ? 'Subiendo...'
                      : isMobile
                        ? 'Sacar foto'
                        : 'Adjuntar foto/imagen'}
                  </label>
                </MediaWrapper>
              )}

              {thumbnailUrl && !isFileUploading && (
                <MediaWrapper>
                  <img
                    src={thumbnailUrl}
                    alt="Thumbnail"
                    style={{ maxWidth: '200px', maxHeight: '200px', width: '100%', height: 'auto' }}
                  />
                  <Body>
                    <Icon name="delete" size={16} />
                    <label onClick={removeImage} style={{ fontSize: '20px' }}>
                      {' '}
                      Eliminar imagen
                    </label>
                  </Body>
                </MediaWrapper>
              )}
            </InputGroup>
          )
        }
        if (item.type === 'video') {
          return (
            <InputGroup key={i}>
              <input
                name="file"
                id="videoInput"
                hidden="hidden"
                iconL="upload"
                type="file"
                accept="video/*"
                capture="user"
                onChange={(value) => onFileChange(item, value, i)}
              />
              {(!videoThumbnailUrl || isVideoUploading) && (
                <MediaWrapper>
                  <Icon name="copy" size={34} />
                  <label
                    htmlFor="videoInput"
                    style={{ fontSize: '24px', fontFamily: 'Roboto, sans-serif' }}
                  >
                    {isVideoUploading
                      ? 'Subiendo...'
                      : isMobile
                        ? 'Grabar video'
                        : 'Adjuntar video'}
                  </label>
                </MediaWrapper>
              )}

              {videoThumbnailUrl && !isVideoUploading && (
                <MediaWrapper>
                  <video width="200" height="200" controls poster={videoThumbnailUrl}>
                    <source src={videoThumbnailUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <Body>
                    <Icon name="delete" size={16} />
                    <label onClick={removeVideo} style={{ fontSize: '20px' }}>
                      {' '}
                      Eliminar video
                    </label>
                  </Body>
                </MediaWrapper>
              )}
            </InputGroup>
          )
        }
        return (
          <FormInput
            key={i}
            item={item}
            defaultValue={(values && item.name in values && values[item.name]) || ''}
            isMobile={isMobile}
            onChange={(value) => handleInputChange(item, value, i, item.parent)}
          />
        )
      })}
      <SubmitButton disabled={disableSubmit || isSubmitting} onClick={handleSubmit}>
        {isSubmitting ? 'Enviando...' : submitLabel}
      </SubmitButton>
    </Wrapper>
  )
}

export default Form
