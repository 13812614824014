export const sortingAudit = (items, auditors, locations, direction, sortDbKey) => {
  const sortedData = [...items]
  if (direction === null) return sortedData

  const isAscending = direction === 'caret-down'

  sortedData.sort((a, b) => {
    let aValue
    let bValue
    switch (sortDbKey) {
      case 'auditorRef': {
        const aAuditor = auditors.find((auditor) => auditor.uid === a.auditorRef?.id)
        const bAuditor = auditors.find((auditor) => auditor.uid === b.auditorRef?.id)
        aValue = `${aAuditor?.firstname || 'zzz'} ${aAuditor?.lastName || 'zzz'}`.toLowerCase()
        bValue = `${bAuditor?.firstname || 'zzz'} ${bAuditor?.lastName || 'zzz'}`.toLowerCase()
        break
      }
      case 'deadline':
        aValue = a.deadline || (isAscending ? Infinity : -Infinity)
        bValue = b.deadline || (isAscending ? Infinity : -Infinity)
        break
      case 'locationRef':
        aValue = locations.find((location) => location.id === a.locationRef?.id)?.name
        bValue = locations.find((location) => location.id === b.locationRef?.id)?.name
        break
      default:
        aValue = a[sortDbKey]
        bValue = b[sortDbKey]
    }
    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return isAscending ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue)
    } else {
      return isAscending ? aValue - bValue : bValue - aValue
    }
  })

  return sortedData
}
