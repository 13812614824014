// theme for colors, font sizes, and shadows.
export const theme = {
  fontSize: {
    x48: 48,
    x40: 40,
    x32: 32,
    x24: 24,
    x20: 20,
    x18: 18,
    x16: 16,
    x14: 14,
    x12: 12,
    x10: 10,
  },
  lineHeight: {
    x48: 56,
    x40: 46,
    x32: 40,
    x24: 32,
    x20: 24,
    x18: 22,
    x16: 20,
    x14: 18,
    x12: 16,
    x10: 12,
  },
  colors: {
    primary: 'rgb(88, 171, 48)',
    secondary: 'rgb(97, 223, 214)',
    terciary: 'rgb(0, 77, 50)',
    black: 'rgb(17, 17, 19)',
    asphalt: 'rgb(49, 51, 53)',
    grey: 'rgb(117, 117, 117)',
    ash: 'rgb(205, 207, 209)',
    smoke: 'rgb(245, 245, 245)',
    white: 'rgb(255, 255, 255)',
    alert: 'rgb(210, 25, 25)',
    success: 'rgb(0, 133, 44)',
    warning: 'rgb(224, 177, 0)',
    info: 'rgb(1, 79, 157)',
  },
  admin: {
    admin: 'rgb(33, 33, 33)',
    admin95: 'rgba(33, 33, 33, 0.95)',
    company: 'rgb(20, 29, 60)',
    company95: 'rgba(20, 29, 60, 0.95)',
    developer: 'rgb(15, 59, 45)',
    developer95: 'rgba(15, 59, 45, 0.95)',
    auditor: 'rgb(72, 0, 90)',
    auditor95: 'rgba(72, 0, 90, 0.95)',
  },
  cards: {
    tm: 'rgb(0, 133, 44)',
    tm10: 'rgba(0, 133, 44, 0.1)',
    to: 'rgb(1, 79, 157)',
    to10: 'rgba(1, 79, 157, 0.1)',
    tq: 'rgb(201, 151, 0)',
    tq10: 'rgba(201, 151, 0, 0.1)',
    ts: 'rgb(210, 25, 25)',
    ts10: 'rgba(210, 25, 25, 0.1)',
  },
  audits: {
    stop: 'rgb(255, 255, 255)',
  },
  opacity: {
    black90: 'rgba(0, 0, 0, 0.9)',
    black80: 'rgba(0, 0, 0, 0.8)',
    black70: 'rgba(0, 0, 0, 0.7)',
    black60: 'rgba(0, 0, 0, 0.6)',
    black50: 'rgba(0, 0, 0, 0.5)',
    black40: 'rgba(0, 0, 0, 0.4)',
    black30: 'rgba(0, 0, 0, 0.3)',
    black20: 'rgba(0, 0, 0, 0.2)',
    black10: 'rgba(0, 0, 0, 0.1)',
    ash90: 'rgba(205, 207, 209, 0.9)',
    ash80: 'rgba(205, 207, 209, 0.8)',
    ash70: 'rgba(205, 207, 209, 0.7)',
    ash60: 'rgba(205, 207, 209, 0.6)',
    ash50: 'rgba(205, 207, 209, 0.5)',
    ash40: 'rgba(205, 207, 209, 0.4)',
    ash30: 'rgba(205, 207, 209, 0.3)',
    ash20: 'rgba(205, 207, 209, 0.2)',
    ash10: 'rgba(205, 207, 209, 0.1)',
    white90: 'rgba(255, 255, 255, 0.9)',
    white80: 'rgba(255, 255, 255, 0.8)',
    white70: 'rgba(255, 255, 255, 0.7)',
    white60: 'rgba(255, 255, 255, 0.6)',
    white50: 'rgba(255, 255, 255, 0.5)',
    white40: 'rgba(255, 255, 255, 0.4)',
    white30: 'rgba(255, 255, 255, 0.3)',
    white20: 'rgba(255, 255, 255, 0.2)',
    white10: 'rgba(255, 255, 255, 0.1)',
    primary90: 'rgba(88, 171, 48, 0.9)',
    primary80: 'rgba(88, 171, 48, 0.8)',
    primary70: 'rgba(88, 171, 48, 0.7)',
    primary60: 'rgba(88, 171, 48, 0.6)',
    primary50: 'rgba(88, 171, 48, 0.5)',
    primary40: 'rgba(88, 171, 48, 0.4)',
    primary30: 'rgba(88, 171, 48, 0.3)',
    primary20: 'rgba(88, 171, 48, 0.2)',
    primary10: 'rgba(88, 171, 48, 0.1)',

    alert10: 'rgba(210, 25, 25, 0.1)',
    success10: 'rgba(0, 133, 44, 0.1)',
    warning10: 'rgba(224, 177, 0, 0.1)',
    info10: 'rgba(1, 79, 157, 0.1)',
  },
  shadows: {
    larger: '0px 4px 20px rgba(17, 17, 19, 0.4)',
    normal: '0px 4px 8px rgba(17, 17, 19, 0.2)',
    smaller: '0px 1px 8px rgba(17, 17, 19, 0.1)',
    error: '0px 0px 8px rgba(210, 25, 25, 0.4)',
    focus: '0px 0px 8px rgba(88, 171, 48, 0.4)',
  },
}

// icon sprite map by name.
export const icons = {
  'caret-down': [0, 3],
  'caret-left': [0, 1],
  'caret-right': [0, 0],
  'caret-up': [0, 2],
  'return-left': [0, 5],
  'return-right': [0, 4],
  'sign-in': [0, 7],
  'sign-out': [0, 6],
  add: [3, 6],
  admin: [4, 3],
  alert: [1, 3],
  bookmark: [3, 8],
  calendar: [2, 1],
  chart: [2, 2],
  check: [2, 0],
  close: [3, 2],
  copy: [3, 1],
  delete: [3, 3],
  dollar: [2, 3],
  download: [0, 8],
  edit: [3, 0],
  external: [0, 10],
  favorite: [3, 10],
  filter: [3, 11],
  flash: [3, 12],
  history: [4, 7],
  home: [4, 0],
  hot: [2, 5],
  image: [2, 6],
  info: [1, 2],
  location: [4, 9],
  na: [1, 4],
  plus: [3, 5],
  rotate: [3, 13],
  search: [3, 4],
  settings: [4, 2],
  success: [1, 1],
  title: [2, 7],
  trash: [3, 9],
  upload: [0, 9],
  user: [2, 8],
  view: [3, 7],
  wallet: [2, 4],
  warning: [1, 0],
}

// constants for dimensions.
export const constants = {
  WRAPPER_HEIGHT: window.innerHeight,
  WRAPPER_MAXWIDTH: '100%',
  TABLE_CELL_MIN_WIDTH: '112px',
  TABLE_CELL_MID_WIDTH: '176px',
  TABLE_CELL_MAX_WIDTH: '232px',
}
