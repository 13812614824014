import { useEffect } from 'react'

import { fetchLocations } from '../../locations/locationsSlice'
import { fetchUsers } from '../../users/usersSlice'
import { selectLocationsStatus } from '../../locations/locationsSelectors'
import { selectUsersStatus } from '../../users/usersSelectors'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { selectAuditDataStatus } from '../../auditData/auditDataSelectors'
import { fetchAuditData } from '../../auditData/auditDataSlice'
import { fetchAudits } from '../../audits/auditsSlice'
import { selectAuditsStatus } from '../../audits/auditsSelectors'

const useFetchAuditsData = () => {
  const dispatch = useAppDispatch()

  const auditDataStatus = useAppSelector(selectAuditDataStatus)
  const locationsStatus = useAppSelector(selectLocationsStatus)
  const usersStatus = useAppSelector(selectUsersStatus)
  const loaded = useAppSelector(selectAuditsStatus)
  useEffect(() => {
    const fetchData = async () => {
      if (auditDataStatus === 'idle') dispatch(fetchAuditData())
      if (usersStatus === 'idle') dispatch(fetchUsers())
      if (locationsStatus === 'idle') dispatch(fetchLocations())

      if (
        auditDataStatus === 'succeeded' &&
        usersStatus === 'succeeded' &&
        locationsStatus === 'succeeded' &&
        loaded === 'idle'
      ) {
        dispatch(fetchAudits())
      }
    }
    fetchData()
  }, [dispatch, auditDataStatus, locationsStatus, usersStatus, loaded])

  return loaded
}

export default useFetchAuditsData
