// dependencies.
import styled from 'styled-components'
// components.
import { Body, Pill } from '../../../Typography'
import IconFeedback from '../../../Icon/IconFeedback'
// utils.
import auditStatusFormat from '../../../../js/auditStatusFormat'
import dateFormat from '../../../../js/dateFormat'
import { theme } from '../../../../styleguide'
// constants.
import { DATE_FORMAT } from '../../../../constants'

// helpers
const baseUnit = theme.fontSize.x16

// partials.
const Wrapper = styled.li({
  backgroundColor: theme.colors.white,
  border: `1px solid ${theme.opacity.ash30}`,
  borderRadius: baseUnit / 2,
  display: 'flex',
  filter: `drop-shadow(${theme.shadows.smaller})`,
  flexDirection: 'column',
  gap: baseUnit / 2,
  minHeight: baseUnit * 4,
  padding: baseUnit / 2,
  width: '100%',
})

const Header = styled.div({
  alignItems: 'center',
  borderBottom: `1px solid ${theme.opacity.ash30}`,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingBottom: baseUnit / 2 - 1,
  width: '100%',
})

const Content = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
})

const ContentLeft = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: baseUnit / 2 - 2,
  justifyContent: 'flex-start',
})

const ContentRight = styled.div({
  alignItems: 'flex-end',
  display: 'flex',
  flexDirection: 'column',
  gap: baseUnit,
  justifyContent: 'flex-start',
})

const Footer = styled.div({
  alignItems: 'center',
  border: `1px solid ${theme.opacity.ash50}`,
  borderRadius: baseUnit / 2,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  margin: 0,
  padding: '2px 2px 2px 8px',
  width: '100%',
})

const StyledBody = styled(Body)({
  alignItems: 'center',
  display: 'flex',
  gap: baseUnit / 2,
})

// main component.
const AuditoriesMobileItem = ({ item }) => {
  // only show if there's items.
  if (!item) return null

  const {
    auditor,
    comments,
    feedback,
    location,
    locationP,
    number,
    person,
    shift,
    status,
    completedDate,
    isContractor,
  } = item

  // return content.
  return (
    <Wrapper>
      <Header>
        <Body type="caption" nowrap>
          Nº: {number}
        </Body>
        <Body size="smallest" nowrap>
          <b>{auditor}</b> - {dateFormat(completedDate, DATE_FORMAT)}
        </Body>
      </Header>

      <Content>
        <ContentLeft>
          <StyledBody size="smallest" weight="bold" nowrap>
            {locationP ? `${locationP} / ${location}` : location}
          </StyledBody>
          <StyledBody size="smallest" nowrap>
            Turno <b>{shift}</b>
          </StyledBody>
          <StyledBody size="smallest" nowrap>
            Observado: <b>{isContractor ? person : 'CCU'}</b>
          </StyledBody>
        </ContentLeft>

        <ContentRight>
          <Pill type={status !== 0 ? 2 : 0}>{auditStatusFormat(status)}</Pill>
          {feedback ? <IconFeedback size={24} type={feedback} /> : null}
        </ContentRight>
      </Content>

      <Footer>
        <Body size="smallest">{comments}</Body>
      </Footer>
    </Wrapper>
  )
}

export default AuditoriesMobileItem
