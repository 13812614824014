// depenmdencies.
import styled from 'styled-components'
// components.
import { Body, Pill } from '../../../Typography'
// utils.
import { useDimensions } from '../../../../js/hooks'
import cardStatusFormat from '../../../../js/cardStatusFormat'
import { cardsStatus } from '../../../../js/cardsUtils'
import { theme } from '../../../../styleguide'

// helpers.
const CHART_HEIGHT = 400
const CHART_WIDTH = CHART_HEIGHT

const svgProps = {
  viewBox: `0 0 480 480`,
  preserveAspectRatio: 'xMinYMax meet',
  version: 1.1,
  xmlns: 'http://www.w3.org/2000/svg',
}

// partials.
const Wrapper = styled.div({
  alignItems: 'center',
  display: 'flex',
  height: (props) => `${props.isMobile ? CHART_HEIGHT / 2 : CHART_HEIGHT}px`,
  justifyContent: 'center',
  width: '100%',
})

const Svg = styled.svg({
  maxHeight: CHART_HEIGHT,
  maxWidth: CHART_WIDTH,
  width: '100%',
})

const Pie = styled.circle({
  fill: 'none',
  stroke: (props) => props.color || theme.colors.black,
  strokeWidth: 80,
})

const Indicators = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  padding: 24,
})

// main component.
const PieChart = ({ cards = [] }) => {
  const { isMobile } = useDimensions()

  const pending = cardsStatus(0, cards)
  const inProgress = cardsStatus(1, cards)
  const done = cardsStatus(2, cards)

  const getMaxCards = pending.length + inProgress.length + done.length

  const processedItems = () => {
    let cumulativeSum = 0

    const chunks = [
      {
        amount: pending.length,
        percentage: pending.length ? (pending.length * 1000) / getMaxCards : 0,
        color: theme.colors.alert,
      },
      {
        amount: inProgress.length,
        percentage: inProgress.length ? (inProgress.length * 1000) / getMaxCards : 0,
        color: theme.colors.warning,
      },
      {
        amount: done.length,
        percentage: done.length ? (done.length * 1000) / getMaxCards : 0,
        color: theme.colors.success,
      },
    ]

    const newChunksArr = chunks.map((item) => {
      cumulativeSum += item.percentage || 0
      return { ...item, cumulativePercentage: cumulativeSum - item.percentage }
    })

    return newChunksArr
  }

  return (
    <Wrapper isMobile={isMobile}>
      {cards.length ? (
        <>
          <Svg {...svgProps}>
            <g transform="translate(0, 0)">
              {processedItems().map(({ percentage, cumulativePercentage, color }, i) => (
                <Pie
                  key={i}
                  r={159}
                  cx="50%"
                  cy="50%"
                  color={color}
                  strokeDasharray={`${percentage} 1000`}
                  strokeDashoffset={-cumulativePercentage}
                />
              ))}
            </g>
          </Svg>

          <Indicators>
            {processedItems().map(({ percentage, amount }, i) => (
              <Pill key={i} type={i} width={136}>
                {(percentage / 10).toFixed(1)}% {cardStatusFormat(i)} ({amount})
              </Pill>
            ))}
          </Indicators>
        </>
      ) : (
        <Body color="grey" align="center">
          No se encontraron resultados.
        </Body>
      )}
    </Wrapper>
  )
}

export default PieChart
