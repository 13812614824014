// dependencies.
import { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
// components.
import { Body, Label } from '../../components/Typography'
import { Icon } from '../../components/Icon'
// utils.
import setBackgroundColor from '../../js/setBackgroundColor'
import useAccessRedirect from '../../js/hooks/useAccessRedirect'
import useDimensions from '../../js/hooks/useDimensions'
import { selectUserData } from '../../js/auth/authSelectors'
import { useAppSelector } from '../../js/store/hooks'
import { theme } from '../../styleguide'
// constants.
import {
  NAVIGATION_WIDTH,
  NAVIGATION_CONDENSED_WIDTH,
  HEADER_HEIGHT_DESKTOP,
} from '../../constants'
import versionInfo from '../../version.json'

// helpers
const baseUnit = theme.fontSize.x16

// partials.
const NavigationWrapper = styled.div(
  {
    alignItems: 'flex-start',
    background: (props) => props.background,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    left: 0,
    maxHeight: '100vh',
    minWidth: (props) =>
      props.condensed ? `${NAVIGATION_CONDENSED_WIDTH}px` : `${NAVIGATION_WIDTH}px`,
    overflow: 'auto',
    padding: '0 16px 32px',
    position: 'fixed',
    top: 0,
    transition: 'all 200ms ease',
    width: (props) =>
      props.condensed ? `${NAVIGATION_CONDENSED_WIDTH}px` : `${NAVIGATION_WIDTH}px`,
    zIndex: 9,
  },
  `@media print { display: none; }`
)

const TitleWrapper = styled.div({
  alignItems: 'center',
  cursor: (props) => (props.isMobile ? 'default' : 'pointer'),
  display: 'flex',
  justifyContent: 'space-between',
  minHeight: HEADER_HEIGHT_DESKTOP,
  padding: '24px 0',
  width: '100%',
})

const Title = styled(Body)({
  textTransform: 'uppercase',
  width: (props) => (props.condensed ? 0 : '100%'),
})

const Item = styled(Label)({
  alignItems: 'center',
  cursor: (props) => (props.active ? 'text' : 'pointer'),
  display: 'flex',
  flexShrink: 0,
  padding: '14px 0',
  position: 'relative',
  width: '100%',
})

const SubTitle = styled(Body)({
  alignItems: 'center',
  display: 'flex',
  flexShrink: 0,
  gap: baseUnit / 2,
  height: 32,
  margin: '16px 0 8px',
  opacity: 0.6,
  pointerEvents: 'none',
  width: '100%',
})

const Version = styled(Body)({
  alignItems: 'bottom',
  color: theme.colors.white,
  fontFamily: 'monospace',
  fontSize: theme.fontSize.x12,
  height: 16,
  marginTop: 16,
  minHeight: 16,
  opacity: 0.2,
  position: 'relative',
  whiteSpace: 'nowrap',
  width: '100%',
})

const Divider = styled.hr({
  backgroundColor: theme.opacity.white10,
  border: 'none',
  display: 'block',
  height: 2,
  minHeight: 1,
  pointerEvents: 'none',
  width: '100%',
})

const Spacer = styled.span({
  display: 'block',
  height: '100%',
  margin: '8px 0',
  pointerEvents: 'none',
  width: '100%',
})

const ActiveIndicator = styled.span({
  backgroundColor: theme.colors.primary,
  borderRadius: '0 4px 4px 0',
  display: 'block',
  height: baseUnit / 2,
  left: -baseUnit,
  position: 'absolute',
  width: baseUnit / 2,
})

// main component.
const Navigation = ({ title, items, expanded, condensed = false, onClick }) => {
  // hooks.
  const userData = useAppSelector(selectUserData)
  const activePath = useAccessRedirect(userData)
  const { isMobile } = useDimensions()

  // states.
  const [version, setVersion] = useState('')
  useEffect(() => {
    const currentVersion = versionInfo.version
    setVersion(currentVersion)
  }, [])

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //
  // only show content if mobile or open.
  if (isMobile && !expanded) return null

  // return content.
  return (
    <NavigationWrapper
      background={setBackgroundColor(userData.isAuditor ? 'auditor' : userData.access)}
      condensed={condensed}
      isMobile={isMobile}
    >
      <TitleWrapper condensed={condensed} isMobile={isMobile} onClick={onClick}>
        {!condensed && (
          <Title color="white" condensed={condensed} nowrap>
            {title}
          </Title>
        )}
        {!isMobile && (
          <Icon name={condensed ? 'caret-right' : 'caret-left'} color="white" size={16} />
        )}
      </TitleWrapper>

      {items.map((item, i) => {
        const isActive = item.path === activePath

        if (item.type === 'divider') return <Divider key={i} />
        if (item.type === 'spacer') return <Spacer key={i} />
        if (item.type === 'blockspacer')
          return (
            <Fragment key={i}>
              <Divider />
              {!condensed && (
                <>
                  <SubTitle color="white" nowrap>
                    <Icon name={item.icon} color="white" size={16} />
                    {item.label}
                  </SubTitle>
                  <Divider />
                </>
              )}
            </Fragment>
          )

        return (
          <Item
            key={i}
            size="smaller"
            color={isActive ? 'primary' : 'white'}
            to={!isActive && item.path}
            active={isActive}
            condensed={condensed}
            nowrap
          >
            {isActive && <ActiveIndicator />}
            {condensed ? (
              <Icon key={i} name={item.icon} color={isActive ? 'primary' : 'white'} size={20} />
            ) : (
              item.label
            )}
          </Item>
        )
      })}
      {!condensed && (
        <>
          <Version>Versión: {version}</Version>
        </>
      )}
    </NavigationWrapper>
  )
}

export default Navigation
