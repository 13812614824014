import { Fragment, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Body } from '../../../Typography'
import { theme } from '../../../../styleguide'
import { useDimensions } from '../../../../js/hooks'
import { Input } from '../../../Form'

const baseUnit = theme.fontSize.x16

const personOptions = [
  { name: 'all', label: 'Todos' },
  { name: 'int', label: 'CCU' },
  { name: 'ext', label: 'Contratista' },
]

const scaleOptions = [
  { name: '100', label: '100' },
  { name: '90', label: '90' },
  { name: '80', label: '80' },
  { name: '70', label: '70' },
  { name: '60', label: '60' },
  { name: '50', label: '50' },
  { name: '40', label: '40' },
  { name: '30', label: '30' },
  { name: '25', label: '25' },
  { name: '20', label: '20' },
  { name: '15', label: '15' },
  { name: '10', label: '10' },
  { name: '5', label: '5' },
]

const CHART_HEIGHT = baseUnit * 29
const CHART_LABEL_PLACEHOLDER = baseUnit * 8
const CHART_AXIS_PADDING = baseUnit * 1.5

const buildDataSource = (epp, items, dynamicColumns) => {
  const dynamicData = dynamicColumns.map(({ id, label }) => {
    const hasValue = items?.find(({ ref }) => ref.id === id) ?? {}
    return { label, amount: hasValue.value || 0 }
  })
  const dataArray = [...dynamicData, { label: 'Uso de EPP', amount: epp }]
  return dataArray.sort((a, b) => b.amount - a.amount).filter((el) => el !== null)
}

const Wrapper = styled.div({
  alignItems: (props) => (props.isEmpty ? 'center' : 'flex-end'),
  display: 'flex',
  flexDirection: 'row',
  height: (props) => `${props.height}px`,
  justifyContent: (props) => (props.isEmpty ? 'center' : 'flex-start'),
  marginTop: baseUnit * 3,
  padding: (props) => (props.isEmpty ? 0 : `${CHART_AXIS_PADDING}px`),
  paddingBottom: (props) => (props.isEmpty ? 0 : `${CHART_LABEL_PLACEHOLDER}px`),
  position: 'relative',
  width: '100%',
})

const AxisY = styled.i({
  borderLeft: `1px solid ${theme.opacity.ash80}`,
  display: 'block',
  height: `calc(100% - ${CHART_LABEL_PLACEHOLDER - CHART_AXIS_PADDING}px)`,
  left: CHART_AXIS_PADDING,
  pointerEvents: 'none',
  position: 'absolute',
  top: 0,
})

const RightAxisY = styled.i({
  borderRight: `1px solid ${theme.opacity.ash80}`,
  display: 'block',
  height: `calc(100% - ${CHART_LABEL_PLACEHOLDER - CHART_AXIS_PADDING}px)`,
  right: CHART_AXIS_PADDING,
  pointerEvents: 'none',
  position: 'absolute',
  top: 0,
})

const AxisX = styled.i({
  borderTop: `1px solid ${theme.opacity.ash80}`,
  display: 'block',
  left: 0,
  pointerEvents: 'none',
  position: 'absolute',
  bottom: CHART_LABEL_PLACEHOLDER,
  width: '100%',
})

const SemiAxisX = styled.i({
  borderTop: `1px solid ${theme.opacity.ash20}`,
  bottom: (props) => `${props.bottom}px`,
  display: 'block',
  left: CHART_AXIS_PADDING,
  pointerEvents: 'none',
  position: 'absolute',
  width: `calc(100% - ${CHART_AXIS_PADDING}px)`,
})

const Bar = styled.span({
  alignItems: 'flex-end',
  background: theme.colors.primary,
  display: 'flex',
  height: (props) => `${props.height}px` || 0,
  justifyContent: 'center',
  left: (props) => props.left || 0,
  margin: (props) => `0 ${props.width / (baseUnit / 2)}px`,
  position: 'relative',
  width: (props) => `${(props.width / (baseUnit / 2)) * 6}px`,
})

const LinePath = styled.svg({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  padding: (props) => (props.isEmpty ? 0 : `0px ${CHART_AXIS_PADDING}px`),
  paddingBottom: (props) => (props.isEmpty ? 0 : `${CHART_LABEL_PLACEHOLDER}px`),
  pointerEvents: 'none',
  zIndex: 100,
})

const StyledLabel = styled(Body)({
  bottom: 0,
  left: 0,
  position: 'absolute',
  transform: 'rotate(-60deg) translate(-80%, -340%)',
  width: CHART_LABEL_PLACEHOLDER,
})

const StyledAxisLabel = styled(Body)({
  bottom: (props) => `${props.bottom}px`,
  left: 0,
  paddingRight: baseUnit / 4,
  pointerEvents: 'none',
  position: 'absolute',
  transform: 'translateY(64%)',
  width: CHART_AXIS_PADDING,
})

const RightAxisLabel = styled(Body)({
  bottom: (props) => `${props.bottom}px`,
  position: 'absolute',
  right: -baseUnit / 3,
  transform: 'translateY(50%)',
  pointerEvents: 'none',
  color: theme.colors.grey,
})

const StyledAmount = styled(Body)({
  position: 'absolute',
  top: 0,
  transform: 'translateY(-100%)',
  width: '100%',
})

const InputWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: baseUnit / 2,
  position: 'absolute',
  top: 0,
  right: 0,
  padding: baseUnit / 2,
  marginBottom: baseUnit,
})

const ParetoChart = ({
  epp,
  items = [],
  dynamicColumns = [],
  activePerson,
  onPersonChange = () => {},
}) => {
  const wrapperRef = useRef(null)
  const { isMobile, width: viewPortWidth } = useDimensions()

  const data = buildDataSource(epp, items, dynamicColumns)

  const CHART_WIDTH = wrapperRef?.current ? wrapperRef?.current?.clientWidth - 16 : 800
  const CHART_RESPONSIVE_HEIGHT =
    viewPortWidth <= 1440 ? CHART_HEIGHT * 1.2 : isMobile ? CHART_HEIGHT / 1.5 : CHART_HEIGHT

  const getMaxY = data
    .map(({ amount }) => amount)
    .reduce((max, count) => {
      return Math.max(max, count)
    }, -Infinity)

  const nexFive = Math.ceil(getMaxY / 5) * 5
  const closestScaleOption = scaleOptions.reduce((prev, curr) => {
    return Math.abs(parseInt(curr.name) - nexFive) < Math.abs(parseInt(prev.name) - nexFive)
      ? curr
      : prev
  }, scaleOptions[12])
  const [scale, setScale] = useState(closestScaleOption.name)

  const axisSteps = Array.from({ length: scale })
  const rightAxisSteps = Array.from({ length: 10 }, (_, i) => (i + 1) * 10)

  const calcHeightPosition = (num) => {
    const availableHeight = CHART_RESPONSIVE_HEIGHT - CHART_LABEL_PLACEHOLDER - CHART_AXIS_PADDING
    const targetHeight = (num * 100) / scale
    return (targetHeight * availableHeight) / 100
  }

  const calcHeightPositionCumulative = (num) => {
    const availableHeight = CHART_RESPONSIVE_HEIGHT - CHART_LABEL_PLACEHOLDER - CHART_AXIS_PADDING
    const targetHeight = (num * 100) / 100
    return (targetHeight * availableHeight) / 100
  }

  const totalAmount = data.reduce((sum, { amount }) => sum + amount, 0)
  const processedItems = data.map(({ label, amount }, i) => ({
    label,
    amount,
    height: calcHeightPosition(amount),
    cumulative:
      (data.slice(0, i + 1).reduce((sum, { amount }) => sum + amount, 0) / totalAmount) * 100,
  }))

  const linePoints = processedItems
    .map(({ cumulative }, index) => {
      const x =
        (CHART_WIDTH / processedItems.length) * index + CHART_WIDTH / processedItems.length / 2
      const y =
        CHART_RESPONSIVE_HEIGHT - CHART_LABEL_PLACEHOLDER - calcHeightPositionCumulative(cumulative)
      return `${x},${y}`
    })
    .join(' ')

  const [activePersonValue, setActivePersonValue] = useState(activePerson?.value || 'all')
  function handlePersonChange(value) {
    if (value === activePersonValue) return
    setActivePersonValue(value)
    onPersonChange(value)
  }
  useEffect(() => {
    setActivePersonValue(activePerson?.value || 'all')
  }, [activePerson])

  return (
    <>
      <InputWrapper>
        <Input
          isMobile={isMobile}
          options={personOptions}
          name="isContractor"
          placeholder="Observados"
          defaultValue={activePersonValue}
          onChange={(value) => handlePersonChange(value)}
          width={viewPortWidth <= 1280 ? 88 : 112}
        />
        <Input
          isMobile={isMobile}
          options={scaleOptions}
          name="scale"
          iconL={isMobile || viewPortWidth > 1280 ? 'settings' : undefined}
          placeholder="Escala"
          defaultValue={closestScaleOption.name}
          onChange={(value) => setScale(value)}
          width={viewPortWidth <= 1280 ? (isMobile ? 100 : 80) : 120}
        />
      </InputWrapper>
      <Wrapper ref={wrapperRef} isEmpty={!items.length} height={CHART_RESPONSIVE_HEIGHT}>
        {items.length ? (
          <>
            <AxisY />
            <AxisX />
            <RightAxisY />

            {axisSteps.map((el, i) => {
              const value = i < 5 ? (i + 1) * (scale / 5) : null
              const elBottom = CHART_LABEL_PLACEHOLDER + calcHeightPosition(value)

              if (!value) return null

              return (
                <Fragment key={i}>
                  <StyledAxisLabel size="minimal" align="right" color="grey" bottom={elBottom}>
                    {value}
                  </StyledAxisLabel>
                  <SemiAxisX bottom={elBottom} />
                </Fragment>
              )
            })}

            {rightAxisSteps.map((percentage, i) => {
              const elBottom = CHART_LABEL_PLACEHOLDER + calcHeightPositionCumulative(percentage)

              return (
                <RightAxisLabel
                  key={`right-${i}`}
                  size="minimal"
                  align="left"
                  color="grey"
                  bottom={elBottom}
                  style={{
                    position: 'absolute',
                    right: CHART_AXIS_PADDING,
                    transform: 'translateY(50%)',
                  }}
                >
                  {`${percentage}%`}
                </RightAxisLabel>
              )
            })}

            <LinePath>
              <polyline
                points={linePoints}
                fill="none"
                stroke={theme.colors.warning}
                strokeWidth="2"
              />
            </LinePath>

            {processedItems.map(({ label, amount, height }, i) => {
              const width =
                CHART_WIDTH / processedItems.length < baseUnit * 6
                  ? CHART_WIDTH / processedItems.length
                  : baseUnit * 6

              return (
                <Bar key={i} left={width * i} height={height} width={width}>
                  <StyledAmount type="caption" align="center">
                    {amount}
                  </StyledAmount>
                  <StyledLabel size="minimal" align="right" color="grey" nowrap>
                    {label}
                  </StyledLabel>
                </Bar>
              )
            })}
          </>
        ) : (
          <Body color="grey" align="center">
            No se encontraron resultados.
          </Body>
        )}
      </Wrapper>
    </>
  )
}

export default ParetoChart
