// dependencies.
import { useEffect, useRef } from 'react'
import styled from 'styled-components'
// components.
import { Body } from '../../../Typography'
// utils.
import dateFormat from '../../../../js/dateFormat'
import { useFocus } from '../../../../js/hooks'
import { theme } from '../../../../styleguide'
import IconFeedback from '../../../Icon/IconFeedback'
// constants.
// import { DATE_TIME_FORMAT } from '../../../../constants'

// helpers
const baseUnit = theme.fontSize.x16

const getCellBackground = [
  theme.colors.ash,
  theme.colors.success,
  theme.colors.alert,
  theme.colors.warning,
]

export const COLUMN_LAYOUT_START = [
  { label: 'Número', dbKey: 'number', columns: 1 },
  { label: 'Año', dbKey: 'id', columns: 1 },
  { label: 'Mes', dbKey: 'status', columns: 1 },
  { label: 'Sem.', dbKey: 'type', columns: 1 },
  { label: 'Fecha', dbKey: 'responsibleRef', columns: 2 },
  { label: 'Observador', dbKey: 'extremeDateReal', columns: 3 },
  { label: 'Sector de Observador', columns: 2 },
  { label: 'Área Observada', columns: 2 },
  { label: 'Empresa', columns: 2 },
  { label: 'Turno', columns: 2 },
  { label: 'Uso de EPP', columns: 1, isDynamic: true },
]
export const COLUMN_LAYOUT_END = [
  { label: 'Resultado', columns: 2 },
  { label: 'Funcionario', columns: 2 },
  { label: 'Actos Observados (Seguros/Inseguros)', columns: 6 },
]
const totalColumnsStart = COLUMN_LAYOUT_START.reduce((sum, { columns }) => sum + columns, 0)
const totalColumnsEnd = COLUMN_LAYOUT_END.reduce((sum, { columns }) => sum + columns, 0)
export const minCellsWidth = totalColumnsStart + totalColumnsEnd

// partials.
const Wrapper = styled.li({
  backgroundColor: (props) => (props.isEven ? theme.colors.smoke : theme.colors.white),
  borderBottom: `1px solid ${theme.opacity.ash80}`,
  display: 'flex',
  flexDirection: 'row',
  height: baseUnit * 2,
  transition: 'background 200ms ease',
  width: '100%',
  '&:focus': {
    backgroundColor: theme.opacity.primary20,
    outline: 'none',
  },
})

const Cell = styled.div({
  alignItems: 'center',
  backgroundColor: (props) =>
    props.type !== undefined ? getCellBackground[props.type] : 'transparent',
  borderRight: `1px solid ${theme.opacity.ash80}`,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  padding: `${baseUnit / 8}px ${baseUnit}px`,
  width: (props) => `${props.width}%`,
})

const StyledBody = styled(Body)({
  alignItems: 'center',
  display: 'flex',
  gap: baseUnit / 2,
})

// main component.
const AuditoriesDesktopItem = ({ item, dynamicColumns, isEven, onFocus = () => {} }) => {
  // handle focus event.
  const itemRef = useRef(null)
  const { focused } = useFocus(itemRef)
  useEffect(() => onFocus(focused), [focused])

  // only show if there's items.
  if (!item) return null

  const {
    auditItemEpp,
    auditItems,
    auditor,
    comments,
    feedback,
    location,
    locationP,
    number,
    person,
    shift,
    completedDate,
    isContractor,
  } = item

  const cellWidth = 100 / (minCellsWidth + dynamicColumns.length)

  // return content.
  return (
    <Wrapper ref={itemRef} isEven={isEven} tabIndex={0}>
      <Cell width={cellWidth * COLUMN_LAYOUT_START[0].columns}>
        <StyledBody size="minimal">{number ?? 0}</StyledBody>
      </Cell>
      <Cell width={cellWidth * COLUMN_LAYOUT_START[1].columns}>
        <StyledBody size="smaller">{dateFormat(completedDate, 'YY')}</StyledBody>
      </Cell>
      <Cell width={cellWidth * COLUMN_LAYOUT_START[2].columns}>
        <StyledBody size="smaller">{dateFormat(completedDate, 'MM')}</StyledBody>
      </Cell>
      <Cell width={cellWidth * COLUMN_LAYOUT_START[3].columns}>
        <StyledBody size="smaller">{dateFormat(completedDate, 'WW')}</StyledBody>
      </Cell>
      <Cell width={cellWidth * COLUMN_LAYOUT_START[4].columns}>
        <StyledBody size="smallest">{dateFormat(completedDate)}</StyledBody>
      </Cell>
      <Cell width={cellWidth * COLUMN_LAYOUT_START[5].columns}>
        <StyledBody size="minimal">{auditor}</StyledBody>
      </Cell>
      <Cell width={cellWidth * COLUMN_LAYOUT_START[6].columns}>
        <StyledBody size="minimal">{locationP || location || '--'}</StyledBody>
      </Cell>
      <Cell width={cellWidth * COLUMN_LAYOUT_START[7].columns}>
        <StyledBody size="minimal">{locationP ? location : '--'}</StyledBody>
      </Cell>
      <Cell width={cellWidth * COLUMN_LAYOUT_START[8].columns}>
        <StyledBody size="minimal">{person}</StyledBody>
      </Cell>
      <Cell width={cellWidth * COLUMN_LAYOUT_START[9].columns}>
        <StyledBody size="minimal">{shift}</StyledBody>
      </Cell>

      <Cell
        width={cellWidth * COLUMN_LAYOUT_START[10].columns}
        type={auditItemEpp?.value ?? undefined}
      >
        {auditItemEpp ? (
          <StyledBody size="smallest" weight="bold" color="white">
            {auditItemEpp.value}
          </StyledBody>
        ) : null}
      </Cell>
      {dynamicColumns.map(({ id }, i) => {
        const hasValue = auditItems?.find(({ ref }) => ref.id === id) ?? {}
        return (
          <Cell key={i} width={cellWidth} type={hasValue.value ?? undefined}>
            {hasValue ? (
              <StyledBody size="smallest" weight="bold" color="white">
                {hasValue.value}
              </StyledBody>
            ) : null}
          </Cell>
        )
      })}

      <Cell width={cellWidth * COLUMN_LAYOUT_END[0].columns}>
        {feedback ? <IconFeedback size={24} type={feedback} /> : null}
      </Cell>
      <Cell width={cellWidth * COLUMN_LAYOUT_END[1].columns}>
        <StyledBody size="minimal">{isContractor}</StyledBody>
      </Cell>
      <Cell width={cellWidth * COLUMN_LAYOUT_END[2].columns}>
        <StyledBody size="minimal">{comments}</StyledBody>
      </Cell>
    </Wrapper>
  )
}

export default AuditoriesDesktopItem
