import styled from 'styled-components'
import { theme } from '../../../styleguide'
import { Body, Headline } from '../../Typography'

// partials.
export const Container = styled.div({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 480,
  width: '100%',
})

export const Wrapper = styled.div({
  alignItems: 'flex-start',
  background: (props) => props.theme || theme.colors.white,
  borderRadius: (props) => (props.isTablet && !props.isMinimal ? '16px 16px 0 0' : '16px'),
  border: (props) => `2px solid ${props.theme || theme.colors.ash}`,
  boxShadow: theme.shadows.larger,
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  overflowY: (props) => (props.isTablet ? 'auto' : 'hidden'),
  padding: (props) => (props.isTablet && !props.isMinimal ? '8px 0 80px' : '8px 0'),
  position: 'relative',
  transition: 'all 200ms ease',
  width: '100%',
  zIndex: (props) => props.zEl || 1,
})

export const HeadingWrapper = styled.div({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '8px 8px 16px',
  width: '100%',
})

export const HeaderWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 0 8px',
  width: '100%',
})

export const ContentWrapper = styled.div({
  alignItems: 'flex-start',
  borderTop: `1px solid ${theme.opacity.black20}`,
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  width: '100%',
})

export const ContentColumn = styled.div({
  alignItems: (props) => props.align || 'start',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 0,
  gap: 8,
  padding: '8px 16px',
})

export const ContentRow = styled.div({
  alignItems: (props) => props.align || 'center',
  display: 'flex',
  flexDirection: 'row',
  gap: (props) => (props.isMobile ? 4 : 8),
  justifyContent: (props) => props.justify || 'space-between',
  padding: '0 12px',
  width: '100%',
  flexWrap: (props) => (props.isMobile ? 'wrap' : 'nowrap'),
})

export const StyledType = styled(Headline)({
  letterSpacing: 1,
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
})

export const StyledCopy = styled(Body)({
  marginBottom: 4,
  marginTop: 4,
  flexShrink: 0,
  flexGrow: 1,
  width: '100%',
  maxWidth: 'calc(100% - 40px)',
})

export const StyledBody = styled(Body)({
  marginBottom: 4,
  marginTop: 4,
  flexShrink: 0,
  flexGrow: 1,
})

export const StyledCaption = styled(Body).attrs({
  type: 'caption',
})({
  color: theme.opacity.black40,
  marginTop: 4,
  marginBottom: 4,
  textTransform: 'uppercase',
  flexGrow: 0,
  width: 'max-content',
})

export const StyledDivider = styled(Body).attrs({
  align: 'center',
  color: 'white',
  size: 'smaller',
  weight: 'bold',
})({
  background: theme.colors.alert,
  borderBottom: `1px solid ${theme.opacity.black20}`,
  borderTop: `1px solid ${theme.opacity.black20}`,
  height: 24,
  letterSpacing: 1,
  lineHeight: '22px',
  textTransform: 'uppercase',
  width: '100%',
})

export const StyledTypeNumberWrapper = styled.div({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
})

export const StyledLocationWrapper = styled.div({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
})

export const StyledItem = styled.div({
  alignItems: 'stretch',
  borderBottom: `1px solid ${theme.opacity.black20}`,
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  gap: 8,
  justifyContent: 'space-between',
  paddingLeft: 24,
  width: '100%',
})

export const DateWrapper = styled.div({
  background: theme.colors.alert,
  border: `2px solid ${theme.opacity.black20}`,
  borderRadius: 8,
  display: 'flex',
  flexDirection: 'column',
  height: 48,
  justifyContent: 'center',
  width: 64,
})

export const DateBoxContent = styled.div({
  background: theme.opacity.white80,
  borderRadius: '0 0 5px 5px',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  justifyContent: 'flex-end',
})

export const DescriptionWrapper = styled.div({
  alignItems: 'stretch',
  background: theme.opacity.black10,
  borderBottom: `1px solid ${theme.opacity.black20}`,
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  justifyContent: 'space-between',
  padding: '8px 16px 16px',
  width: '100%',
})

export const FooterWrapper = styled.div({
  alignItems: 'center',
  bottom: (props) => (props.isTablet ? 0 : 'unset'),
  display: 'flex',
  gap: 8,
  justifyContent: 'center',
  left: (props) => (props.isTablet ? '50%' : 'unset'),
  margin: '0 auto',
  padding: '24px 16px',
  width: (props) => (props.isTablet ? 'calc(100% - 120px)' : '100%'),
})

export const StyledValue = styled.div({
  alignItems: 'center',
  background: theme.opacity.black10,
  borderLeft: `1px solid ${theme.opacity.black10}`,
  color: theme.colors.grey,
  display: 'flex',
  flex: 'none',
  flexShrink: 0,
  justifyContent: 'center',
  minHeight: 24,
  width: 32,
})

const colorOverride = ['', 'success', 'alert', 'warning']

export const RadioButton = styled.div({
  alignItems: 'center',
  border: (props) => `2px solid ${theme.colors[colorOverride[props.type] || 'grey']}`,
  borderRadius: '50%',
  display: 'flex',
  height: 16,
  justifyContent: 'center',
  width: 16,
})

export const RadioDot = styled.div({
  background: (props) => theme.colors[colorOverride[props.type]],
  borderRadius: '50%',
  height: 8,
  width: 8,
})
