// dependencies.
import { useEffect, useState } from 'react'

import { addDoc, collection, doc, getDoc, setDoc } from 'firebase/firestore'
// components.
import {
  AuditSettingsDesktopItem,
  AuditSettingsDesktopSort,
  AuditSettingsDesktopWrapper,
  AuditSettingsMobileItem,
  AuditSettingsMobileWrapper,
} from '../../../components/screens/AuditSettings'
import { Drawer } from '../../../components/Modal'
import { Form } from '../../../components/Form'
import { Icon, Loader } from '../../../components/Icon'
import Header from '../../../components/Typography/Header'
import AdminTemplate from '../../../templates/AdminPanel'
// utils.
import notifyToast from '../../../js/notifyToast'
import { db } from '../../../js/firebase/firebase'
import { useDimensions } from '../../../js/hooks'
// constants.
import { NEW_EPP_FORM, NEW_ITEM_FORM, NEW_PERSON_FORM } from '../constants'
import {
  auditDataAdded,
  auditDataUpdated,
  fetchAuditData,
  setFilters,
} from '../../../js/auditData/auditDataSlice'
import {
  selectAuditDataStatus,
  selectFilteredEpps,
  selectFilteredItems,
  selectFilteredPersons,
} from '../../../js/auditData/auditDataSelectors'
import { useAppDispatch, useAppSelector } from '../../../js/store/hooks'
import AuditSettingsDesktopItemLarge from '../../../components/screens/AuditSettings/DesktopItems/Item-large'
import AuditSettingsDesktopSortLarge from '../../../components/screens/AuditSettings/DesktopItems/Sort-large'
import { clearFilters } from '../../../js/audits/auditsSlice'

/**********************************/
/*                                */
/*    Stop Audit Data Screen      */
/*                                */
/**********************************/

// main component.
const StopAuditSettingsScreen = ({ navMenu, navTitle }) => {
  const dispatch = useAppDispatch()
  const loaded = useAppSelector(selectAuditDataStatus)
  const filteredEpps = useAppSelector(selectFilteredEpps())
  const filteredItems = useAppSelector(selectFilteredItems())
  const filteredPersons = useAppSelector(selectFilteredPersons())

  const { isMobile } = useDimensions()

  const [modalContent, setModalContent] = useState({})
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    if (loaded === 'idle') dispatch(fetchAuditData())
  }, [dispatch, loaded])

  useEffect(() => {
    dispatch(clearFilters())
  }, [])

  // ------------------------------------------------------------------------------------------- //
  // MODALS AND DRAWERS                                                                          //
  // ------------------------------------------------------------------------------------------- //

  // open modal.
  const handleModalOpen = (open) => setModalOpen(open)

  // handle add new EPP modal.
  const handleNewEppModal = () => {
    setModalContent({
      title: 'Agregar nuevo EPP',
      component: <Form items={NEW_EPP_FORM} onClick={(obj) => handleCreateEppClick(obj, 'EPP')} />,
    })
    setModalOpen(true)
  }
  // handle edit selected EPP modal.
  const handleEditEppModal = (obj) => {
    setModalContent({
      title: 'Actualizar nombre EPP',
      component: (
        <Form
          items={NEW_EPP_FORM}
          defaultValues={obj}
          onClick={(obj) => handleSaveEppClick(obj, 'EPP')}
        />
      ),
    })
    setModalOpen(true)
  }
  // handle add new Item modal.
  const handleNewItemModal = () => {
    setModalContent({
      title: 'Agregar nuevo item',
      component: <Form items={NEW_ITEM_FORM} onClick={(obj) => handleCreateEppClick(obj)} />,
    })
    setModalOpen(true)
  }
  // handle edit selected Item modal.
  const handleEditItemModal = (obj) => {
    setModalContent({
      title: 'Actualizar item',
      component: (
        <Form
          items={NEW_ITEM_FORM}
          defaultValues={obj}
          onClick={(obj) => handleSaveEppClick(obj)}
        />
      ),
    })
    setModalOpen(true)
  }
  // handle add new Person modal.
  const handleNewPersonModal = () => {
    setModalContent({
      title: 'Agregar nueva empresa',
      component: <Form items={NEW_PERSON_FORM} onClick={(obj) => handleCreateEppClick(obj)} />,
    })
    setModalOpen(true)
  }
  // handle edit selected Person modal.
  const handleEditPersonModal = (obj) => {
    setModalContent({
      title: 'Actualizar empresa',
      component: (
        <Form
          items={NEW_PERSON_FORM}
          defaultValues={obj}
          onClick={(obj) => handleSaveEppClick(obj)}
        />
      ),
    })
    setModalOpen(true)
  }

  // ------------------------------------------------------------------------------------------- //
  // UPDATE DATABASE DATA                                                                        //
  // ------------------------------------------------------------------------------------------- //

  // handle create new audit data.
  const handleCreateEppClick = async (obj, type) => {
    obj.createdDate = new Date()
    obj.updatedDate = new Date()
    obj.audit = 'STOP'
    obj.type = type || obj.type
    if (!obj.type) return notifyToast('Debe seleccionar un tipo de item', 'error')
    await addDoc(collection(db, 'auditData'), obj)
      .then(async (auditDataRef) => {
        await setDoc(auditDataRef, { ref: auditDataRef }, { merge: true })
        notifyToast(`${obj.type} creado correctamente`, 'success')
        const newAuditDataDoc = await getDoc(auditDataRef)
        const newAuditData = { id: newAuditDataDoc.id, ...newAuditDataDoc.data() }
        dispatch(auditDataAdded(newAuditData))
      })
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))
    setModalOpen(false)
  }
  // handle audit data item update.
  const handleSaveEppClick = async (obj, type) => {
    obj.updatedDate = new Date()
    obj.audit = 'STOP'
    obj.type = type || obj.type
    const auditDataRef = doc(db, 'auditData', obj.ref.id)
    await setDoc(auditDataRef, { ...obj })
      .then(async () => {
        const updatedAuditDataDoc = await getDoc(auditDataRef)
        const updatedAuditData = { id: updatedAuditDataDoc.id, ...updatedAuditDataDoc.data() }
        dispatch(auditDataUpdated(updatedAuditData))
        notifyToast(`${type} actualizado correctamente`, 'success')
      })
      .catch(() => notifyToast('Ha ocurrido un error', 'error'))
    setModalOpen(false)
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <>
      <AdminTemplate
        navigation={navMenu}
        sectionTitle={navTitle}
        modalOpen={modalOpen}
        onOverlayClick={() => setModalOpen(false)}
      >
        {loaded === 'succeeded' ? (
          isMobile ? (
            <>
              <Header
                label={isMobile ? <Icon name="plus" color="white" /> : 'Agregar EPP'}
                direction="row"
                noBorder={isMobile}
                secondary={isMobile}
                hideButtonLabel={isMobile}
                onClick={() => handleNewEppModal()}
              >
                Equipos de Protección Personal
              </Header>
              <AuditSettingsMobileWrapper isEmpty={!!filteredEpps && !filteredEpps.length}>
                {filteredEpps.map((epp, i) => (
                  <AuditSettingsMobileItem
                    key={epp.id}
                    item={epp}
                    isEven={i % 2 === 0}
                    onEdit={() => handleEditEppModal(epp)}
                  />
                ))}
              </AuditSettingsMobileWrapper>
              <Header
                label={isMobile ? <Icon name="plus" color="white" /> : 'Agregar nuevo item'}
                direction="row"
                noBorder={isMobile}
                secondary={isMobile}
                hideButtonLabel={isMobile}
                onClick={() => handleNewItemModal()}
              >
                Items conducta u operación
              </Header>
              <AuditSettingsMobileWrapper isEmpty={!!filteredItems && !filteredItems.length}>
                {filteredItems.map((epp, i) => (
                  <AuditSettingsMobileItem
                    key={epp.id}
                    item={epp}
                    isEven={i % 2 === 0}
                    onEdit={() => handleEditItemModal(epp)}
                  />
                ))}
              </AuditSettingsMobileWrapper>
              <Header
                label={isMobile ? <Icon name="plus" color="white" /> : 'Agregar empresa'}
                direction="row"
                noBorder={isMobile}
                secondary={isMobile}
                hideButtonLabel={isMobile}
                onClick={() => handleNewPersonModal()}
              >
                Empresa o persona observada
              </Header>
              <AuditSettingsMobileWrapper isEmpty={!!filteredPersons && !filteredPersons.length}>
                {filteredPersons.map((epp, i) => (
                  <AuditSettingsMobileItem
                    key={epp.id}
                    item={epp}
                    isEven={i % 2 === 0}
                    onEdit={() => handleEditPersonModal(epp)}
                  />
                ))}
              </AuditSettingsMobileWrapper>
            </>
          ) : (
            <>
              <Header label="Agregar EPP" onClick={() => handleNewEppModal()}>
                Equipos de Protección Personal
              </Header>
              <AuditSettingsDesktopWrapper isEmpty={!!filteredEpps && !filteredEpps.length}>
                <AuditSettingsDesktopSort />
                {filteredEpps.map((epp, i) => (
                  <AuditSettingsDesktopItem
                    key={epp.id}
                    item={epp}
                    isEven={i % 2 === 0}
                    onEdit={() => handleEditEppModal(epp)}
                  />
                ))}
              </AuditSettingsDesktopWrapper>
              <Header label="Agregar nuevo item" onClick={() => handleNewItemModal()}>
                Items conducta u operación
              </Header>
              <AuditSettingsDesktopWrapper isEmpty={!!filteredItems && !filteredItems.length}>
                <AuditSettingsDesktopSortLarge
                  items={filteredItems}
                  onSort={(e) => dispatch(setFilters({ sort: e }))}
                />
                {filteredItems.map((item, i) => (
                  <AuditSettingsDesktopItemLarge
                    key={item.id}
                    item={item}
                    isEven={i % 2 === 0}
                    onEdit={() => handleEditItemModal(item)}
                  />
                ))}
              </AuditSettingsDesktopWrapper>
              <Header label="Agregar empresa" onClick={() => handleNewPersonModal()}>
                Empresa o persona observada
              </Header>
              <AuditSettingsDesktopWrapper isEmpty={!!filteredPersons && !filteredPersons.length}>
                <AuditSettingsDesktopSort />
                {filteredPersons.map((item, i) => (
                  <AuditSettingsDesktopItem
                    key={item.id}
                    item={item}
                    isEven={i % 2 === 0}
                    onEdit={() => handleEditPersonModal(item)}
                  />
                ))}
              </AuditSettingsDesktopWrapper>
            </>
          )
        ) : (
          <Loader />
        )}
      </AdminTemplate>
      <Drawer title={modalContent.title} isOpen={modalOpen} onChange={handleModalOpen}>
        {modalContent.component}
      </Drawer>
    </>
  )
}

export default StopAuditSettingsScreen
