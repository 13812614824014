// dependencies.
import styled from 'styled-components'
// components.
import { Body, Label } from '../../../Typography'
import { Icon } from '../../../Icon'
// utils.
import { theme } from '../../../../styleguide'

// helpers
const baseUnit = theme.fontSize.x16

export const COLUMN_LAYOUT = [
  { label: 'Orden', columns: 1, dbKey: 'order' },
  { label: 'Nombre', columns: 3 },
  { label: 'Descripción', columns: 4 },
  { label: 'Tipo', columns: 1, dbKey: 'type' },
  { label: 'Acciones', columns: 1 },
]
export const cellWidth = 10 // 10% (100% / 10 columns)

const typesMap = {
  EPP: 'EPP',
  CND: 'Conducta',
  OPS: 'Operación',
  INT: 'CCU',
  EXT: 'Externo',
}

// partials.
const Wrapper = styled.li({
  backgroundColor: (props) => (props.isEven ? theme.colors.smoke : theme.colors.white),
  borderBottom: `1px solid ${theme.opacity.ash80}`,
  display: 'flex',
  flexDirection: 'row',
  height: baseUnit * 3,
  transition: 'background 200ms ease',
  width: '100%',
  '&:focus': {
    backgroundColor: theme.opacity.primary20,
    outline: 'none',
  },
})

const Cell = styled.div({
  alignItems: 'center',
  borderRight: `1px solid ${theme.opacity.ash80}`,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  padding: `${baseUnit / 4}px ${baseUnit}px`,
  width: (props) => `${props.width}%`,
})

const StyledBody = styled(Body)({
  alignItems: 'center',
  display: 'flex',
  lineHeight: 1.1,
  width: '100%',
})

const StyledType = styled(Body).attrs({
  align: 'center',
  type: 'caption',
})({
  alignItems: 'center',
  display: 'flex',
})

const StyledEdit = styled(Label)({
  background: (props) => (props.disabled ? theme.colors.smoke : theme.colors.warning),
  border: (props) =>
    props.disabled ? `1px solid ${theme.colors.white}` : `1px solid ${theme.colors.warning}`,
  borderRadius: baseUnit / 2 - 2,
  display: 'flex',
  fontSize: theme.fontSize.x14,
  gap: 4,
  padding: '3px 6px 3px 8px',
  width: 'fit-content',
})

// main component.
const AuditSettingsDesktopItemLarge = ({ item, isEven, onEdit }) => {
  // only show if there's items.
  if (!item) return null

  const { order, name, description, type } = item

  const enableEdit = Boolean(onEdit)

  // return content.
  return (
    <Wrapper isEven={isEven} tabIndex={0}>
      <Cell width={cellWidth * COLUMN_LAYOUT[0].columns}>
        <StyledType>{order}</StyledType>
      </Cell>
      <Cell width={cellWidth * COLUMN_LAYOUT[1].columns}>
        <StyledBody size="smallest" weight="bold">
          {name}
        </StyledBody>
      </Cell>
      <Cell width={cellWidth * COLUMN_LAYOUT[2].columns}>
        <StyledBody size="smallest">{description}</StyledBody>
      </Cell>
      <Cell width={cellWidth * COLUMN_LAYOUT[3].columns}>
        <StyledType nowrap>{typesMap[type]}</StyledType>
      </Cell>
      <Cell width={cellWidth * COLUMN_LAYOUT[4].columns}>
        <StyledEdit color="white" disabled={!enableEdit} onClick={enableEdit && onEdit}>
          Editar
          <Icon name="edit" size={16} color={!enableEdit ? 'ash' : 'white'} />
        </StyledEdit>
      </Cell>
    </Wrapper>
  )
}

export default AuditSettingsDesktopItemLarge
