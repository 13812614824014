// dependencies.
import styled from 'styled-components'
// components.
import { Body } from '../../../Typography'
// utils.
import { theme } from '../../../../styleguide'
// constants.
import { BOTTOMBAR_HEIGHT, HEADER_HEIGHT_MOBILE } from '../../../../constants'

// helpers
const baseUnit = theme.fontSize.x16

// partials.
const ScrollWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  margin: 0,
  minHeight: `${BOTTOMBAR_HEIGHT + HEADER_HEIGHT_MOBILE}px`,
  overflowX: 'scroll',
  width: '100%',
})

const ContentWrapper = styled.ul({
  alignItems: 'center',
  backgroundColor: (props) => (props.isEmpty ? theme.colors.white : theme.colors.smoke),
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  gap: baseUnit / 2,
  listStyle: 'none',
  minWidth: '100%',
  padding: `8px 8px ${HEADER_HEIGHT_MOBILE}px`,
  width: 'fit-content',
})

// main component.
const CardsMobileWrapper = ({ isEmpty = false, children }) => (
  <ScrollWrapper>
    <ContentWrapper isEmpty={isEmpty}>
      {isEmpty ? <Body color="grey">No se encontraron resultados.</Body> : children}
    </ContentWrapper>
  </ScrollWrapper>
)

export default CardsMobileWrapper
