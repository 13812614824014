// depenmdencies.
import moment from 'moment'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
// components.
import { Input } from '../../../Form'
import { Body } from '../../../Typography'
// utils.
import { theme } from '../../../../styleguide'
import { makeSelectLastBudgetByDateAndType } from '../../../../js/budgets/budgetsSelectors'
import { useAppSelector } from '../../../../js/store/hooks'
import { useDimensions } from '../../../../js/hooks'

// helpers.
const baseUnit = theme.fontSize.x16

// partials.
const Wrapper = styled.div({
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: (props) => (props.isMobile ? 'column' : 'row'),
  gap: baseUnit * 2,
  height: '100%',
  width: '100%',
})

const InputWrapper = styled.div({
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  gap: baseUnit,
  height: '100%',
  paddingTop: baseUnit / 2,
  width: (props) => (props.isMobile ? '100%' : '25%'),
})

const StatsWrapper = styled.div({
  alignItems: 'stretch',
  border: `1px solid ${theme.opacity.ash40}`,
  borderRadius: baseUnit / 2,
  display: 'flex',
  flexDirection: 'column',
  height: 'fit-content',
  padding: baseUnit / 2,
  width: '100%',
})

const StyledNumber = styled(Body)({
  alignItems: 'center',
  background: (props) => props.background,
  borderRadius: baseUnit,
  display: 'flex',
  filter: `drop-shadow(${theme.shadows.smaller})`,
  height: baseUnit * 2,
  justifyContent: 'center',
  maxHeight: baseUnit * 1.5,
  minWidth: baseUnit * 1.5,
  overflow: 'hidden',
  padding: baseUnit / 2,
})

const StatItem = styled.div({
  alignItems: 'center',
  borderBottom: (props) => (props.divider ? `1px solid ${theme.opacity.ash40}` : 'none'),
  display: 'flex',
  flexDirection: 'row',
  marginBottom: (props) => (props.divider ? `${baseUnit / 2}px` : 0),
  padding: (props) => `${props.divider ? baseUnit / 2 : 0}px 0`,
})

const Cell = styled.div({
  alignItems: (props) => props.align || 'center',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  padding: `${baseUnit / 4}px ${baseUnit / 4}px`,
  width: (props) => `${props.width}%`,
})

const AuditoriesReportHeader = ({ fromDate, toDate, auditories = [], budget }) => {
  const [fteAmount, setFteAmount] = useState()
  const [selectedMonth, setSelectedMonth] = useState()

  const { isMobile } = useDimensions()

  const buildFteBy100Index = () =>
    fteAmount && auditories.length ? Math.round((auditories.length / fteAmount) * 100) : null

  const getFTEBudgets = useAppSelector(makeSelectLastBudgetByDateAndType('FTE', fromDate, toDate))

  function getMonthFromDates(fromDate) {
    const fromMoment = moment(fromDate)
    setSelectedMonth(fromMoment.format('MMMM'))
  }

  const buildFTEBudgetAvg = getFTEBudgets?.quantity || 0

  useEffect(() => {
    if (fromDate) getMonthFromDates(fromDate)
  }, [fromDate, toDate])

  return (
    <Wrapper isMobile={isMobile}>
      {isMobile ? null : (
        <InputWrapper>
          <Body type="caption" color="grey">
            Mes Seleccionado
          </Body>
          <Input name="month-report" placeholder={selectedMonth} onChange={() => {}} disabled />
        </InputWrapper>
      )}
      <InputWrapper isMobile={isMobile}>
        <Body type="caption" color="grey">
          Ingresar cantidad de FTE:
        </Body>
        <Input
          name="fte-amount"
          placeholder="Funcionarios activos..."
          defaultValue={buildFTEBudgetAvg}
          onChange={(value) => setFteAmount(value)}
        />
      </InputWrapper>
      <StatsWrapper>
        <StatItem divider>
          <Cell width={isMobile ? 34 : 25}>
            <Body size="smaller" align="center" color="grey">
              <strong>Auditorías realizadas</strong>
            </Body>
          </Cell>
          <Cell width={isMobile ? 33 : 25}>
            <Body type="caption" color="grey" align="center">
              {isMobile ? 'Ppto.' : 'Presupuesto'}
            </Body>
          </Cell>
          {isMobile ? null : (
            <Cell width={25}>
              <Body type="caption" color="grey" align="center">
                FTE
              </Body>
            </Cell>
          )}
          <Cell width={isMobile ? 33 : 25}>
            <Body type="caption" color="grey" align="center">
              Cantidad
              <br />
              (÷ FTE ×100)
            </Body>
          </Cell>
        </StatItem>
        <StatItem>
          <Cell width={isMobile ? 34 : 25}>
            <StyledNumber background={theme.opacity.black10} size="smaller">
              {auditories.length}
            </StyledNumber>
          </Cell>
          {isMobile ? null : (
            <Cell width={25}>
              <StyledNumber background={theme.opacity.black10} size="smaller">
                {budget}
              </StyledNumber>
            </Cell>
          )}
          <Cell width={isMobile ? 33 : 25}>
            <StyledNumber background={theme.opacity.black10} size="smaller">
              {buildFTEBudgetAvg}
            </StyledNumber>
          </Cell>
          <Cell width={isMobile ? 33 : 25}>
            <StyledNumber
              background={theme.opacity.black10}
              size="smaller"
              color={!buildFteBy100Index() && 'grey'}
            >
              {buildFteBy100Index() || '∞'}
            </StyledNumber>
          </Cell>
        </StatItem>
      </StatsWrapper>
    </Wrapper>
  )
}

export default AuditoriesReportHeader
