// dependencies.
import styled from 'styled-components'
import { CSVLink } from 'react-csv'
import { writeFile, utils } from 'xlsx'
// components.
import { Button } from '../../Button'
import { Body } from '../../Typography'
// utils.
import { theme } from '../../../styleguide'
import { useDimensions } from '../../../js/hooks'
import dateFormat from '../../../js/dateFormat'

/************************/
/*                      */
/*    Search Heading    */
/*                      */
/************************/

// helpers.
const baseUnit = theme.fontSize.x16

// partials.
const Wrapper = styled.div({
  display: 'flex',
  flexDirection: (props) => (props.column ? 'column' : 'row'),
  gap: baseUnit,
  width: (props) => (props.isMobile ? '100%' : props.column ? '100%' : 'fit-content'),
  alignItems: 'center',
})

const ButtonContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
  width: (props) => (props.column ? '100%' : 'fit-content'),
})

const StyledButton = styled(Button)({
  padding: 0,
  height: baseUnit * 3,
  minWidth: baseUnit * 3,
  width: (props) => (props.isMobile ? '100%' : props.column ? '100%' : 'fit-content'),
})

// main component.
const Export = ({ label, header = [], items = [], dynamicColumns = [], filename = 'reporte' }) => {
  // hooks.
  const { isMobile } = useDimensions()

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //
  // Prepare data for CSV
  const csvHeaders = header.map((h) => h.label)
  const csvData = items.map((item) => {
    const {
      auditItemEpp,
      auditItems,
      auditor,
      comments,
      feedback,
      location,
      locationP,
      number,
      person,
      shift,
      completedDate,
      isContractor,
    } = item

    return [
      number ?? 0,
      dateFormat(completedDate, 'YY'),
      dateFormat(completedDate, 'MM'),
      dateFormat(completedDate, 'WW'),
      dateFormat(completedDate),
      auditor,
      locationP || location || '--',
      locationP ? location : '--',
      person,
      shift,
      auditItemEpp ? auditItemEpp.value : '',
      ...dynamicColumns.map(({ id }) => {
        const auditItem = auditItems?.find(({ ref }) => ref.id === id)
        return auditItem ? auditItem.value : ''
      }),
      feedback === 1 ? '🙂' : '😡',
      isContractor,
      comments,
    ]
  })

  const handleExportXLSX = () => {
    const data = [csvHeaders].concat(csvData)
    const ws = utils.aoa_to_sheet(data)
    const wb = utils.book_new()
    utils.book_append_sheet(wb, ws, 'Tarjetas')
    writeFile(wb, `Reporte-${new Date().toLocaleDateString()}.xlsx`)
  }

  // return content.
  return (
    <Wrapper column={!!label} isMobile={isMobile}>
      {label ? (
        <Body type="caption" color="grey">
          {label}
        </Body>
      ) : (
        <Body type="caption" color="white">
          Exportar:
        </Body>
      )}

      <ButtonContainer column={!!label}>
        {csvHeaders && csvData && (
          <StyledButton
            isMobile={isMobile}
            column={!!label}
            onClick={() => handleExportXLSX()}
            background="success"
          >
            XLSX
          </StyledButton>
        )}

        {csvHeaders && csvData && (
          <CSVLink
            className="link-reset"
            style={{
              color: 'inherit',
              textDecoration: 'none',
              width: isMobile ? '100%' : label ? '100%' : 'fit-content',
            }}
            filename={`${filename}.csv`}
            data={csvData}
            headers={csvHeaders}
          >
            <StyledButton column={!!label} background="success">
              CSV
            </StyledButton>
          </CSVLink>
        )}
      </ButtonContainer>
    </Wrapper>
  )
}

export default Export
