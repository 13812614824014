// depenmdencies.
import { useState } from 'react'
import styled from 'styled-components'
// components.
import { Input } from '../../../Form'
import { Body } from '../../../Typography'
// utils.
import { cardFilterByType } from '../../../../js/cardFilters'
import { theme } from '../../../../styleguide'
import { useDimensions } from '../../../../js/hooks'
import { useAppSelector } from '../../../../js/store/hooks'
import { makeSelectLastBudgetByDateAndType } from '../../../../js/budgets/budgetsSelectors'

// helpers.
const baseUnit = theme.fontSize.x16

// partials.
const Wrapper = styled.div({
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: (props) => (props.isMobile ? 'column' : 'row'),
  gap: baseUnit * 2,
  height: '100%',
  width: '100%',
})

const InputWrapper = styled.div({
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  gap: baseUnit,
  height: '100%',
  paddingTop: baseUnit / 2,
  width: (props) => (props.isMobile ? '100%' : '25%'),
})

const StatsWrapper = styled.div({
  alignItems: 'stretch',
  border: `1px solid ${theme.opacity.ash40}`,
  borderRadius: baseUnit / 2,
  display: 'flex',
  flexDirection: 'column',
  height: 'fit-content',
  padding: baseUnit / 2,
  width: '100%',
})

const StyledType = styled(Body)({
  '&:before': {
    backgroundColor: (props) => props.background,
    borderRadius: baseUnit / 4,
    content: '""',
    display: 'inline-block',
    filter: `drop-shadow(${theme.shadows.smaller})`,
    height: baseUnit / 2,
    marginRight: baseUnit / 4,
    width: baseUnit / 2,
  },
})

const StyledNumber = styled(Body)({
  alignItems: 'center',
  background: (props) => props.background,
  borderRadius: baseUnit,
  display: 'flex',
  filter: `drop-shadow(${theme.shadows.smaller})`,
  height: baseUnit * 2,
  justifyContent: 'center',
  maxHeight: baseUnit * 1.5,
  minWidth: baseUnit * 1.5,
  padding: baseUnit / 2,
  overflow: 'hidden',
})

const StatItem = styled.div({
  alignItems: 'center',
  borderBottom: (props) => (props.divider ? `1px solid ${theme.opacity.ash40}` : 'none'),
  display: 'flex',
  flexDirection: 'row',
  marginBottom: (props) => (props.divider ? `${baseUnit / 2}px` : 0),
  padding: (props) => `${props.divider ? baseUnit / 2 : 0}px 0`,
})

const Cell = styled.div({
  alignItems: (props) => props.align || 'center',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  padding: `${baseUnit / 4}px ${baseUnit / 4}px`,
  width: (props) => `${props.width}%`,
})

const CardsByTypeAndFTE = ({ fromDate, toDate, cards = [] }) => {
  const { isMobile } = useDimensions()
  // const budgets = useAppSelector(selectBudgetsData)
  const selectLastFteBudgetByDate = makeSelectLastBudgetByDateAndType('FTE', fromDate, toDate)
  const lastFteBudget = useAppSelector(selectLastFteBudgetByDate)
  const [fteAmount, setFteAmount] = useState(lastFteBudget?.quantity || 0)

  const tmArr = cardFilterByType(cards, 'TM')
  const toArr = cardFilterByType(cards, 'TO')
  const tqArr = cardFilterByType(cards, 'TQ')
  const tsArr = cardFilterByType(cards, 'TS')

  const tmLength = tmArr.length
  const toLength = toArr.length
  const tqLength = tqArr.length
  const tsLength = tsArr.length

  const buildFteBy100Index = (type) => (fteAmount ? Math.round((type / fteAmount) * 100) : null)
  const buildOtIndex = (arr, status) => arr.filter(({ otStatus }) => otStatus === status).length
  const buildClosedPercentage = (arr) => {
    if (arr.length === 0) return '0%'
    const percentage = (arr.filter(({ status }) => status === 2).length * 100) / arr.length || 0
    return `${percentage.toFixed(1)}%`
  }
  const buildOtifPercentage = (arr) => {
    if (arr.length === 0) return '0%'
    const closedStatusLength = arr.filter(({ status }) => status === 2).length || 0
    const otStatusLength = arr.filter(({ otStatus }) => otStatus === 2).length || 0
    const percentage = (otStatusLength * 100) / closedStatusLength || 0
    return `${percentage.toFixed(1)}%`
  }

  const getFTEBudgets = useAppSelector(makeSelectLastBudgetByDateAndType('FTE', fromDate, toDate))
  const getTMBudgets = useAppSelector(makeSelectLastBudgetByDateAndType('TM', fromDate, toDate))
  const getTOBudgets = useAppSelector(makeSelectLastBudgetByDateAndType('TO', fromDate, toDate))
  const getTQBudgets = useAppSelector(makeSelectLastBudgetByDateAndType('TQ', fromDate, toDate))
  const getTSBudgets = useAppSelector(makeSelectLastBudgetByDateAndType('TS', fromDate, toDate))

  const buildFTEBudgetAvg = getFTEBudgets?.quantity || 0
  const buildTMBudgetAvg = getTMBudgets?.quantity || 0
  const buildTOBudgetAvg = getTOBudgets?.quantity || 0
  const buildTQBudgetAvg = getTQBudgets?.quantity || 0
  const buildTSBudgetAvg = getTSBudgets?.quantity || 0

  return (
    <Wrapper isMobile={isMobile}>
      <InputWrapper isMobile={isMobile}>
        <Body type="caption" color="grey">
          Ingresar cantidad de funcionarios
          <br />
          activos en el mes (FTE):
        </Body>
        <Input
          name="fte-amount"
          placeholder="Funcionarios activos..."
          defaultValue={buildFTEBudgetAvg}
          onChange={(value) => setFteAmount(value)}
        />
      </InputWrapper>

      <StatsWrapper>
        <StatItem divider>
          <Cell width={isMobile ? 20 : 23} align="flex-start">
            <Body type="caption" color="grey">
              Tipo
            </Body>
          </Cell>
          <Cell width={isMobile ? 20 : 11}>
            <Body type="caption" color="grey" align="center">
              Ppto.
            </Body>
          </Cell>
          {isMobile ? null : (
            <Cell width={11}>
              <Body type="caption" color="grey" align="center">
                Cantidad
              </Body>
            </Cell>
          )}
          <Cell width={isMobile ? 20 : 11}>
            <Body type="caption" color="grey" align="center">
              Cantidad
              <br />
              (÷ FTE ×100)
            </Body>
          </Cell>
          {isMobile ? null : (
            <>
              <Cell width={11}>
                <Body type="caption" color="grey" align="center">
                  Sin fecha compromiso
                </Body>
              </Cell>
              <Cell width={11}>
                <Body type="caption" color="grey" align="center">
                  A tiempo
                </Body>
              </Cell>
              <Cell width={11}>
                <Body type="caption" color="grey" align="center">
                  Con atraso
                </Body>
              </Cell>
            </>
          )}
          <Cell width={isMobile ? 20 : 11}>
            <Body type="caption" color="grey" align="center">
              % de cierre
            </Body>
          </Cell>
          <Cell width={isMobile ? 20 : 11}>
            <Body type="caption" color="grey" align="center">
              % OTIF
            </Body>
          </Cell>
        </StatItem>

        <StatItem divider>
          <Cell width={isMobile ? 20 : 23} align="flex-start">
            <Body size="smaller">
              <strong>{isMobile ? 'Tarjetas' : 'Tarjetas levantadas:'}</strong>
            </Body>
          </Cell>
          <Cell width={isMobile ? 20 : 11}>
            <StyledNumber background={theme.opacity.black10} size="smaller">
              {Number(buildTMBudgetAvg) +
                Number(buildTOBudgetAvg) +
                Number(buildTQBudgetAvg) +
                Number(buildTSBudgetAvg)}
            </StyledNumber>
          </Cell>
          {isMobile ? null : (
            <Cell width={11}>
              <StyledNumber background={theme.opacity.black10} size="smaller">
                {cards.length}
              </StyledNumber>
            </Cell>
          )}
          <Cell width={isMobile ? 20 : 11}>
            <StyledNumber
              background={theme.opacity.black10}
              size="smaller"
              color={!buildFteBy100Index(cards.length) && 'grey'}
            >
              {buildFteBy100Index(cards.length) || '∞'}
            </StyledNumber>
          </Cell>
          {isMobile ? null : (
            <>
              <Cell width={11}>
                <StyledNumber background={theme.opacity.black10} size="smaller">
                  {buildOtIndex(cards, 0)}
                </StyledNumber>
              </Cell>
              <Cell width={11}>
                <StyledNumber background={theme.opacity.black10} size="smaller">
                  {buildOtIndex(cards, 2)}
                </StyledNumber>
              </Cell>
              <Cell width={11}>
                <StyledNumber background={theme.opacity.black10} size="smaller">
                  {buildOtIndex(cards, 1)}
                </StyledNumber>
              </Cell>
            </>
          )}
          <Cell width={isMobile ? 20 : 11}>
            <StyledNumber background={theme.opacity.black10} size="smaller">
              {buildClosedPercentage(cards)}
            </StyledNumber>
          </Cell>
          <Cell width={isMobile ? 20 : 11}>
            <StyledNumber background={theme.opacity.black10} size="smaller">
              {buildOtifPercentage(cards)}
            </StyledNumber>
          </Cell>
        </StatItem>

        <StatItem>
          <Cell width={isMobile ? 20 : 23} align="flex-start">
            <StyledType background={theme.cards.ts} type="caption" nowrap>
              {isMobile ? 'TS' : 'Seguridad:'}
            </StyledType>
          </Cell>
          <Cell width={isMobile ? 20 : 11}>
            <StyledNumber background={theme.cards.ts10} size="smaller">
              {buildTSBudgetAvg}
            </StyledNumber>
          </Cell>
          {isMobile ? null : (
            <Cell width={11}>
              <StyledNumber background={theme.cards.ts10} size="smaller">
                {tsLength}
              </StyledNumber>
            </Cell>
          )}
          <Cell width={isMobile ? 20 : 11}>
            <StyledNumber
              background={theme.cards.ts10}
              size="smaller"
              color={!buildFteBy100Index(tsLength) && 'grey'}
            >
              {buildFteBy100Index(tsLength) || '∞'}
            </StyledNumber>
          </Cell>
          {isMobile ? null : (
            <>
              <Cell width={11}>
                <StyledNumber background={theme.cards.ts10} size="smaller">
                  {buildOtIndex(tsArr, 0)}
                </StyledNumber>
              </Cell>
              <Cell width={11}>
                <StyledNumber background={theme.cards.ts10} size="smaller">
                  {buildOtIndex(tsArr, 2)}
                </StyledNumber>
              </Cell>
              <Cell width={11}>
                <StyledNumber background={theme.cards.ts10} size="smaller">
                  {buildOtIndex(tsArr, 1)}
                </StyledNumber>
              </Cell>
            </>
          )}
          <Cell width={isMobile ? 20 : 11}>
            <StyledNumber background={theme.cards.ts10} size="smaller">
              {buildClosedPercentage(tsArr)}
            </StyledNumber>
          </Cell>
          <Cell width={isMobile ? 20 : 11}>
            <StyledNumber background={theme.cards.ts10} size="smaller">
              {buildOtifPercentage(tsArr)}
            </StyledNumber>
          </Cell>
        </StatItem>

        <StatItem>
          <Cell width={isMobile ? 20 : 23} align="flex-start">
            <StyledType background={theme.cards.to} type="caption" nowrap>
              {isMobile ? 'TO' : 'Orden y Limpieza:'}
            </StyledType>
          </Cell>
          <Cell width={isMobile ? 20 : 11}>
            <StyledNumber background={theme.cards.to10} size="smaller">
              {buildTOBudgetAvg}
            </StyledNumber>
          </Cell>
          {isMobile ? null : (
            <Cell width={11}>
              <StyledNumber background={theme.cards.to10} size="smaller">
                {toLength}
              </StyledNumber>
            </Cell>
          )}
          <Cell width={isMobile ? 20 : 11}>
            <StyledNumber
              background={theme.cards.to10}
              size="smaller"
              color={!buildFteBy100Index(toLength) && 'grey'}
            >
              {buildFteBy100Index(toLength) || '∞'}
            </StyledNumber>
          </Cell>
          {isMobile ? null : (
            <>
              <Cell width={11}>
                <StyledNumber background={theme.cards.to10} size="smaller">
                  {buildOtIndex(toArr, 0)}
                </StyledNumber>
              </Cell>
              <Cell width={11}>
                <StyledNumber background={theme.cards.to10} size="smaller">
                  {buildOtIndex(toArr, 2)}
                </StyledNumber>
              </Cell>
              <Cell width={11}>
                <StyledNumber background={theme.cards.to10} size="smaller">
                  {buildOtIndex(toArr, 1)}
                </StyledNumber>
              </Cell>
            </>
          )}
          <Cell width={isMobile ? 20 : 11}>
            <StyledNumber background={theme.cards.to10} size="smaller">
              {buildClosedPercentage(toArr)}
            </StyledNumber>
          </Cell>
          <Cell width={isMobile ? 20 : 11}>
            <StyledNumber background={theme.cards.to10} size="smaller">
              {buildOtifPercentage(toArr)}
            </StyledNumber>
          </Cell>
        </StatItem>

        <StatItem>
          <Cell width={isMobile ? 20 : 23} align="flex-start">
            <StyledType background={theme.cards.tm} type="caption" nowrap>
              {isMobile ? 'TM' : 'Mantenimiento:'}
            </StyledType>
          </Cell>
          <Cell width={isMobile ? 20 : 11}>
            <StyledNumber background={theme.cards.tm10} size="smaller">
              {buildTMBudgetAvg}
            </StyledNumber>
          </Cell>
          {isMobile ? null : (
            <Cell width={11}>
              <StyledNumber background={theme.cards.tm10} size="smaller">
                {tmLength}
              </StyledNumber>
            </Cell>
          )}
          <Cell width={isMobile ? 20 : 11}>
            <StyledNumber
              background={theme.cards.tm10}
              size="smaller"
              color={!buildFteBy100Index(tmLength) && 'grey'}
            >
              {buildFteBy100Index(tmLength) || '∞'}
            </StyledNumber>
          </Cell>
          {isMobile ? null : (
            <>
              <Cell width={11}>
                <StyledNumber background={theme.cards.tm10} size="smaller">
                  {buildOtIndex(tmArr, 0)}
                </StyledNumber>
              </Cell>
              <Cell width={11}>
                <StyledNumber background={theme.cards.tm10} size="smaller">
                  {buildOtIndex(tmArr, 2)}
                </StyledNumber>
              </Cell>
              <Cell width={11}>
                <StyledNumber background={theme.cards.tm10} size="smaller">
                  {buildOtIndex(tmArr, 1)}
                </StyledNumber>
              </Cell>
            </>
          )}
          <Cell width={isMobile ? 20 : 11}>
            <StyledNumber background={theme.cards.tm10} size="smaller">
              {buildClosedPercentage(tmArr)}
            </StyledNumber>
          </Cell>
          <Cell width={isMobile ? 20 : 11}>
            <StyledNumber background={theme.cards.tm10} size="smaller">
              {buildOtifPercentage(tmArr)}
            </StyledNumber>
          </Cell>
        </StatItem>

        <StatItem>
          <Cell width={isMobile ? 20 : 23} align="flex-start">
            <StyledType background={theme.cards.tq} type="caption" nowrap>
              {isMobile ? 'TQ' : 'Calidad:'}
            </StyledType>
          </Cell>
          <Cell width={isMobile ? 20 : 11}>
            <StyledNumber background={theme.cards.tq10} size="smaller">
              {buildTQBudgetAvg}
            </StyledNumber>
          </Cell>
          {isMobile ? null : (
            <Cell width={11}>
              <StyledNumber background={theme.cards.tq10} size="smaller">
                {tqLength}
              </StyledNumber>
            </Cell>
          )}
          <Cell width={isMobile ? 20 : 11}>
            <StyledNumber
              background={theme.cards.tq10}
              size="smaller"
              color={!buildFteBy100Index(tqLength) && 'grey'}
            >
              {buildFteBy100Index(tqLength) || '∞'}
            </StyledNumber>
          </Cell>
          {isMobile ? null : (
            <>
              <Cell width={11}>
                <StyledNumber background={theme.cards.tq10} size="smaller">
                  {buildOtIndex(tqArr, 0)}
                </StyledNumber>
              </Cell>
              <Cell width={11}>
                <StyledNumber background={theme.cards.tq10} size="smaller">
                  {buildOtIndex(tqArr, 2)}
                </StyledNumber>
              </Cell>
              <Cell width={11}>
                <StyledNumber background={theme.cards.tq10} size="smaller">
                  {buildOtIndex(tqArr, 1)}
                </StyledNumber>
              </Cell>
            </>
          )}
          <Cell width={isMobile ? 20 : 11}>
            <StyledNumber background={theme.cards.tq10} size="smaller">
              {buildClosedPercentage(tqArr)}
            </StyledNumber>
          </Cell>
          <Cell width={isMobile ? 20 : 11}>
            <StyledNumber background={theme.cards.tq10} size="smaller">
              {buildOtifPercentage(tqArr)}
            </StyledNumber>
          </Cell>
        </StatItem>
      </StatsWrapper>
    </Wrapper>
  )
}

export default CardsByTypeAndFTE
