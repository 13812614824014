// dependencies.
import styled from 'styled-components'
// components.
import { Body } from '../../../Typography'
// utils.
import { useDimensions } from '../../../../js/hooks'
import { theme } from '../../../../styleguide'

// helpers
const baseUnit = theme.fontSize.x16

export const COLUMN_LAYOUT_START = [
  { label: 'icon', columns: 5 },
  { label: 'Uso de EPP', columns: 1, isDynamic: true },
]
export const COLUMN_LAYOUT_END = [
  { label: 'Sumatoria <br />de <br />puntos', columns: 2, isSpecial: true },
  { label: '% de lo Observado', columns: 1, isDynamic: true },
]
const totalColumnsStart = COLUMN_LAYOUT_START.reduce((sum, { columns }) => sum + columns, 0)
const totalColumnsEnd = COLUMN_LAYOUT_END.reduce((sum, { columns }) => sum + columns, 0)
export const minCellsWidth = totalColumnsStart + totalColumnsEnd

// partials.
const Wrapper = styled.li({
  backgroundColor: theme.colors.white,
  borderBottom: (props) => (props.isLast ? 'none' : `1px solid ${theme.opacity.ash80}`),
  display: 'flex',
  flexDirection: 'row',
  height: baseUnit * 2,
  transition: 'background 200ms ease',
  width: '100%',
  '&:focus': {
    backgroundColor: theme.opacity.primary20,
    outline: 'none',
  },
})

const Cell = styled.div({
  alignItems: 'center',
  backgroundColor: (props) =>
    props.isGreen ? theme.colors.success : props.isRed ? theme.colors.alert : 'transparent',
  borderRight: `1px solid ${theme.opacity.ash80}`,
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 0,
  height: '100%',
  justifyContent: 'center',
  padding: `${baseUnit / 8}px ${baseUnit / 4}px`,
  width: (props) => `${props.width}%`,
})

const StyledBody = styled(Body)({
  alignItems: 'center',
  display: 'flex',
  gap: baseUnit / 2,
})

// main component.
const AuditReportsDesktopItem = ({ label, item, dynamicColumns, isGreen, isRed, isLast }) => {
  const { width } = useDimensions()

  // only show if there's items.
  if (!item) return null

  const { epp, items, count, percent } = item

  const cellWidth = 100 / (minCellsWidth + dynamicColumns.length)

  // return content.
  return (
    <Wrapper isLast={isLast}>
      <Cell isGreen={isGreen} isRed={isRed} width={cellWidth * COLUMN_LAYOUT_START[0].columns}>
        <StyledBody
          size={width <= 1320 ? 'minimal' : 'smallest'}
          weight="bold"
          color={isGreen || isRed ? 'white' : undefined}
        >
          {label}
        </StyledBody>
      </Cell>

      <Cell width={cellWidth * COLUMN_LAYOUT_START[1].columns}>
        <StyledBody size={width <= 1320 && epp?.length >= 3 ? 'minimal' : 'smallest'} weight="bold">
          {epp}
        </StyledBody>
      </Cell>
      {dynamicColumns.map(({ id }, i) => {
        const hasValue = items?.find(({ ref }) => ref.id === id) ?? {}
        return (
          <Cell key={i} width={cellWidth}>
            <StyledBody
              size={width <= 1320 && hasValue?.value?.length >= 3 ? 'minimal' : 'smallest'}
              weight="bold"
            >
              {hasValue.value}
            </StyledBody>
          </Cell>
        )
      })}

      <Cell width={cellWidth * COLUMN_LAYOUT_END[0].columns}>
        <StyledBody size="smallest" weight="bold">
          {count}
        </StyledBody>
      </Cell>
      <Cell isGreen={isGreen} isRed={isRed} width={cellWidth * COLUMN_LAYOUT_END[1].columns}>
        <StyledBody size="smallest" weight="bold" color={isGreen || isRed ? 'white' : undefined}>
          {percent}
        </StyledBody>
      </Cell>
    </Wrapper>
  )
}

export default AuditReportsDesktopItem
