import { createSelector } from 'reselect'

export const selectAuditData = (state) => state.auditData.data

export const selectFilteredEpps = () =>
  createSelector([selectAuditData], (auditData) => {
    const filteredEpps = auditData.filter((dataObj) => dataObj.type === 'EPP')
    return filteredEpps.length ? filteredEpps : []
  })

export const selectFilteredItems = () =>
  createSelector([selectAuditData], (auditData) => {
    const filteredItems = auditData
      .filter((dataObj) => dataObj.type === 'CND' || dataObj.type === 'OPS')
      .sort((a, b) => a.order - b.order)
    return filteredItems.length ? filteredItems : []
  })

export const selectFilteredPersons = () =>
  createSelector([selectAuditData], (auditData) => {
    const filteredItems = auditData.filter(
      (dataObj) => dataObj.type === 'INT' || dataObj.type === 'EXT'
    )
    return filteredItems.length ? filteredItems : []
  })

export const selectFilteredItemsConduct = () =>
  createSelector([selectAuditData], (auditData) => {
    const filteredItems = auditData
      .filter((dataObj) => dataObj.type === 'CND')
      .sort((a, b) => a.order - b.order)
    return filteredItems.length ? filteredItems : []
  })

export const selectFilteredItemsOperation = () =>
  createSelector([selectAuditData], (auditData) => {
    const filteredItems = auditData
      .filter((dataObj) => dataObj.type === 'OPS')
      .sort((a, b) => a.order - b.order)
    return filteredItems.length ? filteredItems : []
  })

export const selectEppsForSelect = createSelector([selectFilteredEpps()], (auditData) => {
  return auditData.reduce(function (filtered, dataObj) {
    const epp = {
      label: dataObj.name,
      name: dataObj.ref.id,
    }
    filtered.push(epp)
    return filtered
  }, [])
})

export const selectPersonsForSelect = createSelector([selectFilteredPersons()], (auditData) => {
  return auditData.reduce(function (filtered, dataObj) {
    const persons = {
      label: dataObj.name,
      name: dataObj.ref.id,
    }
    filtered.push(persons)
    return filtered
  }, [])
})

// Select the status of loading the anomalies.
export const selectAuditDataStatus = (state) => state.auditData.status
