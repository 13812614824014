// dependencies.
import styled from 'styled-components'
// components.
import { Body, Label } from '../../../Typography'
import { Icon } from '../../../Icon'
// utils.
import { theme } from '../../../../styleguide'

// helpers
const baseUnit = theme.fontSize.x16

export const COLUMN_LAYOUT = [
  { label: 'Nombre', columns: 5 },
  { label: 'Tipo', columns: 2 },
  { label: 'Acciones', columns: 3 },
]
export const cellWidth = 10 // 10% (100% / 10 columns)

const typesMap = {
  EPP: 'EPP',
  CND: 'Conducta',
  OPS: 'Operación',
  INT: 'CCU',
  EXT: 'Externo',
}

// partials.

const Wrapper = styled.li({
  backgroundColor: theme.colors.white,
  border: `1px solid ${theme.opacity.ash30}`,
  borderRadius: baseUnit / 2,
  display: 'flex',
  filter: `drop-shadow(${theme.shadows.smaller})`,
  flexDirection: 'column',
  gap: baseUnit / 2,
  minHeight: baseUnit * 4,
  padding: baseUnit / 2,
  width: '100%',
})

const Header = styled.div({
  alignItems: 'flex-start',
  borderBottom: `1px solid ${theme.opacity.ash30}`,
  display: 'flex',
  flexDirection: 'column',
  gap: baseUnit / 4,
  justifyContent: 'space-between',
  paddingBottom: baseUnit / 2 - 1,
  width: '100%',
})

const Footer = styled.div({
  alignItems: 'center',
  border: `1px solid ${theme.opacity.ash50}`,
  borderRadius: baseUnit / 2,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  margin: 0,
  marginTop: baseUnit / 4,
  padding: '2px',
  width: '100%',
})

const StyledBody = styled(Body)({
  alignItems: 'center',
  display: 'flex',
  gap: baseUnit / 2,
})

const StyledEdit = styled(Label)({
  background: (props) => (props.disabled ? theme.colors.smoke : theme.colors.warning),
  border: (props) =>
    props.disabled ? `1px solid ${theme.colors.white}` : `1px solid ${theme.colors.warning}`,
  borderRadius: baseUnit / 2 - 2,
  display: 'flex',
  fontSize: theme.fontSize.x14,
  gap: 4,
  padding: '3px 6px 3px 8px',
  width: 'fit-content',
})

// main component.
const AnomaliesMobileItem = ({ item, isEven, onEdit }) => {
  // only show if there's items.
  if (!item) return null

  const { name, description, type } = item

  const enableEdit = Boolean(onEdit)

  // return content.
  return (
    <Wrapper isEven={isEven} tabIndex={0}>
      <Header>
        <StyledBody
          size={description ? 'smallest' : 'smaller'}
          {...(description ? { weight: 'bold' } : {})}
        >
          {name}
        </StyledBody>
        {description ? <StyledBody size="minimal">{description}</StyledBody> : null}
      </Header>
      <Footer>
        <Body size="smaller" nowrap>
          <strong>{typesMap[type]}</strong>
        </Body>
        <StyledEdit color="white" disabled={!enableEdit} onClick={enableEdit && onEdit}>
          Editar
          <Icon name="edit" size={16} color={!enableEdit ? 'ash' : 'white'} />
        </StyledEdit>
      </Footer>
    </Wrapper>
  )
}

export default AnomaliesMobileItem
