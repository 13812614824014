import { createSelector } from 'reselect'
import { searchBudgetsByType } from '../../components/screens/Budgets/SearchBudgetsByType'
import moment from 'moment'
import { sortingBudgets } from '../budgetSorting'

export const selectBudgetsData = (state) => state.budgets.data
export const selectSearchTerm = (state) => state.budgets.filters.searchTerm
export const selectSort = (state) => state.budgets.filters.sort

export const makeSelectLastBudgetByDateAndType = (type, fromDate, toDate) =>
  createSelector([selectBudgetsData], (budgetsSet) => {
    if (!budgetsSet || budgetsSet.length === 0) return null

    const budgets = budgetsSet.filter((budget) => budget.type === type)

    if (budgets.length === 0) return null

    const from = fromDate ? moment(new Date(fromDate)) : null
    const to = toDate ? moment(new Date(toDate)) : null

    // Filter budgets in the range of dates
    const budgetsInRange = budgets.filter((budget) => {
      const budgetDate = moment(new Date(budget.startDate))
      return from && to
        ? budgetDate.isBetween(from, to, null, '[]') // Inclusive range
        : true
    })

    if (budgetsInRange.length > 0) {
      return budgetsInRange[0]
    }

    // If there are no budgets in the range, get the last budget before the range
    const budgetsBeforeRange = budgets.filter((budget) => {
      const budgetDate = moment(new Date(budget.startDate))
      return from ? budgetDate.isBefore(from) : true
    })

    if (budgetsBeforeRange.length > 0) {
      return budgetsBeforeRange[0]
    }

    // If there are no budgets before the range, get budgets after the range
    const budgetAfterRange = [...budgets]
      .reverse() // Invert the array to get the first budget after range
      .find((budget) => {
        const budgetDate = moment(new Date(budget.startDate))
        return to ? budgetDate.isAfter(to) : true
      })
    if (budgetAfterRange) return budgetAfterRange

    return null
  })

export const makeSelectBudgetsByDate = (fromDate, toDate) =>
  createSelector([selectBudgetsData], (budgetsSet) => {
    if (!fromDate || !toDate)
      return {
        budgetsSet,
      }
    return budgetsSet.filter((budgetObj) =>
      moment(new Date(budgetObj.startDate)).isBetween(
        moment(new Date(fromDate)),
        moment(new Date(toDate))
      )
    )
  })

export const selectFilteredBudgets = () =>
  createSelector([selectBudgetsData, selectSearchTerm, selectSort], (budgets, searchTerm, sort) => {
    // Aplica los filtros uno por uno
    let filteredBudgets = budgets
    if (searchTerm) {
      filteredBudgets = searchBudgetsByType(filteredBudgets, searchTerm)
    }
    if (sort) {
      filteredBudgets = sortingBudgets(filteredBudgets, sort.direction, sort.key)
    }
    return filteredBudgets
  })

// Select the status of loading the budgets.
export const selectBudgetsStatus = (state) => state.budgets.status
