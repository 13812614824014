import { createSelector } from 'reselect'
import { searchAuditsByAuditor } from '../../components/screens/AuditAssignments/SearchAuditsByAuditor'
import { selectUsersData } from '../users/usersSelectors'
import { getNameFull } from '../usernameUtils'
import {
  auditFilterByDates,
  auditsFilterByAuditor,
  auditsFilterByIsContractor,
  auditsFilterByLocation,
  auditsFilterByPerson,
  processAuditsData,
} from '../auditsUtils'
import { sortingAudit } from '../auditSorting'
import moment from 'moment'
import { selectPersonsForSelect } from '../auditData/auditDataSelectors'

const POSITIVE_AUDIT = 1
const NEGATIVE_AUDIT = 2

export const selectAuditsData = (state) => state.audits.data
export const selectSearchTerm = (state) => state.audits.filters.searchTerm
export const selectAuditsSort = (state) => state.audits.filters.sort

export const selectAuditorUsersForSelect = createSelector([selectUsersData], (users) => {
  const activeUsers = users.filter((userObj) => userObj.isActive && !userObj.isAuditor)
  // set in the select only the active anomalies
  return activeUsers.reduce(function (filtered, userObj) {
    const user = {
      label: getNameFull(userObj.firstname, userObj.lastname),
      name: userObj.uid,
    }
    filtered.push(user)
    return filtered
  }, [])
})

export const selectAuditorsForSelect = createSelector([selectUsersData], (users) => {
  const activeUsers = users.filter((userObj) => userObj.isActive && userObj.isAuditor)
  // set in the select only the active anomalies
  return activeUsers.reduce(function (filtered, userObj) {
    const user = {
      label: getNameFull(userObj.firstname, userObj.lastname),
      name: userObj.uid,
    }
    filtered.push(user)
    return filtered
  }, [])
})

export const selectUserAuditAssignments = (user, locations) =>
  createSelector([selectAuditsData], (audits) => {
    if (!user || !audits || !Array.isArray(audits)) return []

    return audits
      .filter((auditObj) => user.uid === auditObj.auditorRef.id)
      .map((auditObj) => {
        const location = locations.find((loc) => loc.id === auditObj.locationRef.id) || {}
        return {
          id: auditObj.id,
          auditor: getNameFull(user.firstname, user.lastname),
          location: location.name || '',
          status: auditObj.status || 0,
          deadline: auditObj.deadline || null,
          feedback: auditObj.feedback || null,
        }
      })
  })

export const selectAuditorsAssignments = (auditItems, users, locations, persons, epps) =>
  createSelector([selectAuditsData], (audits) => {
    if (!audits || !Array.isArray(audits)) return []
    return processAuditsData(users, locations, audits, auditItems, persons, epps)
  })

export const selectFilteredAudits = (auditItems, users, locations, persons, epps, user) =>
  createSelector(
    [
      selectAuditorsAssignments(auditItems, users, locations, persons, epps),
      selectSearchTerm,
      selectLocationFilter,
      selectFromDateFilter,
      selectToDateFilter,
      selectAuditorFilter,
      selectPersonFilter,
      selectIsContractorFilter,
      selectAuditsSort,
    ],
    (
      auditsWithDetails,
      searchTerm,
      locationId,
      fromDate,
      toDate,
      auditorId,
      personId,
      isContractor,
      sort
    ) => {
      let filteredAudits = auditsWithDetails

      if (searchTerm) {
        filteredAudits = searchAuditsByAuditor(filteredAudits, searchTerm)
      }
      if (locationId) {
        filteredAudits = auditsFilterByLocation(filteredAudits, locationId)
      }
      if (fromDate && toDate) {
        filteredAudits = auditFilterByDates(filteredAudits, fromDate, toDate)
      }
      if (auditorId) {
        filteredAudits = auditsFilterByAuditor(filteredAudits, auditorId)
      }
      if (personId) {
        filteredAudits = auditsFilterByPerson(filteredAudits, personId)
      }
      if (isContractor) {
        filteredAudits = auditsFilterByIsContractor(filteredAudits, isContractor)
      }
      if (sort) {
        filteredAudits = sortingAudit(filteredAudits, users, locations, sort.direction, sort.key)
      }
      if (user) {
        filteredAudits = filteredAudits.filter((audit) => audit.auditorRef.id === user.uid)
      }

      return {
        all: filteredAudits,
        pending: filteredAudits.filter((audit) => audit.status === 0),
        done: filteredAudits.filter((audit) => audit.status === 1),
      }
    }
  )

export const makeSelectAuditsByStatus = (auditItems, users, locations, user, persons) =>
  createSelector([selectAuditsData, (state) => state.audits.filters], (auditsSet, filters) => {
    // filter audits by user
    if (user) {
      auditsSet = auditsSet.filter((audit) => audit.auditorRef.id === user.uid)
    }
    const allAudits = processAuditsData(users, locations, auditsSet, auditItems, persons)
    return {
      pending: allAudits.filter((audit) => audit.status === 0),
      done: allAudits.filter((audit) => audit.status === 1),
    }
  })

export const selectAuditsStatus = (state) => state.audits.status
export const selectLocationFilter = (state) => state.audits.filters.locationId
export const selectFromDateFilter = (state) => state.audits.filters.fromDate
export const selectToDateFilter = (state) => state.audits.filters.toDate
export const selectAuditorFilter = (state) => state.audits.filters.auditorId
export const selectPersonFilter = (state) => state.audits.filters.personId
export const selectIsContractorFilter = (state) => state.audits.filters.isContractor

export const selectAuditFilters = (state) => state.audits.filters

export const selectmonthlyNegativeAuditsByPersonFilters = (state) => {
  return state.audits.filters.monthlyNegativeAuditsByPerson
}
export const selectActiveFilters = (users, locations) =>
  createSelector(
    [
      selectSearchTerm,
      selectLocationFilter,
      selectFromDateFilter,
      selectToDateFilter,
      selectAuditorFilter,
      selectPersonFilter,
      selectPersonsForSelect,
      selectIsContractorFilter,
    ],
    (term, locationId, fromDate, toDate, auditorId, personId, persons, isContractor) => {
      const activeFilters = []

      if (term) {
        activeFilters.push({
          label: `${term}`,
          key: 'searchTerm',
        })
      }

      if (locationId) {
        const location = locations.filter((loc) => loc.ref.id === locationId)[0]
        if (location) {
          activeFilters.push({
            label: `Ubicación: ${location.name}`,
            key: 'locationId',
          })
        }
      }

      if (fromDate && toDate) {
        activeFilters.push({
          label: `Fecha: ${fromDate} - ${toDate}`,
          key: 'fromDate',
          value: {
            fromDate,
            toDate,
          },
        })
      }

      if (auditorId) {
        const auditor = users.filter((d) => d.uid === auditorId)[0]
        if (auditor) {
          activeFilters.push({
            label: `Observador: ${getNameFull(auditor.firstname, auditor.lastname)}`,
            key: 'auditorId',
          })
        }
      }

      if (personId) {
        const person = persons.filter(({ name }) => name === personId)[0]
        console.log(person)
        if (person) {
          activeFilters.push({
            label: `Observado: ${person.label}`,
            key: 'personId',
          })
        }
      }
      if (isContractor === 'ext' || isContractor === 'int') {
        activeFilters.push({
          label: `${isContractor === 'ext' ? 'Contratistas' : isContractor === 'int' ? 'Interno CCU' : null}`,
          value: isContractor,
          key: 'isContractor',
        })
      }

      return activeFilters
    }
  )

export const selectFilteredAuditorBudget = createSelector([selectUsersData], (users) => {
  const activeUsers = users.filter((userObj) => userObj.isActive && userObj.isAuditor)
  return activeUsers.reduce(function (totalBudget, userObj) {
    return parseInt(totalBudget) + (parseInt(userObj.stopAuditBudget) ?? 0)
  }, 0)
})

function toPercent(number) {
  return isNaN(number) ? '0%' : number.toFixed(0) + '%'
}

function getItemPercentage(arr1, arr2) {
  return arr1.map((item) => {
    const corr = arr2.find((i) => i.ref.id === item.ref.id) || { value: 0 }
    return {
      ...item,
      value: isNaN((item.value * 100) / (item.value + corr.value))
        ? '0%'
        : ((item.value * 100) / (item.value + corr.value)).toFixed(0) + '%',
    }
  })
}

function calculatStatisticsByType(audits, type) {
  const itemEpp = audits.reduce((count, audit) => {
    return count + (audit.auditItemEpp.value === type ? 1 : 0)
  }, 0)
  const items = audits.reduce((acc, audit) => {
    audit.auditItems.forEach(({ ref, value }) => {
      const existingItem = acc.find((i) => i.ref.id === ref.id)
      if (existingItem) {
        // Si el value es 2, aumentar el sum
        if (value === 2) existingItem.sum += 1

        // Si el value coincide con el tipo, aumentar el value
        if (value === type) existingItem.value += 1
      } else {
        // Si el item no existe, agregarlo con su valor inicial
        acc.push({
          ref: { id: ref.id },
          value: value === type ? 1 : 0,
          sum: value === 2 ? 1 : 0, // Iniciar sum si el value es 2
        })
      }
    })
    return acc
  }, [])

  const count = items.reduce((acc, item) => acc + item.value, 0) + itemEpp
  return { epp: itemEpp, items: items, count: count }
}

export const selectAuditStatistics = (auditItems, users, locations, persons, epps) =>
  createSelector(
    [
      selectFilteredAudits(auditItems, users, locations, persons, epps),
      selectActiveFilters(users, locations),
    ],
    (allYearAudits, activeFilters) => {
      const doneAudits = allYearAudits.done
      const dateFilter = activeFilters.find((filter) => filter.key === 'fromDate')
      let fromDate = dateFilter ? dateFilter.value.fromDate : null
      let toDate = dateFilter ? dateFilter.value.toDate : null

      const getFilteredAudits = (audits, period) => {
        if (period === 'm') {
          if (fromDate && toDate) {
            fromDate = moment(fromDate).startOf('month').toDate()
            toDate = moment(toDate).endOf('month').toDate()
          }
        } else if (period === 'y') {
          if (fromDate && toDate) {
            fromDate = fromDate ? moment(fromDate).startOf('year').toDate() : null
            toDate = toDate ? moment(toDate).endOf('year').toDate() : null
          }
        }
        return auditFilterByDates(audits, fromDate, toDate)
      }

      const monthlyAudits = getFilteredAudits(doneAudits, 'm')
      const yearlyAudits = getFilteredAudits(doneAudits, 'y')

      const calculateStatistics = (audits) => {
        const {
          epp: itemEpp1,
          items: items1,
          count: count1,
        } = calculatStatisticsByType(audits, POSITIVE_AUDIT)
        const {
          epp: itemEpp2,
          items: items2,
          count: count2,
        } = calculatStatisticsByType(audits, NEGATIVE_AUDIT)

        return [
          {
            epp: itemEpp1,
            items: items1,
            count: count1,
            percent: toPercent((count1 * 100) / (count1 + count2)),
          },
          {
            epp: itemEpp2,
            items: items2,
            count: count2,
            percent: toPercent((count2 * 100) / (count1 + count2)),
          },
          {
            epp: toPercent((itemEpp1 * 100) / (itemEpp1 + itemEpp2)),
            items: getItemPercentage(items1, items2),
          },
          {
            epp: toPercent((itemEpp2 * 100) / (itemEpp1 + itemEpp2)),
            items: getItemPercentage(items2, items1),
          },
        ]
      }

      return {
        monthly: calculateStatistics(monthlyAudits),
        yearly: calculateStatistics(yearlyAudits),
        monthlyNegative: calculatStatisticsByType(monthlyAudits, NEGATIVE_AUDIT),
        yearlyNegative: calculatStatisticsByType(yearlyAudits, NEGATIVE_AUDIT),
      }
    }
  )

export const selectDoneAuditsWithEppCount = (auditItems, users, locations, persons, epps, user) =>
  createSelector(
    [
      selectFilteredAudits(auditItems, users, locations, persons, epps, user),
      selectActiveFilters(users, locations),
    ],
    (filteredAudits, activeFilters) => {
      const doneAudits = filteredAudits.done
      const dateFilter = activeFilters.find((filter) => filter.key === 'fromDate')
      let fromDate = dateFilter ? dateFilter.value.fromDate : null
      let toDate = dateFilter ? dateFilter.value.toDate : null

      if (fromDate && toDate) {
        fromDate = moment(fromDate).startOf('month').toDate()
        toDate = moment(toDate).endOf('month').toDate()
      }

      const filteredDoneAudits = auditFilterByDates(doneAudits, fromDate, toDate)

      const eppCount = filteredDoneAudits.reduce((acc, audit) => {
        const epp = audit.auditItemEpp

        // Validate if exists and is negative
        if (epp && epp.value === NEGATIVE_AUDIT) {
          const eppRefId = epp.ref?.id
          if (!acc[eppRefId]) acc[eppRefId] = { ref: { id: eppRefId }, count: 0 }
          acc[eppRefId].count += 1
        }

        return acc
      }, {})
      return {
        total: Object.values(eppCount).reduce((total, epp) => total + epp.count, 0),
        items: Object.values(eppCount),
      }
    }
  )

export const selectAuditorStatistics = (auditItems, users, locations, persons, epps) =>
  createSelector(
    [
      selectFilteredAudits(auditItems, users, locations, persons, epps, null),
      selectActiveFilters(users, locations),
      selectUsersData,
    ],
    (filteredAudits, activeFilters, users) => {
      const auditors = users.filter((userObj) => userObj.isActive && userObj.isAuditor)

      const dateFilter = activeFilters.find((filter) => filter.key === 'fromDate')
      let fromDate = dateFilter ? dateFilter.value.fromDate : null
      let toDate = dateFilter ? dateFilter.value.toDate : null

      if (fromDate && toDate) {
        fromDate = moment(fromDate).startOf('month').toDate()
        toDate = moment(toDate).endOf('month').toDate()
      }

      return auditors.map((auditor) => {
        const userAudits = filteredAudits.all.filter((audit) => audit.auditorRef.id === auditor.uid)
        const auditsInRange = auditFilterByDates(userAudits, fromDate, toDate)
        const pendingAudits = auditsInRange.filter((audit) => audit.status === 0)
        const doneAudits = auditsInRange.filter((audit) => audit.status === 1)
        const onTimeAudits = doneAudits.filter((audit) =>
          moment(audit.deadline.seconds * 1000).isAfter(audit.updatedDate.seconds * 1000)
        )

        return {
          id: auditor.uid,
          name: getNameFull(auditor.firstname, auditor.lastname),
          budget: parseInt(auditor?.stopAuditBudget) || 0,
          total: userAudits.length,
          done: doneAudits.length,
          pending: pendingAudits.length,
          onTime: onTimeAudits.length,
        }
      })
    }
  )

export const selectMonthlyNegativeAuditsByPerson = (auditItems, users, locations, persons, epps) =>
  createSelector(
    [
      selectFilteredAudits(auditItems, users, locations, persons, epps),
      selectActiveFilters(users, locations),
      selectmonthlyNegativeAuditsByPersonFilters,
    ],
    (yearlyDoneAudits, activeFilters, monthlyNegativeAuditsByPersonFilters) => {
      const doneAudits = yearlyDoneAudits.done
      const dateFilter = activeFilters.find((filter) => filter.key === 'fromDate')

      const personId = monthlyNegativeAuditsByPersonFilters.personId
      const itemId = monthlyNegativeAuditsByPersonFilters.itemId
      const eppId = monthlyNegativeAuditsByPersonFilters.eppId

      let fromDate = dateFilter ? dateFilter.value.fromDate : null
      let toDate = dateFilter ? dateFilter.value.toDate : null

      if (fromDate && toDate) {
        fromDate = moment(fromDate).startOf('month').toDate()
        toDate = moment(toDate).endOf('month').toDate()
      }

      const filteredAudits = auditFilterByDates(doneAudits, fromDate, toDate)

      const negativeAuditItemsByPerson = persons.reduce((acc, person) => {
        // filter by personId if it's passed
        if (personId && person.id !== personId && personId !== 'all') {
          return acc
        }

        let personAuditItems = filteredAudits

        personAuditItems = personAuditItems.filter((audit) => audit.personRef?.id === person.id)

        let personAuditEpps = personAuditItems.filter(
          (audit) => audit.auditItemEpp?.value === NEGATIVE_AUDIT && audit.auditItemEpp?.ref
        )

        personAuditItems = personAuditItems
          .flatMap((audit) => audit.auditItems)
          .filter((item) => item.value === NEGATIVE_AUDIT)

        if (itemId === 'epp' && eppId !== 'epp') {
          // return only the epps
          personAuditItems = []
          // filter by eppId if it's passed
          if (eppId !== 'epp') {
            personAuditEpps = personAuditEpps.filter(
              (audit) => audit.auditItemEpp?.ref?.id === eppId
            )
          }
        } else {
          if (itemId !== 'epp') {
            personAuditEpps = []
          }
          // filter by itemId if it's passed
          if (itemId && itemId !== 'all') {
            personAuditItems = personAuditItems.filter((item) => item.ref.id === itemId)
          }
        }

        const personAuditTotalItems = [...personAuditItems, ...personAuditEpps]
        acc.push({
          personId: person.id,
          personName: person.name,
          items: personAuditTotalItems,
          count: personAuditTotalItems.length,
        })

        return acc
      }, [])

      return negativeAuditItemsByPerson
    }
  )
