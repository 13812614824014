// dependencies.
import styled from 'styled-components'
// utils.
import styleguideHasKey from '../../js/styleguideHasKey'
import { theme } from '../../styleguide'

/************************/
/*                      */
/*    Icon Component    */
/*                      */
/************************/

// helpers.
const UNIT_SIZE = 32

// partials.
const Img = ({ className, color, type }) => (
  <svg className={className} fill={color} viewBox="0 0 24 24">
    {type !== 1 ? (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33141 23.502C8.78386 24.1245 10.3401 24.4357 12 24.4357C13.6599 24.4357 15.2161 24.1245 16.6686 23.502C18.121 22.8795 19.389 22.0265 20.4726 20.9429C21.5792 19.8363 22.4438 18.5568 23.0663 17.1043C23.6888 15.6519 24 14.0957 24 12.4357C24 10.7758 23.6888 9.21959 23.0663 7.76714C22.4438 6.3147 21.5792 5.04668 20.4726 3.96311C19.389 2.85648 18.121 1.99193 16.6686 1.36945C15.2161 0.746971 13.6599 0.435732 12 0.435732C10.3401 0.435732 8.78386 0.746971 7.33141 1.36945C5.87896 1.99193 4.59942 2.85648 3.4928 3.96311C2.40922 5.06974 1.5562 6.34928 0.933717 7.80173C0.311239 9.23112 0 10.7758 0 12.4357C0 14.0957 0.311239 15.6519 0.933717 17.1043C1.5562 18.5568 2.42075 19.8363 3.52738 20.9429C4.63401 22.0265 5.90202 22.8795 7.33141 23.502ZM8.54179 11.0524C8.26513 11.3522 7.89625 11.502 7.43516 11.502C6.99712 11.502 6.62824 11.3522 6.32853 11.0524C6.02882 10.7527 5.87896 10.3839 5.87896 9.94582C5.87896 9.50778 6.02882 9.1389 6.32853 8.83919C6.62824 8.53948 6.99712 8.38962 7.43516 8.38962C7.89625 8.38962 8.26513 8.53948 8.54179 8.83919C8.8415 9.1389 8.99135 9.50778 8.99135 9.94582C8.99135 10.3839 8.8415 10.7527 8.54179 11.0524ZM17.6715 11.087C17.3718 11.3637 17.0029 11.502 16.5648 11.502C16.1268 11.502 15.7579 11.3522 15.4582 11.0524C15.1585 10.7527 15.0086 10.3839 15.0086 9.94582C15.0086 9.50778 15.1585 9.1389 15.4582 8.83919C15.7579 8.53948 16.1268 8.38962 16.5648 8.38962C17.0029 8.38962 17.3718 8.53948 17.6715 8.83919C17.9712 9.1389 18.121 9.50778 18.121 9.94582C18.121 10.4069 17.9712 10.7873 17.6715 11.087ZM6.44 18.3203C7.73107 15.8534 9.58698 14.62 12.0077 14.62C14.4054 14.62 16.2498 15.8534 17.5409 18.3203L16.3651 18.9774C15.8809 18.0782 15.2815 17.3981 14.5668 16.937C13.829 16.499 12.976 16.2799 12.0077 16.2799C11.0625 16.2799 10.2325 16.499 9.51781 16.937C8.78006 17.3981 8.15758 18.0782 7.65037 18.9774L6.44 18.3203Z"
      />
    ) : (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C10.3401 24 8.78386 23.6888 7.33141 23.0663C5.90202 22.4438 4.63401 21.5908 3.52738 20.5072C2.42075 19.4006 1.5562 18.121 0.933717 16.6686C0.311239 15.2161 0 13.6599 0 12C0 10.3401 0.311239 8.79539 0.933717 7.36599C1.5562 5.91355 2.40922 4.63401 3.4928 3.52738C4.59942 2.42075 5.87896 1.5562 7.33141 0.933718C8.78386 0.311239 10.3401 0 12 0C13.6599 0 15.2161 0.311239 16.6686 0.933718C18.121 1.5562 19.389 2.42075 20.4726 3.52738C21.5793 4.61095 22.4438 5.87896 23.0663 7.33141C23.6888 8.78386 24 10.3401 24 12C24 13.6599 23.6888 15.2161 23.0663 16.6686C22.4438 18.121 21.5793 19.4006 20.4726 20.5072C19.389 21.5908 18.121 22.4438 16.6686 23.0663C15.2161 23.6888 13.6599 24 12 24ZM11.9654 18.536C14.3862 18.536 16.2421 17.3026 17.5331 14.8357L16.3228 14.1787C15.8156 15.0778 15.1931 15.7579 14.4553 16.219C13.7406 16.6571 12.9107 16.8761 11.9654 16.8761C10.9971 16.8761 10.1441 16.6571 9.40634 16.219C8.69164 15.7579 8.09222 15.0778 7.60807 14.1787L6.43228 14.8357C7.72334 17.3026 9.56772 18.536 11.9654 18.536ZM7.43516 11.0663C7.89625 11.0663 8.26513 10.9164 8.54179 10.6167C8.8415 10.317 8.99136 9.94813 8.99136 9.51009C8.99136 9.07205 8.8415 8.70317 8.54179 8.40346C8.26513 8.10375 7.89625 7.95389 7.43516 7.95389C6.99712 7.95389 6.62824 8.10375 6.32853 8.40346C6.02882 8.70317 5.87896 9.07205 5.87896 9.51009C5.87896 9.94813 6.02882 10.317 6.32853 10.6167C6.62824 10.9164 6.99712 11.0663 7.43516 11.0663ZM16.5648 11.0663C17.0029 11.0663 17.3718 10.928 17.6715 10.6513C17.9712 10.3516 18.121 9.97118 18.121 9.51009C18.121 9.07205 17.9712 8.70317 17.6715 8.40346C17.3718 8.10375 17.0029 7.95389 16.5648 7.95389C16.1268 7.95389 15.7579 8.10375 15.4582 8.40346C15.1585 8.70317 15.0086 9.07205 15.0086 9.51009C15.0086 9.94813 15.1585 10.317 15.4582 10.6167C15.7579 10.9164 16.1268 11.0663 16.5648 11.0663Z"
      />
    )}
  </svg>
)

const Wrapper = styled.i({
  alignItems: 'center',
  display: 'flex',
  height: (props) => `${props.size}px`,
  justifyContent: 'center',
  minHeight: (props) => `${props.size}px`,
  minWidth: (props) => `${props.size}px`,
  overflow: 'hidden',
  position: 'relative',
  width: (props) => `${props.size}px`,
})

const ImgWrapper = styled(Img)({
  display: 'block',
  height: (props) => `${props.size * 0.75}px`,
  width: (props) => `${props.size}px`,
})

// main component.
const IconFeedback = ({ size = UNIT_SIZE, color, className, type = 1 }) => {
  // ------------------------------------------------------------------------------------------- //
  // PROCESS DATA                                                                                //
  // ------------------------------------------------------------------------------------------- //

  // make sure color is included in the library.
  const { colors } = theme
  const iconColor = styleguideHasKey(color, colors, type === 1 ? 'success' : 'alert')

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <Wrapper size={size} className={className}>
      <ImgWrapper size={size} color={colors[iconColor]} type={type} />
    </Wrapper>
  )
}

export default IconFeedback
