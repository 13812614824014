export const NEW_AUDITOR_USER_FORM = (users) => [
  { type: 'headline', label: 'Seleccione un usuario:' },
  { name: 'uid', placeholder: 'Usuarios', options: users },
  { name: 'stopAuditBudget', placeholder: 'Presupuesto mensual', type: 'number', required: true },
  { type: 'checkbox', name: 'isAuditor', placeholder: 'Marcar como auditor' },
]

export const EDIT_AUDITOR_USER_FORM = [
  { name: 'stopAuditBudget', placeholder: 'Presupuesto mensual', type: 'number', required: true },
  { type: 'checkbox', name: 'isAuditor', placeholder: 'Marcar como auditor' },
]

export const NEW_EPP_FORM = [
  { type: 'headline', label: 'Nombre del EPP' },
  { name: 'name', placeholder: 'Nombre', required: true },
]

export const NEW_ITEM_FORM = [
  { type: 'headline', label: 'Nombre para mostrar' },
  { name: 'name', placeholder: 'Nombre', required: true },
  { name: 'description', placeholder: 'Descripción' },
  { name: 'order', placeholder: 'Orden (número de item)', type: 'number' },
  {
    name: 'type',
    placeholder: 'Tipo de item',
    options: [
      { label: 'Conducta', name: 'CND' },
      { label: 'Operación', name: 'OPS' },
    ],
  },
]

export const NEW_PERSON_FORM = [
  { type: 'headline', label: 'Nombre o razón social' },
  { name: 'name', placeholder: 'Nombre', required: true },
  {
    name: 'type',
    placeholder: 'Tipo de item',
    options: [
      { label: 'Interno CCU', name: 'INT' },
      { label: 'Externo', name: 'EXT' },
    ],
  },
]

export const COMPLETE_AUDIT_FORM = (persons, epps, conductItems, operationItems) => [
  {
    type: 'array',
    items: [
      { name: 'auditType', placeholder: 'Tipo', disabled: true },
      { name: 'location', placeholder: 'Ubicación', disabled: true },
    ],
  },
  {
    type: 'array',
    items: [
      {
        name: 'shift',
        placeholder: 'Turno',
        options: [
          { value: 'matutino', label: 'Matutino' },
          { value: 'vespertino', label: 'Vespertino' },
          { value: 'nocturno', label: 'Nocturno' },
        ],
        required: true,
      },
      { name: 'person', placeholder: 'Persona observada', options: persons, required: true },
    ],
  },
  { type: 'safetyItemsHeadline', label: 'Seleccione según:' },
  {
    type: 'array',
    items: [
      { type: 'label', label: '<strong>Uso de su EPP.</strong><br />Cuál le falta:' },
      {
        name: 'eppRef',
        placeholder: 'Ninguno',
        options: epps,
        required: true,
        disabled: true,
      },
      {
        name: 'eppValue',
        type: 'radioGroup',
        options: [{ value: 1 }, { value: 2 }, { value: 3 }],
        required: true,
      },
    ],
  },
  ...conductItems.map((item, index) => ({
    name: item.ref,
    parent: 'auditItems',
    placeholder: item.name || `Item ${index + 1}`,
    type: 'radioGroup',
    options: [{ value: 1 }, { value: 2 }, { value: 3 }],
    required: item.required || false,
  })),
  { type: 'headline', label: 'Operación' },
  { type: 'safetyItemsHeadline', label: 'Seleccione según:' },
  ...operationItems.map((item, index) => ({
    name: item.ref,
    parent: 'auditItems',
    placeholder: item.name || `Item ${index + 1}`,
    type: 'radioGroup',
    options: [{ value: 1 }, { value: 2 }, { value: 3 }],
    required: item.required || false,
  })),
  { type: 'headline', label: 'Actos observados' },
  { name: 'comments', placeholder: 'Comentarios' },
]
