// dependencies.
import styled from 'styled-components'
// components.
import { Body, Label, Pill } from '../../../Typography'
import { Icon } from '../../../Icon'
// utils.
import { theme } from '../../../../styleguide'
import { getNameFull } from '../../../../js/usernameUtils'

// helpers
const baseUnit = theme.fontSize.x16

export const COLUMN_LAYOUT = [
  { label: 'Nombre Completo', dbKey: 'lastname', columns: 3 },
  { label: 'Documento', dbKey: 'document', columns: 1 },
  { label: 'Acceso', dbKey: 'access', columns: 2 },
  { label: 'Estado', columns: 1 },
  { label: 'Presupuesto', ddKey: 'stopAuditBudget', columns: 1 },
  { label: 'Acciones', columns: 2 },
]
export const cellWidth = 10 // 10% (100% / 10 columns)

// partials.
const Wrapper = styled.li({
  backgroundColor: (props) => (props.isEven ? theme.colors.smoke : theme.colors.white),
  borderBottom: `1px solid ${theme.opacity.ash80}`,
  display: 'flex',
  flexDirection: 'row',
  height: baseUnit * 3,
  transition: 'background 200ms ease',
  width: '100%',
  '&:focus': {
    backgroundColor: theme.opacity.primary20,
    outline: 'none',
  },
})

const Cell = styled.div({
  alignItems: 'center',
  borderRight: `1px solid ${theme.opacity.ash80}`,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  padding: `${baseUnit / 2}px ${baseUnit}px`,
  width: (props) => `${props.width}%`,
})

const ActionGroup = styled.div({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
  justifyContent: 'center',
  margin: 0,
  width: '100%',
})

const StyledBody = styled(Body)({
  alignItems: 'center',
  display: 'flex',
  gap: baseUnit / 2,
})

const StyledEdit = styled(Label)({
  background: (props) => (props.disabled ? theme.colors.smoke : theme.colors.warning),
  border: (props) =>
    props.disabled ? `1px solid ${theme.colors.white}` : `1px solid ${theme.colors.warning}`,
  borderRadius: baseUnit / 2 - 2,
  display: 'flex',
  fontSize: theme.fontSize.x14,
  gap: 4,
  padding: '3px 6px 3px 8px',
  width: 'fit-content',
})

// main component.
const UsersDesktopItem = ({ item, isEven, onEdit }) => {
  // only show if there's items.
  if (!item) return null
  const { firstname, lastname, document, access, isActive, pendingActivation, stopAuditBudget } =
    item

  const enableEdit = Boolean(onEdit)

  // return content.
  return (
    <Wrapper isEven={isEven} tabIndex={0}>
      <Cell width={cellWidth * COLUMN_LAYOUT[0].columns}>
        <StyledBody nowrap> {getNameFull(firstname, lastname)}</StyledBody>
      </Cell>
      <Cell width={cellWidth * COLUMN_LAYOUT[1].columns}>
        <StyledBody nowrap>{document}</StyledBody>
      </Cell>
      <Cell width={cellWidth * COLUMN_LAYOUT[2].columns}>
        <StyledBody nowrap>
          {access === 'superadmin'
            ? 'Super admin'
            : access === 'admin'
              ? 'Administrador'
              : 'Funcionario'}
        </StyledBody>
      </Cell>
      <Cell width={cellWidth * COLUMN_LAYOUT[3].columns}>
        <Pill type={isActive === false ? 0 : pendingActivation ? 1 : 2}>
          {isActive === false ? 'Inactivo' : pendingActivation ? 'Pendiente' : 'Activo'}
        </Pill>
      </Cell>
      <Cell width={cellWidth * COLUMN_LAYOUT[4].columns}>
        <StyledBody nowrap>{stopAuditBudget ?? 0}</StyledBody>
      </Cell>
      <Cell width={cellWidth * COLUMN_LAYOUT[5].columns}>
        <ActionGroup>
          <StyledEdit color="white" disabled={!enableEdit} onClick={enableEdit && onEdit}>
            Editar
            <Icon name="edit" size={16} color={!enableEdit ? 'ash' : 'white'} />
          </StyledEdit>
        </ActionGroup>
      </Cell>
    </Wrapper>
  )
}

export default UsersDesktopItem
