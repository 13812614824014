import Search from '../../Filters/Search'

// search function.
export const searchAuditsByAuditor = (array, searchTerm) => {
  if (!array) return

  return array.filter((data) => {
    let str = `${data.auditor}`
    if (data.type) str = `${str} ${data.type}`
    if (data.number) str = `${str} ${data.number}`

    const processStr = str.toUpperCase().includes(searchTerm.trim('^').toUpperCase())
    return processStr
  })
}

// main component.
const SearchAuditsByAuditor = ({ defaultValue = '', onSearch = () => {} }) => {
  // return content.
  return (
    <Search
      placeholder="Buscar por auditor o ubicación..."
      defaultValue={defaultValue}
      onChange={(term) => onSearch(term)}
    />
  )
}

export default SearchAuditsByAuditor
