// dependencies.

import React, { useState, useEffect } from 'react'
import moment from 'moment'
import styled from 'styled-components'
// components.
import { Body } from '../Typography'
import { Input } from '../Form'
// utils.
import { useDimensions } from '../../js/hooks'
import { MONTHS_ES, MONTHS_SHORT_ES } from '../../constants'

/************************/
/*                      */
/* Time Range Filter    */
/*                      */
/************************/

// partials.
const Wrapper = styled.div({
  display: 'flex',
  flexDirection: (props) => (props.column ? 'column' : 'row'),
  gap: (props) => (props.isTablet ? '8px' : '16px'),
  width: (props) => (props.column ? '100%' : 'fit-content'),
})

const InputWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  width: '100%',
})

const StyledDivider = styled(Body).attrs({
  align: 'center',
  color: 'white',
  type: 'caption',
})({
  alignSelf: 'center',
  width: 24,
})

// main component.
const FilterTimeRange = ({ granularity = ['w', 'm', 'y'], label, onFilter = () => {} }) => {
  // hooks.
  const { isMobile, isTablet } = useDimensions()

  const [selectedMonth, setSelectedMonth] = useState(moment().format('MM'))
  const [selectedYear, setSelectedYear] = useState(moment().format('YYYY'))

  useEffect(() => {
    handleFilter({ m: selectedMonth, y: selectedYear })
  }, [selectedMonth, selectedYear])

  // Weeks options.
  const weekOptions = Array.from({ length: 52 }, (_, i) => ({
    name: `${i + 1}`,
    label: `${i + 1}`,
  }))

  // Years options.
  const currentYear = moment().format('YYYY')
  const yearOptions = Array.from({ length: currentYear - 2023 + 1 }, (_, i) => ({
    name: `${2023 + i}`,
    label: `${2023 + i}`,
  }))

  // Months options.
  moment.updateLocale('es', {
    months: MONTHS_ES.split('_'),
    monthsShort: MONTHS_SHORT_ES.split('_'),
  })
  const monthOptions = moment.months().map((month, index) => ({
    name: moment().month(index).format('MM'),
    label: month,
  }))

  function getSelectedWeek(we, year = moment().year()) {
    const date = moment().year(year).week(we)
    const fromDate = date.startOf('week').toISOString().split('T')[0]
    const toDate = date.endOf('week').toISOString().split('T')[0]
    return { fromDate, toDate }
  }

  function getSelectedMonth(mo, year = moment().year()) {
    const date = moment()
      .year(year)
      .month(mo - 1)
    const fromDate = date.startOf('month').toISOString().split('T')[0]
    const toDate = date.endOf('month').format('YYYY-MM-DD')
    return { fromDate, toDate }
  }

  function getSelectedYear(year) {
    const date = moment().year(year)
    const fromDate = date.startOf('year').toISOString().split('T')[0]
    const toDate = date.endOf('year').toISOString().split('T')[0]
    return { fromDate, toDate }
  }
  const handleFilter = ({ w, m, y }) => {
    const selectedWeekValue = w || ''
    const selectedMonthValue = m || ''
    const selectedYearValue = y || ''

    let selectedRange = {}

    if (selectedMonthValue && selectedYearValue) {
      selectedRange = getSelectedMonth(selectedMonthValue, selectedYearValue)
    } else if (selectedWeekValue && selectedYearValue) {
      selectedRange = getSelectedWeek(selectedWeekValue, selectedYearValue)
    } else if (selectedYearValue) {
      selectedRange = getSelectedYear(selectedYearValue)
    } else if (selectedMonthValue) {
      selectedRange = getSelectedMonth(selectedMonthValue)
    } else if (selectedWeekValue) {
      selectedRange = getSelectedWeek(selectedWeekValue)
    }

    return onFilter(selectedRange)
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //
  // return content.

  return (
    <Wrapper column={!!label} isTablet={isTablet}>
      {label && (
        <Body type="caption" color="grey">
          {label}
        </Body>
      )}
      <InputWrapper>
        {granularity.includes('w') ? (
          <Input
            isMobile={isMobile}
            options={weekOptions}
            name="week"
            placeholder="Semana"
            onChange={(w) => handleFilter({ w })}
            width={120}
          />
        ) : null}
        {granularity.includes('w') && granularity.includes('m') ? (
          <StyledDivider>o</StyledDivider>
        ) : null}
        {granularity.includes('m') ? (
          <Input
            isMobile={isMobile}
            options={monthOptions}
            name="month"
            placeholder="Mes"
            defaultValue={selectedMonth}
            onChange={(m) => setSelectedMonth(m)}
            width={120}
          />
        ) : null}
        {granularity.includes('y') ? (
          <Input
            isMobile={isMobile}
            options={yearOptions}
            name="year"
            placeholder="Año"
            defaultValue={selectedYear}
            onChange={(y) => setSelectedYear(y)}
            width={120}
          />
        ) : null}
      </InputWrapper>
    </Wrapper>
  )
}

export default FilterTimeRange
