import { collection, orderBy, query } from 'firebase/firestore'
import { db } from '../firebase/firebase'
import { createSlice } from '@reduxjs/toolkit'
import { createFetchThunk } from '../store/fetch'

const q = query(collection(db, 'users'), orderBy('lastname', 'asc'))
const access = ['superadmin', 'admin', 'operator', 'developer']

export const fetchUsers = createFetchThunk('users', q, access)

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    data: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    filters: {
      searchTerm: '',
      sort: {
        key: 'lastname',
        direction: 'asc',
      },
    },
  },
  reducers: {
    usersUpdated: (state, action) => {
      state.data = action.payload
    },
    userAdded: (state, action) => {
      state.data.push(action.payload)
    },
    // Reducer para actualizar un usuario específico por ID
    userUpdated: (state, action) => {
      const index = state.data.findIndex((user) => user.id === action.payload.id)
      if (index !== -1) {
        state.data[index] = action.payload
      }
    },
    setFilters: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.data = action.payload
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  },
})

export const { usersUpdated, userUpdated, userAdded, setFilters } = usersSlice.actions
export default usersSlice.reducer
