// dependencies.
import styled from 'styled-components'
// utils.
import styleguideHasKey from '../../js/styleguideHasKey'
import { theme } from '../../styleguide'

/*********************************/
/*                               */
/*    Headline Text Component    */
/*                               */
/*********************************/

// helpers.
const fontSize = (size) => {
  switch (size) {
    case 'largest':
      return `${theme.fontSize.x48}px`
    case 'larger':
      return `${theme.fontSize.x40}px`
    case 'normal':
      return `${theme.fontSize.x32}px`
    case 'smaller':
      return `${theme.fontSize.x24}px`
    case 'smallest':
      return `${theme.fontSize.x20}px`
    default:
      return `${theme.fontSize.x16}px`
  }
}

const lineHeight = (size) => {
  switch (size) {
    case 'largest':
      return `${theme.lineHeight.x48}px`
    case 'larger':
      return `${theme.lineHeight.x40}px`
    case 'normal':
      return `${theme.lineHeight.x32}px`
    case 'smaller':
      return `${theme.lineHeight.x24}px`
    case 'smallest':
      return `${theme.lineHeight.x20}px`
    default:
      return `${theme.lineHeight.x16}px`
  }
}

// partials.
const Element = ({ className, size, children }) => {
  switch (size) {
    case 'largest':
      return <h1 className={className}>{children}</h1>
    case 'larger':
      return <h2 className={className}>{children}</h2>
    case 'normal':
      return <h3 className={className}>{children}</h3>
    case 'smaller':
      return <h4 className={className}>{children}</h4>
    case 'smallest':
      return <h5 className={className}>{children}</h5>
    default:
      return <h6 className={className}>{children}</h6>
  }
}

const Wrapper = styled(Element)(
  {
    color: (props) => props.color,
    flexGrow: 1,
    fontFamily: 'Roboto, sans-serif',
    fontSize: (props) => fontSize(props.size),
    fontWeight: (props) => (props.size === 'largest' ? 'normal' : 'bold'),
    lineHeight: (props) => lineHeight(props.size),
    margin: 0,
    padding: 0,
    textAlign: (props) => props.align,
    whiteSpace: (props) => (props.nowrap ? 'nowrap' : 'normal'),
  },
  `@media print { font-size: 14px; }`
)

// main component.
const Headline = ({ color, size, align = 'left', nowrap, className, children }) => {
  // ------------------------------------------------------------------------------------------- //
  // PROCESS DATA                                                                                //
  // ------------------------------------------------------------------------------------------- //

  // make sure color is included in the library.
  const { colors } = theme
  const textColor = styleguideHasKey(color, colors, 'black')

  // build props object.
  const props = {
    align,
    nowrap,
    className,
    color: colors[textColor],
    size,
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return <Wrapper {...props}>{children}</Wrapper>
}

export default Headline
