// dependencies.
import { useRef } from 'react'
import styled from 'styled-components'
// components.
import Label from '../Typography/Label'
// utils.
import styleguideHasKey from '../../js/styleguideHasKey'
import { theme } from '../../styleguide'
import { useHover } from '../../js/hooks'

/**************************/
/*                        */
/*    Button Component    */
/*                        */
/**************************/

// helpers
const baseUnit = theme.fontSize.x16

// partials.
const Wrapper = styled.span(
  {
    alignItems: 'center',
    backgroundColor: (props) => props.background,
    borderRadius: baseUnit * 1.5,
    cursor: (props) => (props.disabled ? 'not-allowed' : 'pointer'),
    display: 'flex',
    filter: (props) =>
      props.hovered
        ? `drop-shadow(${theme.shadows.larger})`
        : `drop-shadow(${theme.shadows.normal})`,
    justifyContent: 'center',
    opacity: (props) => (props.hovered ? 0.6 : 1),
    width: (props) => props.width,
    textAlign: 'center',
    transition: 'all 200ms ease',
    '&:focus': {
      opacity: 0.8,
      outline: 'none',
    },
  },
  `@media print { display: none; }`
)

const StyledLabel = styled(Label)({
  borderRadius: baseUnit * 1.5,
  padding: (props) => props.padding || '14px 24px',
  userSelect: 'none',
  width: '100%',
  whiteSpace: 'nowrap',
})

const StyledButton = styled.button({
  appearance: 'none',
  alignItems: 'center',
  background: 'none',
  border: 'none',
  borderRadius: baseUnit * 1.5,
  display: 'flex',
  flexDirection: 'row',
  gap: baseUnit / 2,
  height: '100%',
  justifyContent: 'center',
  padding: (props) => props.padding || '8px',
  userSelect: 'none',
  width: '100%',
})

// main component.
const Button = ({
  className,
  variant,
  color = 'white',
  background,
  href,
  to,
  hideLabel,
  padding,
  disabled,
  children,
  onClick,
  width,
}) => {
  // refs.
  const hoverRef = useRef(null)
  const isHover = useHover(hoverRef)

  // ------------------------------------------------------------------------------------------- //
  // PROCESS DATA                                                                                //
  // ------------------------------------------------------------------------------------------- //

  // make sure color is included in the library.
  const { colors } = theme
  const variantBg = disabled ? 'grey' : variant === 'secondary' ? 'terciary' : 'primary'
  const backgroundColor = styleguideHasKey(background, colors, variantBg)

  // build props object.
  const props = {
    background: colors[backgroundColor],
    className,
    disabled,
    hovered: !disabled && isHover,
    ref: hoverRef,
    tabIndex: 0,
    width,
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <Wrapper {...props}>
      {hideLabel ? (
        <StyledButton disabled={disabled} onClick={onClick}>
          {children}
        </StyledButton>
      ) : (
        <StyledLabel
          disabled={disabled}
          padding={padding}
          color={color}
          href={href}
          to={to}
          onClick={onClick}
          nowrap
        >
          {children}
        </StyledLabel>
      )}
    </Wrapper>
  )
}

export default Button
