// dependencies.
import { useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'
// components.
import { Body } from '../../components/Typography'
import { ScreenWrapper, Topbar, Wrapper } from '../../components/Layout'
// utils.
import { useAppSelector } from '../../js/store/hooks'
import { auth } from '../../js/firebase/firebase'
import { selectUserData } from '../../js/auth/authSelectors'

/***********************/
/*                     */
/*    Splash Screen    */
/*                     */
/***********************/

// partials.
const StyledBody = styled(Body)({
  padding: '15px',
  margin: 'auto',
  textAlign: 'center',
  maxWidth: '360px',
})

// main component.
const QrDestinationScreen = () => {
  // contexts.

  const userData = useAppSelector(selectUserData)
  // hooks.
  const navigate = useNavigate()
  const params = useParams()
  // ------------------------------------------------------------------------------------------- //
  // Handle actions                                                                              //
  // ------------------------------------------------------------------------------------------- //

  const access = userData?.access

  useEffect(() => {
    // handle logged out users.
    if (!auth.currentUser) navigate(`/signin?redirect=${params.locationId}`)
    if (userData.access === 'superadmin' || userData.access === 'admin')
      navigate(`/admin/${params.locationId}`)
    if (userData.access === 'operator') navigate(`/funcionario/${params.locationId}`)
  }, [auth.currentUser, access])

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <ScreenWrapper>
      <Topbar label="CCU" />

      <Wrapper background="white">
        <StyledBody color="grey">Bienvenido</StyledBody>
      </Wrapper>
    </ScreenWrapper>
  )
}

export default QrDestinationScreen
