// depenmdencies.
import { Fragment, useRef } from 'react'
import styled from 'styled-components'
// components.
import { Body } from '../../../Typography'
// utils.
import { useDimensions } from '../../../../js/hooks'
import { theme } from '../../../../styleguide'

// helpers.
const baseUnit = theme.fontSize.x16

const CHART_HEIGHT = baseUnit * 25
const CHART_LABEL_PLACEHOLDER = baseUnit * 6
const CHART_AXIS_PADDING = baseUnit * 1.5

// partials.
const Wrapper = styled.div({
  alignItems: (props) => (props.isEmpty ? 'center' : 'flex-end'),
  display: 'flex',
  flexDirection: 'row',
  height: (props) => `${props.isMobile ? CHART_HEIGHT / 1.5 : CHART_HEIGHT}px`,
  justifyContent: (props) => (props.isEmpty ? 'center' : 'flex-start'),
  padding: (props) => (props.isEmpty ? 0 : `${CHART_AXIS_PADDING}px`),
  paddingBottom: (props) => (props.isEmpty ? 0 : `${CHART_LABEL_PLACEHOLDER}px`),
  position: 'relative',
  width: '100%',
})

const AxisY = styled.i({
  borderLeft: `1px solid ${theme.opacity.ash80}`,
  display: 'block',
  height: `calc(100% - ${CHART_LABEL_PLACEHOLDER - CHART_AXIS_PADDING}px)`,
  left: CHART_AXIS_PADDING,
  pointerEvents: 'none',
  position: 'absolute',
  top: 0,
})

const AxisX = styled.i({
  borderTop: `1px solid ${theme.opacity.ash80}`,
  display: 'block',
  left: 0,
  pointerEvents: 'none',
  position: 'absolute',
  bottom: CHART_LABEL_PLACEHOLDER,
  width: '100%',
})

const SemiAxisX = styled.i({
  borderTop: (props) => `1px solid ${props.highlight ? theme.opacity.ash80 : theme.opacity.ash20}`,
  bottom: (props) => `${props.bottom}px`,
  display: 'block',
  left: CHART_AXIS_PADDING,
  pointerEvents: 'none',
  position: 'absolute',
  width: `calc(100% - ${CHART_AXIS_PADDING}px)`,
})

const CombinedBar = styled.span({
  display: 'flex',
  flexDirection: 'column',
})

const Bar = styled.span({
  alignItems: 'flex-end',
  background: (props) => (props.inverted ? theme.colors.alert : theme.colors.warning),
  bottom: (props) => `${props.bottom}px`,
  display: 'flex',
  height: (props) => `${props.height}px` || 0,
  justifyContent: 'center',
  left: (props) => props.left || 0,
  margin: (props) => `0 ${props.width / (baseUnit / 2)}px`,
  position: 'relative',
  width: (props) => `${(props.width / (baseUnit / 2)) * 6}px`,
})

const StyledLabel = styled(Body)({
  bottom: (props) => (props.bottom ? `${props.bottom}px` : 0),
  left: 0,
  position: 'absolute',
  transform: 'rotate(-60deg) translate(-80%, -60%)',
  width: CHART_LABEL_PLACEHOLDER,
})

const StyledAmount = styled(Body).attrs({
  type: 'caption',
  align: 'center',
})({
  bottom: (props) => (props.position === 'bottom' ? 0 : 'unset'),
  position: 'absolute',
  top: (props) => (props.position === 'top' ? 0 : 'unset'),
  transform: (props) => (props.position === 'top' ? 'translateY(-100%)' : 'translateY(100%)'),
  width: '100%',
})

const StyledAxisLabel = styled(Body)({
  bottom: (props) => `${props.bottom}px`,
  left: 0,
  paddingRight: baseUnit / 4,
  pointerEvents: 'none',
  position: 'absolute',
  transform: 'translateY(64%)',
  width: CHART_AXIS_PADDING,
})

// main component.
const BarChart = ({ auditors = [] }) => {
  const wrapperRef = useRef(null)

  const { isMobile } = useDimensions()

  const CHART_WIDTH = wrapperRef?.current ? wrapperRef?.current?.clientWidth - 16 : 800

  const getMaxY = auditors
    .map(({ budget }) => budget)
    .reduce((max, budget) => Math.max(max, budget), -Infinity)
  const getMinY = auditors
    .map(({ budget, done }) => (budget - done >= 0 ? budget - done : 0))
    .reduce((max, num) => Math.max(max, num), -Infinity)

  const nexFive = Math.ceil(getMaxY)
  const prevFive = Math.ceil(getMinY)
  const totalSteps = prevFive + 1 + nexFive + 1
  const axisSteps = Array.from({ length: totalSteps })

  const calcHeightPosition = (num, steps = totalSteps) => {
    const availableHeight =
      (isMobile ? CHART_HEIGHT / 1.5 : CHART_HEIGHT) - CHART_LABEL_PLACEHOLDER - CHART_AXIS_PADDING
    const maxSteps = steps
    const targetHeight = (num * 100) / maxSteps

    return (targetHeight * availableHeight) / 100
  }

  const processedItems = auditors
    .map(({ name: author, budget: amount, done }) => {
      const height = calcHeightPosition(amount)
      const invertedHeight = calcHeightPosition(amount - done)
      return {
        author,
        amount,
        invertedAmount: amount - done >= 0 ? amount - done : 0,
        height,
        invertedHeight,
      }
    })
    .filter((el) => el !== null)
    .sort((a, b) => b.amount - a.amount)

  return (
    <Wrapper ref={wrapperRef} isEmpty={!auditors.length} isMobile={isMobile}>
      {auditors.length ? (
        <>
          <AxisY />
          <AxisX />

          {axisSteps.map((el, i) => {
            const value = i - prevFive
            const elBottom = CHART_LABEL_PLACEHOLDER + calcHeightPosition(value + prevFive + 1)

            return (
              <Fragment key={i}>
                <StyledAxisLabel size="smallest" align="right" color="grey" bottom={elBottom}>
                  {value < 0 ? value * -1 : value}
                </StyledAxisLabel>
                <SemiAxisX bottom={elBottom} highlight={value === 0} />
              </Fragment>
            )
          })}

          {processedItems.map(({ author, amount, invertedAmount, height, invertedHeight }, i) => {
            const width =
              CHART_WIDTH / processedItems.length < baseUnit * 6
                ? CHART_WIDTH / processedItems.length
                : baseUnit * 6
            const bottom = calcHeightPosition(prevFive + 1 - invertedAmount)
            const invertedBottom = calcHeightPosition(prevFive + 1 - invertedAmount)

            return (
              <CombinedBar key={i}>
                <Bar left={width * i} height={height} width={width} bottom={bottom}>
                  <StyledAmount position="top">{amount}</StyledAmount>
                </Bar>
                <Bar
                  left={width * i}
                  height={invertedHeight}
                  width={width}
                  inverted
                  bottom={invertedBottom}
                >
                  <StyledAmount position="bottom">{invertedAmount}</StyledAmount>
                  <StyledLabel
                    size="smallest"
                    align="right"
                    color="grey"
                    nowrap
                    bottom={-invertedBottom}
                  >
                    {author}
                  </StyledLabel>
                </Bar>
              </CombinedBar>
            )
          })}
        </>
      ) : (
        <Body color="grey" align="center">
          No se encontraron resultados.
        </Body>
      )}
    </Wrapper>
  )
}

export default BarChart
