// depenmdencies.
import { Fragment, useRef } from 'react'
import styled from 'styled-components'
// components.
import { Body } from '../../../Typography'
// utils.
import { theme } from '../../../../styleguide'
import { useDimensions } from '../../../../js/hooks'

// helpers.
const baseUnit = theme.fontSize.x16

const CHART_HEIGHT = baseUnit * 25
const CHART_LABEL_PLACEHOLDER = baseUnit * 6
const CHART_AXIS_PADDING = baseUnit * 1.5

// partials.
const Wrapper = styled.div({
  alignItems: (props) => (props.isEmpty ? 'center' : 'flex-end'),
  display: 'flex',
  flexDirection: 'row',
  height: (props) => `${props.isMobile ? CHART_HEIGHT / 1.5 : CHART_HEIGHT}px`,
  justifyContent: (props) => (props.isEmpty ? 'center' : 'flex-start'),
  padding: (props) => (props.isEmpty ? 0 : `${CHART_AXIS_PADDING}px`),
  paddingBottom: (props) => (props.isEmpty ? 0 : `${CHART_LABEL_PLACEHOLDER}px`),
  position: 'relative',
  width: '100%',
})

const AxisY = styled.i({
  borderLeft: `1px solid ${theme.opacity.ash80}`,
  display: 'block',
  height: `calc(100% - ${CHART_LABEL_PLACEHOLDER - CHART_AXIS_PADDING}px)`,
  left: CHART_AXIS_PADDING,
  pointerEvents: 'none',
  position: 'absolute',
  top: 0,
})

const AxisX = styled.i({
  borderTop: `1px solid ${theme.opacity.ash80}`,
  display: 'block',
  left: 0,
  pointerEvents: 'none',
  position: 'absolute',
  bottom: CHART_LABEL_PLACEHOLDER,
  width: '100%',
})

const SemiAxisX = styled.i({
  borderTop: `1px solid ${theme.opacity.ash20}`,
  bottom: (props) => `${props.bottom}px`,
  display: 'block',
  left: CHART_AXIS_PADDING,
  pointerEvents: 'none',
  position: 'absolute',
  width: `calc(100% - ${CHART_AXIS_PADDING}px)`,
})

const Bar = styled.span({
  alignItems: 'flex-end',
  background: theme.colors.primary,
  display: 'flex',
  height: (props) => `${props.height}px` || 0,
  justifyContent: 'center',
  left: (props) => props.left || 0,
  margin: (props) => `0 ${props.width / (baseUnit / 2)}px`,
  position: 'relative',
  width: (props) => `${(props.width / (baseUnit / 2)) * 6}px`,
})

const StyledLabel = styled(Body)({
  bottom: 0,
  left: 0,
  position: 'absolute',
  transform: 'rotate(-60deg) translate(-80%, -120%)',
  width: CHART_LABEL_PLACEHOLDER,
})

const StyledAmount = styled(Body)({
  position: 'absolute',
  top: 0,
  transform: 'translateY(-100%)',
  width: '100%',
})

const StyledAxisLabel = styled(Body)({
  bottom: (props) => `${props.bottom}px`,
  left: 0,
  paddingRight: baseUnit / 4,
  pointerEvents: 'none',
  position: 'absolute',
  transform: 'translateY(64%)',
  width: CHART_AXIS_PADDING,
})

// main component.
const BarChart = ({ epps = [], items = [] }) => {
  const wrapperRef = useRef(null)

  const { isMobile } = useDimensions()

  const CHART_WIDTH = wrapperRef?.current ? wrapperRef?.current?.clientWidth - 16 : 800

  const getMaxY = items
    .map(({ ref }) => {
      return items.filter((card) => card.ref.id === ref.id).length
    })
    .reduce((max, count) => {
      return Math.max(max, count)
    }, -Infinity)

  const nexFive = Math.ceil(getMaxY)
  const axisSteps = Array.from({ length: nexFive + 1 })

  const calcHeightPosition = (num) => {
    const availableHeight =
      (isMobile ? CHART_HEIGHT / 1.5 : CHART_HEIGHT) - CHART_LABEL_PLACEHOLDER - CHART_AXIS_PADDING
    const maxSteps = nexFive + 1
    const targetHeight = (num * 100) / maxSteps

    return (targetHeight * availableHeight) / 100
  }

  const processedItems = epps
    .map(({ ref, name }) => {
      const amount = items.filter((card) => card.ref.id === ref.id).length
      const height = calcHeightPosition(amount)

      return {
        author: name || 'Anónimo',
        amount,
        height,
      }
    })
    .filter((el) => el !== null)
    .sort((a, b) => b.amount - a.amount)

  return (
    <Wrapper ref={wrapperRef} isEmpty={!items.length} isMobile={isMobile}>
      {items.length ? (
        <>
          <AxisY />
          <AxisX />

          {axisSteps.map((el, i) => {
            const value = i + 1
            const elBottom = CHART_LABEL_PLACEHOLDER + calcHeightPosition(value)

            return (
              <Fragment key={i}>
                <StyledAxisLabel size="smallest" align="right" color="grey" bottom={elBottom}>
                  {value}
                </StyledAxisLabel>
                <SemiAxisX bottom={elBottom} />
              </Fragment>
            )
          })}

          {processedItems.map(({ author, amount, height }, i) => {
            const width =
              CHART_WIDTH / processedItems.length < baseUnit * 6
                ? CHART_WIDTH / processedItems.length
                : baseUnit * 6

            return (
              <Bar key={i} left={width * i} height={height} width={width}>
                <StyledAmount type="caption" align="center">
                  {amount}
                </StyledAmount>
                <StyledLabel size="smallest" align="right" color="grey" nowrap>
                  {author}
                </StyledLabel>
              </Bar>
            )
          })}
        </>
      ) : (
        <Body color="grey" align="center">
          No se encontraron resultados.
        </Body>
      )}
    </Wrapper>
  )
}

export default BarChart
