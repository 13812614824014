// dependencies.
import styled from 'styled-components'
// components.
import Headline from './Headline'
import { Button } from '../../components/Button'
// utils.
import { theme } from '../../styleguide'
import { useDimensions } from '../../js/hooks'

/***********************/
/*                     */
/*    Module Header    */
/*                     */
/***********************/

// partials.
const Wrapper = styled.div({
  alignItems: 'center',
  backgroundColor: (props) => (props.secondary ? theme.colors.smoke : theme.colors.white),
  borderBottom: (props) => (props.noBorder ? 'none' : `2px solid ${theme.colors.ash}`),
  display: 'flex',
  flexDirection: (props) => props.direction || (props.isMobile ? 'column' : 'row'),
  gap: (props) => (props.isMobile ? '8px' : '24px'),
  margin: 0,
  padding: (props) => (props.isMobile ? '8px' : '16px'),
  width: '100%',
})

// main component.
const Header = ({
  title,
  label,
  noBorder,
  secondary,
  direction,
  hideButtonLabel,
  onClick,
  children,
}) => {
  // hooks.
  const { isMobile } = useDimensions()

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // return content.
  return (
    <Wrapper isMobile={isMobile} noBorder={noBorder} secondary={secondary} direction={direction}>
      {title ? (
        <>
          <Headline size={isMobile ? 'smallest' : 'smaller'} nowrap={!isMobile}>
            {title}
          </Headline>
          {children || null}
        </>
      ) : (
        <Headline size={isMobile ? 'smallest' : 'smaller'} nowrap={!isMobile}>
          {children}
        </Headline>
      )}
      {onClick ? (
        <Button onClick={onClick} hideLabel={hideButtonLabel}>
          {label}
        </Button>
      ) : null}
    </Wrapper>
  )
}

export default Header
