// dependencies.
import styled from 'styled-components'
// utils.
import { theme } from '../../../../styleguide'
import { Body } from '../../../Typography'

// helpers
const baseUnit = theme.fontSize.x16

const StyledWrapper = styled.table({
  borderCollapse: 'collapse',
  marginTop: baseUnit / 2,
  width: '100%',
})

const StyledHeader = styled.th({
  borderBottom: `2px solid ${theme.opacity.ash80}`,
  fontFamily: 'Roboto, sans-serif',
  fontSize: `${theme.fontSize.x14}px`,
  padding: `${baseUnit / 2}px`,
  textAlign: (props) => (props.align ? props.align : 'center'),
})

const StyledCell = styled.td({
  backgroundColor: (props) => (props.noBackground ? 'transparent' : theme.opacity.success10),
  borderBottom: (props) => (props.isLast ? 'none' : `1px solid ${theme.opacity.ash80}`),
  borderRight: (props) => (props.noBorder ? 'none' : `1px solid ${theme.opacity.ash80}`),
  fontFamily: 'Roboto, sans-serif',
  fontSize: `${theme.fontSize.x16}px`,
  padding: `0 ${baseUnit / 4}px`,
  textAlign: (props) => (props.align ? props.align : 'center'),
})

const StyledNumber = styled(Body).attrs({
  size: 'smallest',
})({
  alignItems: 'center',
  display: 'flex',
  height: baseUnit,
  justifyContent: 'center',
  margin: '0 auto',
  maxHeight: baseUnit,
  minWidth: baseUnit * 1.5,
  width: 'fit-content',
})

function AuditAccumulationTable({ epp, items = [], dynamicColumns = [] }) {
  if (!items.length || !dynamicColumns.length) return null

  const processedItems = [
    { label: 'Uso de EPP', value: epp },
    ...dynamicColumns.map(({ id, label }, i) => {
      const hasValue = items?.find(({ ref }) => ref.id === id) ?? {}
      return { label, value: hasValue.value }
    }),
  ].sort((a, b) => b.value - a.value)

  return (
    <StyledWrapper>
      <thead>
        <tr>
          <StyledHeader align="left">Parámetro</StyledHeader>
          <StyledHeader>Cant.</StyledHeader>
        </tr>
      </thead>
      <tbody>
        {processedItems.map(({ label, value }, i) => {
          const isLast = i === processedItems.length - 1
          return (
            <tr key={`${value}-${i}`}>
              <StyledCell align="left" isLast={isLast}>
                <Body size="minimal">{label}</Body>
              </StyledCell>
              <StyledCell isLast={isLast} noBorder noBackground>
                <StyledNumber>{value}</StyledNumber>
              </StyledCell>
            </tr>
          )
        })}
      </tbody>
    </StyledWrapper>
  )
}

export default AuditAccumulationTable
