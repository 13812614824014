import { collection, orderBy, query } from 'firebase/firestore'
import { db } from '../firebase/firebase'
import { createSlice } from '@reduxjs/toolkit'
import { createFetchThunk } from '../store/fetch'

const q = query(collection(db, 'budgets'), orderBy('startDate', 'desc'))
const access = ['superadmin', 'admin', 'operator', 'developer']

export const fetchBudgets = createFetchThunk('budgets', q, access)

const budgetsSlice = createSlice({
  name: 'budgets',
  initialState: {
    data: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    filters: {
      searchTerm: '',
      sort: {
        key: 'startDate',
        direction: 'caret-up',
      },
    },
  },
  reducers: {
    budgetAdded: (state, action) => {
      state.data.push(action.payload)
    },
    budgetUpdated: (state, action) => {
      const index = state.data.findIndex((budget) => budget.id === action.payload.id)
      if (index !== -1) {
        state.data[index] = action.payload
      }
    },
    budgetsUpdated: (state, action) => {
      state.data = action.payload
    },
    setFilters: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBudgets.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchBudgets.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.data = action.payload
      })
      .addCase(fetchBudgets.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  },
})
export const { budgetAdded, budgetUpdated, budgetsUpdated, setFilters } = budgetsSlice.actions
export default budgetsSlice.reducer
