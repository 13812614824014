// dependencies.
import styled from 'styled-components'
// components.
import IconFeedback from '../../../Icon/IconFeedback'
import { Body, Pill } from '../../../Typography'
// utils.
import { theme } from '../../../../styleguide'
import { DATE_FORMAT } from '../../../../constants'
import auditStatusFormat from '../../../../js/auditStatusFormat'
import dateFormat from '../../../../js/dateFormat'

// helpers
const baseUnit = theme.fontSize.x16

export const COLUMN_LAYOUT = [
  { label: 'Auditor', dbKey: 'auditorRef', columns: 4 },
  { label: 'Ubicación', dbKey: 'locationRef', columns: 3 },
  { label: 'Fecha límite', dbKey: 'deadline', columns: 1 },
  { label: 'Estado', dbKey: 'status', columns: 1 },
  { label: 'Resultado', dbKey: 'feedback', columns: 1 },
]
export const cellWidth = 10 // 10% (100% / 10 columns)

// partials.
const Wrapper = styled.li({
  backgroundColor: (props) => (props.isEven ? theme.colors.smoke : theme.colors.white),
  borderBottom: `1px solid ${theme.opacity.ash80}`,
  display: 'flex',
  flexDirection: 'row',
  height: baseUnit * 3,
  transition: 'background 200ms ease',
  width: '100%',
  '&:focus': {
    backgroundColor: theme.opacity.primary20,
    outline: 'none',
  },
})

const Cell = styled.div({
  alignItems: 'center',
  borderRight: `1px solid ${theme.opacity.ash80}`,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  padding: `${baseUnit / 2}px ${baseUnit}px`,
  width: (props) => `${props.width}%`,
})

const StyledBody = styled(Body)({
  alignItems: 'center',
  display: 'flex',
  gap: baseUnit / 2,
})

// main component.
const AssignmentDesktopItem = ({ item, isEven }) => {
  // only show if there's items.
  if (!item) return null

  const { auditor, location, locationP, status, deadline, feedback } = item

  // return content.
  return (
    <Wrapper isEven={isEven} tabIndex={0}>
      <Cell width={cellWidth * COLUMN_LAYOUT[0].columns}>
        <StyledBody nowrap>{auditor}</StyledBody>
      </Cell>
      <Cell width={cellWidth * COLUMN_LAYOUT[1].columns}>
        {locationP ? (
          <>
            <StyledBody size="smaller" nowrap>
              {location}: <strong>{locationP}</strong>
            </StyledBody>
          </>
        ) : (
          <StyledBody size="smaller" weight="bold" nowrap>
            {location}
          </StyledBody>
        )}
      </Cell>
      <Cell width={cellWidth * COLUMN_LAYOUT[2].columns}>
        <StyledBody nowrap>{dateFormat(deadline, DATE_FORMAT)}</StyledBody>
      </Cell>
      <Cell width={cellWidth * COLUMN_LAYOUT[3].columns}>
        <Pill type={status !== 0 ? 2 : 0}>{auditStatusFormat(status)}</Pill>
      </Cell>
      <Cell width={cellWidth * COLUMN_LAYOUT[4].columns}>
        {feedback ? <IconFeedback size={24} type={feedback} /> : null}
      </Cell>
    </Wrapper>
  )
}

export default AssignmentDesktopItem
