// store.js

import { configureStore } from '@reduxjs/toolkit'
import authSlice from '../auth/authSlice'
import locationsSlice from '../locations/locationsSlice'
import usersSlice from '../users/usersSlice'
import cardsSlice from '../cards/cardsSlice'
import anomaliesSlice from '../anomalies/anomaliesSlice'
import auditDataSlice from '../auditData/auditDataSlice'
import budgetsSlice from '../budgets/budgetsSlice'
import auditsSlice from '../audits/auditsSlice'

export default configureStore({
  reducer: {
    user: authSlice,
    locations: locationsSlice,
    users: usersSlice,
    cards: cardsSlice,
    anomalies: anomaliesSlice,
    auditData: auditDataSlice,
    budgets: budgetsSlice,
    audits: auditsSlice,
    // ...reducers
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // warning !! this is not recommended for production - see later
      serializableCheck: false,
    }),
})
