import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { Body } from '../Typography'
import { theme } from '../../styleguide'

import { useDimensions } from '../../js/hooks'

const radioSize = 20
const paddingSize = 4

const colorOverride = ['', 'success', 'alert', 'warning']

const Container = styled.div({
  alignItems: 'stretch',
  borderTop: `1px solid ${theme.colors.ash}`,
  display: 'flex',
  gap: 16,
  marginBottom: (props) => (props.isMobile ? '-8px' : '-16px'),
})

const RadioWrapper = styled.div({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  gap: 8,
  padding: 8,
})

const RadioButton = styled.div({
  alignItems: 'center',
  background: (props) => props.background,
  border: (props) => `2px solid ${props.color}`,
  borderRadius: '50%',
  color: (props) => props.color,
  cursor: 'pointer',
  display: 'flex',
  flex: 'none',
  height: `${radioSize + paddingSize * 2}px`,
  justifyContent: 'center',
  order: 0,
  padding: `${paddingSize}px`,
  position: 'relative',
  width: `${radioSize + paddingSize * 2}px`,
})

const RadioDot = styled.div({
  background: (props) => props.color,
  borderRadius: '50%',
  height: `${radioSize - 6}px`,
  width: `${radioSize - 6}px`,
})

const OptionsContainer = styled.div({
  borderLeft: `1px solid ${theme.colors.ash}`,
  display: 'flex',
  gap: 4,
  padding: 4,
})

const StyledLabel = styled(Body).attrs({
  size: 'smaller',
  weight: 'bold',
})({
  alignSelf: 'center',
  flexGrow: 1,
  height: 'fit-content',
  width: '100%',
})

const SingleOptionGroup = ({ options, defaultValue = '', disabled = false, onChange, label }) => {
  const { isMobile } = useDimensions()
  const [selected, setSelected] = useState(defaultValue)

  const handleSelect = (value) => {
    if (disabled) return
    setSelected(value)
  }

  useEffect(() => {
    if (defaultValue !== '') setSelected(defaultValue)
  }, [defaultValue])

  useEffect(() => {
    if (!onChange) return
    onChange(selected)
  }, [selected])

  const { colors } = theme
  const color = disabled ? 'ash' : 'black'
  const background = disabled ? 'smoke' : 'white'

  return (
    <Container isMobile={isMobile}>
      {label && <StyledLabel>{label}</StyledLabel>}
      <OptionsContainer>
        {options.map((option) => {
          const bg = colorOverride[option.value]
          return (
            <RadioWrapper key={option.value} onClick={() => handleSelect(option.value)}>
              <RadioButton color={colors[bg] || colors[color]} background={colors[background]}>
                {selected === option.value && <RadioDot color={colors[bg] || colors[color]} />}
              </RadioButton>
            </RadioWrapper>
          )
        })}
      </OptionsContainer>
    </Container>
  )
}

export default SingleOptionGroup
