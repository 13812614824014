import { useEffect } from 'react'
// components.
import { Body } from '../../Typography'
import { Button } from '../../Button'
import { Icon } from '../../Icon'
// utils.
import dateFormat, { dateFormatObject } from '../../../js/dateFormat'
import { useDimensions } from '../../../js/hooks'
import { useAppDispatch, useAppSelector } from '../../../js/store/hooks'
import { selectUserData } from '../../../js/auth/authSelectors'
import {
  Container,
  ContentColumn,
  ContentRow,
  ContentWrapper,
  DateBoxContent,
  DateWrapper,
  DescriptionWrapper,
  FooterWrapper,
  HeaderWrapper,
  HeadingWrapper,
  StyledLocationWrapper,
  StyledItem,
  StyledBody,
  StyledCaption,
  StyledDivider,
  StyledType,
  StyledTypeNumberWrapper,
  Wrapper,
  StyledValue,
  RadioButton,
  RadioDot,
  StyledCopy,
} from './AuditCardStyled'
import { getAuditInfo } from './AuditCardHelpers'
import {
  selectAuditDataStatus,
  selectFilteredItemsConduct,
  selectFilteredItemsOperation,
} from '../../../js/auditData/auditDataSelectors'
import { fetchAuditData } from '../../../js/auditData/auditDataSlice'
import IconFeedback from '../../Icon/IconFeedback'
// import { auditItemValueFormat } from '../../../js/auditItemValueFormat'

const AuditCard = ({
  audit,
  type,
  actionLabel,
  className,
  isMinimal = false,
  onClick,
  onActionClick,
}) => {
  // hooks.
  const { isMobile, isTablet } = useDimensions()
  // data.
  const dispatch = useAppDispatch()
  const loaded = useAppSelector(selectAuditDataStatus)
  const userData = useAppSelector(selectUserData)
  const filteredItemsConduct = useAppSelector(selectFilteredItemsConduct())
  const filteredItemsOperation = useAppSelector(selectFilteredItemsOperation())

  const canCompleteAudit = userData.uid === audit.auditorRef?.id && audit.status === 0

  useEffect(() => {
    if (loaded === 'idle') dispatch(fetchAuditData())
  }, [dispatch, loaded])

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  if (!type) return null

  const {
    number,
    location,
    locationP,
    deadline,
    completedDate,
    createdDate,
    auditor,
    person,
    shift,
    comments,
    auditItems,
    auditItemEpp,
    epp,
    feedback,
  } = audit
  // return content.
  return (
    <Container tabIndex={0} onClick={onClick}>
      <Wrapper
        className={className}
        theme={getAuditInfo[type]?.theme}
        isTablet={isTablet}
        isMinimal={isMinimal}
      >
        <HeadingWrapper>
          <ContentColumn>
            <StyledTypeNumberWrapper>
              <StyledType size="smallest" color="alert">
                {getAuditInfo[type]?.title}
              </StyledType>
              <StyledCaption nowrap>N° {number ?? 0}</StyledCaption>
            </StyledTypeNumberWrapper>
            {location ? (
              <StyledLocationWrapper>
                <StyledCaption>Ubicación:</StyledCaption>{' '}
                <Body align="left" size="smallest">
                  {locationP ? (
                    <>
                      {locationP}
                      {' - '}
                      <strong>{location}</strong>
                    </>
                  ) : (
                    <strong>{location}</strong>
                  )}
                </Body>
              </StyledLocationWrapper>
            ) : null}
          </ContentColumn>
          <ContentColumn align="center">
            <StyledCaption align="center">Vto.</StyledCaption>
            <DateWrapper>
              <Body align="center" type="caption" color="white">
                {dateFormatObject(deadline).month} {dateFormatObject(createdDate).yy}
              </Body>
              <DateBoxContent>
                <Body align="center" size="larger">
                  {dateFormatObject(deadline).day}
                </Body>
              </DateBoxContent>
            </DateWrapper>
          </ContentColumn>
        </HeadingWrapper>

        {isMinimal ? null : (
          <>
            <HeaderWrapper>
              <ContentRow isMobile={isMobile}>
                <ContentRow justify="flex-start">
                  <StyledCaption>Nombre:</StyledCaption>
                  <StyledBody size="smallest" weight="bold" color="grey">
                    {auditor}
                  </StyledBody>
                </ContentRow>
                <ContentRow justify="flex-start">
                  <StyledCaption>Realizada:</StyledCaption>
                  <StyledBody size="smallest" weight="bold" color="grey">
                    {dateFormat(completedDate, 'DD MMMM, YYYY')}
                  </StyledBody>
                </ContentRow>
              </ContentRow>
              <ContentRow isMobile={isMobile}>
                <ContentRow justify="flex-start">
                  <StyledCaption>Empresa observada:</StyledCaption>
                  <StyledBody size="smallest" weight="bold" color="grey">
                    {person}
                  </StyledBody>
                </ContentRow>
                <ContentRow justify="flex-start">
                  <StyledCaption>Turno:</StyledCaption>
                  <StyledBody size="smallest" weight="bold" color="grey">
                    {shift}
                  </StyledBody>
                </ContentRow>
              </ContentRow>
            </HeaderWrapper>

            <ContentWrapper>
              <StyledDivider />
              <StyledItem>
                <StyledBody size="smallest">
                  <strong>Uso de su EPP.</strong> Cuál le falta:
                </StyledBody>
                {epp ? (
                  <StyledBody size="smallest" weight="bold">
                    -- {epp} --
                  </StyledBody>
                ) : null}
                <StyledValue>
                  <RadioButton type={auditItemEpp?.value ?? undefined}>
                    {auditItemEpp ? <RadioDot type={auditItemEpp.value} /> : null}
                  </RadioButton>
                </StyledValue>
              </StyledItem>
              {filteredItemsConduct.map(({ id, name, description }, i) => {
                const hasValue = auditItems?.find(({ ref }) => ref.id === id) ?? {}
                return (
                  <StyledItem key={i}>
                    <StyledCopy size="smallest">
                      <strong>{name}</strong>
                      {!isMobile && description ? ' ' + description : null}
                    </StyledCopy>
                    <StyledValue>
                      <RadioButton type={hasValue.value ?? undefined}>
                        {hasValue ? <RadioDot type={hasValue.value} /> : null}
                      </RadioButton>
                    </StyledValue>
                  </StyledItem>
                )
              })}
              <StyledDivider>Operación</StyledDivider>
              {filteredItemsOperation.map(({ id, name, description }, i) => {
                const hasValue = auditItems?.find(({ ref }) => ref.id === id) ?? {}
                return (
                  <StyledItem key={i}>
                    <StyledCopy size="smallest">
                      <strong>{name}</strong>
                      {!isMobile && description ? ' ' + description : null}
                    </StyledCopy>
                    <StyledValue>
                      <RadioButton type={hasValue.value ?? undefined}>
                        {hasValue ? <RadioDot type={hasValue.value} /> : null}
                      </RadioButton>
                    </StyledValue>
                  </StyledItem>
                )
              })}
              <StyledDivider>Actos Observados (Seguros/Inseguros)</StyledDivider>
              <DescriptionWrapper>
                <Body type="caption" color="grey" align="center">
                  Si es inseguro, causa (falta procedimiento, falta capacitación, etc.)
                </Body>
                <Body size="smaller" align="center">
                  {comments}
                </Body>
              </DescriptionWrapper>
            </ContentWrapper>

            <FooterWrapper isTablet={isTablet}>
              {canCompleteAudit && actionLabel ? (
                <Button background="asphalt" onClick={onActionClick}>
                  <Icon name="edit" size={16} color="white" /> {actionLabel}
                </Button>
              ) : feedback ? (
                <>
                  <Body type="caption">Retroalimentación/Conclusión</Body>
                  <IconFeedback size={24} type={feedback} />
                </>
              ) : null}
            </FooterWrapper>
          </>
        )}
      </Wrapper>
    </Container>
  )
}

export default AuditCard
