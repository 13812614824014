// dependencies.
import styled from 'styled-components'
// components.
import { Body } from '../../../Typography'
// utils.
import { theme } from '../../../../styleguide'
import { useDimensions } from '../../../../js/hooks'

// helpers
const baseUnit = theme.fontSize.x16

// partials.
const Wrapper = styled.div(
  {
    alignItems: 'center',
    background: theme.colors.white,
    borderRadius: `${baseUnit}px`,
    display: 'flex',
    filter: `drop-shadow(${theme.shadows.smaller})`,
    flexDirection: 'column',
    height: 'fit-content',
    justifyContent: 'center',
    minHeight: (props) => `${props.isEmpty ? baseUnit * 2 : baseUnit * 15}px`,
    overflow: 'hidden',
    paddingTop: (props) => `${props.isEmpty ? 0 : baseUnit * 8}px`,
    width: (props) =>
      props.isTablet || !props.width ? '100%' : `calc(${props.width} - ${baseUnit}px)`,
  },
  `@media print { margin: 0; width: 100%; }`
)

// main component.
const AuditReportsDesktopWrapper = ({ isEmpty = false, width = '100%', children }) => {
  const { isTablet } = useDimensions()

  return (
    <Wrapper isEmpty={isEmpty} width={width} isTablet={isTablet}>
      {isEmpty ? (
        <Body type="caption" color="grey">
          No se encontraron resultados.
        </Body>
      ) : (
        children
      )}
    </Wrapper>
  )
}

export default AuditReportsDesktopWrapper
