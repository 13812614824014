// dependencies.
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
// components.
import { Icon } from '../Icon'
import { Body } from '../Typography'
// utils.
import validateInput, { cleanCi } from '../../js/validateInput'
import { dateFormatUS } from '../../js/dateFormat'
import { theme } from '../../styleguide'
import { useFocus } from '../../js/hooks'

// constants.

/*************************/
/*                       */
/*    Input Component    */
/*                       */
/*************************/

// helpers.
const baseUnit = theme.fontSize.x16

const maxHeight = `${baseUnit * 3}px`
const minHeight = `${baseUnit}px`
const minWidth = `${baseUnit * 3}px`
const radius = `${baseUnit * 1.5}px`
const iconSize = {
  small: 15,
  large: 20,
}

const inputStyles = {
  appearance: 'none',
  background: 'transparent',
  border: 0,
  color: theme.colors.black,
  flexGrow: 1,
  fontSize: minHeight,
  lineHeight: maxHeight,
  minWidth: minWidth,
  padding: 0,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '100%',
}

// partials.
const Wrapper = styled.div({
  alignItems: 'center',
  background: (props) => (props.isDisabled ? theme.colors.smoke : theme.colors.white),
  border: (props) =>
    `1px solid ${props.valueCheck === false ? theme.colors.alert : theme.colors.ash}`,
  borderRadius: radius,
  boxShadow: (props) =>
    props.valueCheck === false
      ? `${theme.shadows.error}`
      : props.isFocused
        ? `${theme.shadows.focus}`
        : '',
  display: (props) => (props.isHidden ? 'none' : 'flex'),
  marginTop: 0,
  padding: 0,
  position: 'relative',
  width: (props) => (props.width ? `${props.width}px` : '100%'),

  '@media (max-width: 1280px)': {
    height: '36px',
  },
})

const LabelWrapper = styled(Body)({
  left: `${baseUnit / 2}px`,
  padding: `0 ${baseUnit / 2}px`,
  position: 'absolute',
  top: '12px',
  transform: 'translateY(-56%)',
})

const PrefixWrapper = styled.div({
  alignItems: 'center',
  background: theme.colors.smoke,
  borderRadius: `${radius} 0 0 ${radius}`,
  display: 'flex',
  justifyContent: 'center',
  height: maxHeight,
  order: 0,
  width: maxHeight,
})

const InputWrapper = styled.div({
  alignItems: 'center',
  display: 'flex',
  flexGrow: 1,
  gap: minHeight,
  height: maxHeight,
  padding: `0 ${baseUnit}px`,

  '@media (max-width: 1280px)': {
    gap: 8,
  },
})

const InputElement = styled.input({
  ...inputStyles,
  color: (props) => (props.disabled ? theme.colors.grey : theme.colors.black),
  paddingTop: (props) => (props.hasLabel ? '10px' : 0),

  '@media (max-width: 1280px)': {
    fontSize: '12px',
    lineHeight: '36px',
  },
})

const SelectElement = styled.select({
  ...inputStyles,
  paddingRight: '20px',

  '@media (max-width: 1280px)': {
    fontSize: '12px',
    lineHeight: '36px',
  },
})

const SelectWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: maxHeight,
  paddingTop: (props) => (props.hasLabel ? '10px' : 0),
  position: 'relative',
  width: '100%',
})

const IconWrapper = styled.div({
  flexGrow: 0,
  height: `${maxHeight}px`,
  width: `${iconSize.large}px`,

  '@media (max-width: 1280px)': {
    height: '15px',
    width: '15px',
  },
})

const AbsoluteIconWrapper = styled.div({
  alignItems: 'center',
  display: 'flex',
  height: maxHeight,
  pointerEvents: 'none',
  position: 'absolute',
  right: '0',
  top: '0',

  '@media (max-width: 1280px)': {
    height: '48px',
  },
})

const PrefixElement = ({ prefix }) => {
  if (!prefix) return null

  return (
    <PrefixWrapper>
      <Body color="ash">{prefix}</Body>
    </PrefixWrapper>
  )
}

const IconElement = ({ icon }) => {
  if (!icon) return null

  return (
    <IconWrapper>
      <Icon
        name={icon}
        size={window.innerWidth <= 1280 ? iconSize.small : iconSize.large}
        color="ash"
      />
    </IconWrapper>
  )
}

// main component.
const Input = ({
  className,
  defaultValue = '',
  disabled = false,
  required = false,
  hidden = false,
  iconL,
  iconR,
  name,
  options,
  placeholder,
  prefix,
  showLabel,
  type = 'text',
  width,
  onChange,
  min = null,
  max = null,
}) => {
  // refs.
  const textInput = useRef(null)
  const { focused } = useFocus(textInput)
  // data objects.
  const valueFormatted = type === 'date' ? dateFormatUS(defaultValue) : defaultValue
  // state.
  const [value, setValue] = useState(valueFormatted)
  const [valueCheck, setValueCheck] = useState(true)

  // ------------------------------------------------------------------------------------------- //
  // Handle actions                                                                              //
  // ------------------------------------------------------------------------------------------- //

  // handle update input on value change.
  const handleInputChange = () => {
    setValue(
      textInput.current.name === 'document'
        ? cleanCi(textInput.current.value)
        : textInput.current.value
    )
    if (textInput.current.value === '' && textInput.current.required) {
      setValueCheck(false)
    } else if (textInput.current.value !== '') {
      setValueCheck(validateInput(name, textInput.current.value))
    }
  }

  // handle reset on input focus.
  const handleInputBlur = () => {
    if (textInput.current.value === '') {
      setValue(valueFormatted)
      if (textInput.current.required) setValueCheck(false)
    } else if (textInput.current.value !== '') {
      setValueCheck(validateInput(name, textInput.current.value))
    }
  }

  // prevent reset on select focus.
  const handleInputClick = () => {
    !options?.length && setValue('')
  }

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //

  // should show label?
  const maybeShowLabel = type === 'date' || showLabel

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  // propagate value.
  useEffect(() => {
    onChange(value)
  }, [value])

  // only show if there's a name.
  if (!name) return null

  // return content.
  return (
    <Wrapper
      className={className}
      isDisabled={disabled}
      isFocused={focused}
      valueCheck={valueCheck}
      width={width}
      isHidden={hidden}
    >
      {maybeShowLabel ? (
        <LabelWrapper color="grey" type="caption">
          {placeholder}
        </LabelWrapper>
      ) : null}

      <PrefixElement prefix={prefix} />

      <InputWrapper>
        <IconElement icon={iconL} />

        {options ? (
          <SelectWrapper hasLabel={maybeShowLabel}>
            <SelectElement
              ref={textInput}
              disabled={disabled}
              required={required}
              hidden={hidden}
              name={name}
              value={value || 'undefined'}
              onKeyUp={handleInputChange}
              onChange={handleInputChange}
              onClick={handleInputClick}
              onBlur={handleInputBlur}
            >
              <option disabled value="undefined">
                {placeholder}
              </option>

              {options.map(({ name, label }, index) => (
                <option key={index} value={name}>
                  {label}
                </option>
              ))}
            </SelectElement>
            <AbsoluteIconWrapper>
              <IconElement icon="caret-down" />
            </AbsoluteIconWrapper>
          </SelectWrapper>
        ) : (
          <InputElement
            ref={textInput}
            disabled={disabled}
            required={required}
            hidden={hidden}
            name={name}
            placeholder={placeholder}
            hasLabel={maybeShowLabel}
            type={type}
            value={value}
            onKeyUp={handleInputChange}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            autoComplete="new-password" // prevent autocomplete all data
            min={min}
            max={max}
          />
        )}

        <IconElement icon={iconR} />
      </InputWrapper>
    </Wrapper>
  )
}

export default Input
