// dependencies.
import { useState } from 'react'
import styled from 'styled-components'
// components.
import { Button } from '../Button'
import { BranchSelect, Input } from '../Form'
import { Icon } from '../Icon'
import { Body } from '../Typography'
// utils.
import setBackgroundColor from '../../js/setBackgroundColor'
import { theme } from '../../styleguide'
import { useDimensions } from '../../js/hooks'
import { CSVLink } from 'react-csv'
import { writeFile, utils } from 'xlsx'
import { useAppSelector } from '../../js/store/hooks'
import { selectUserData } from '../../js/auth/authSelectors'

/************************/
/*                      */
/*    Search Heading    */
/*                      */
/************************/

// partials.
const Wrapper = styled.div({
  alignItems: (props) => (props.isMobile ? 'flex-end' : 'center'),
  backgroundColor: (props) => props.background,
  display: 'flex',
  flexDirection: (props) => (props.isMobile ? 'column' : 'row'),
  justifyContent: (props) => (props.isMobile ? 'center' : 'flex-end'),
  margin: 0,
  padding: (props) => (props.isMobile ? '8px' : '11px 16px'),
  width: '100%',
  gap: (props) => (props.isTablet ? '8px' : '16px'),
})

const InputWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '8px',
  flexGrow: '1',
})

const StyledInput = styled(Input)({
  width: '100%',
})

const StyledButton = styled(Button)({
  padding: 0,
  height: '48px',
  minWidth: '48px',
  width: (props) => (props.isMobile ? '100%' : 'unset'),
})

const StyledActionButton = styled(Button)({
  padding: 0,
  height: '48px',
  minWidth: '48px',
  width: (props) => (props.isMobile ? '48px' : 'unset'),
})

const InputContainer = styled.div({
  display: 'flex',
  width: '40%',
  gap: (props) => (props.isTablet ? '8px' : '16px'),
})

const Divider = styled.hr({
  border: '0',
  width: '1px',
  height: '32px',
  backgroundColor: theme.colors.ash,
})

const ActionButtonWrapper = styled.div({
  maxWidth: '250px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '8px',
  alignContent: 'center',
  alignItems: 'center',
})

const ReportWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  width: (props) => (props.isMobile ? '100%' : 'unset'),
})

// main component.
const FilterHeader = ({
  exportReport,
  label = 'Nuevo',
  onChange = () => {},
  onFilterClick,
  onActionClick,
  header = [],
  items = [],
  selectLocationItems = null,
  filename = 'reporte',
  placeholder = 'Buscar...',
  selectInput,
  search = true,
  ActionButtonElement = null,
}) => {
  // hooks.
  const userData = useAppSelector(selectUserData)
  const { isMobile, isTablet } = useDimensions()
  // states
  const [fromDate, setFromDate] = useState()
  const [toDate, setToDate] = useState()
  const [selectInputValue, setSelectInputValue] = useState()

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //
  // Prepare data for CSV
  const csvHeaders = header.map((h) => h.label)
  const csvData = items.map((item) => {
    return item.items.map((subItem) => subItem.copy)
  })

  const case1 = onFilterClick && exportReport
  const case2 = !onFilterClick && !exportReport && onActionClick
  //  const case3 = onFilterClick && !exportReport && !onActionClick

  const conditionalStyles = {
    case1: {
      search: { width: isMobile ? '100%' : '20%' },
      filters: { width: isMobile ? '100%' : '35%' },
    },
    case2: {
      search: { width: isMobile ? '100%' : '50%' },
      filters: { width: isMobile ? '100%' : '35%' },
    },
    case3: {
      search: { width: isMobile ? '100%' : '50%' },
      filters: { width: isMobile ? '100%' : '50%' },
    },
  }

  const searchConditionStyle = case1
    ? conditionalStyles.case1.search
    : case2
      ? conditionalStyles.case2.search
      : conditionalStyles.case3.search

  const filtersConditionStyle = case1
    ? conditionalStyles.case1.filters
    : case2
      ? conditionalStyles.case2.filters
      : conditionalStyles.case3.filters

  const handleExportXLSX = () => {
    const data = [csvHeaders].concat(csvData)
    const ws = utils.aoa_to_sheet(data)
    const wb = utils.book_new()
    utils.book_append_sheet(wb, ws, 'Tarjetas')
    writeFile(wb, `Reporte-${new Date().toLocaleDateString()}.xlsx`)
  }

  // return content.
  return (
    <Wrapper
      background={setBackgroundColor(userData.isAuditor ? 'auditor' : userData.access, true)}
      isMobile={isMobile}
      isTablet={isTablet}
    >
      {(search || selectInput || selectLocationItems) && (
        <InputWrapper style={searchConditionStyle}>
          {selectInput?.name && (
            <Input
              {...selectInput}
              onChange={(value) => {
                setSelectInputValue(value)
              }}
            />
          )}

          {selectLocationItems && (
            <BranchSelect
              isMobile={isMobile}
              placeholder={placeholder}
              items={selectLocationItems}
            />
          )}

          {search && (
            <StyledInput
              isMobile={isMobile}
              name="search"
              iconL="search"
              placeholder={placeholder}
              onChange={(value) => onChange(value)}
            />
          )}

          {onActionClick &&
            (isMobile ? (
              <StyledActionButton onClick={onActionClick} hideLabel={isMobile} isMobile={isMobile}>
                {isMobile ? <Icon name="plus" color="white" size="24" /> : <>{label}</>}
              </StyledActionButton>
            ) : (
              <ActionButtonWrapper>
                {!isMobile && <Divider />}
                <Button onClick={onActionClick} width="100%">
                  {label}
                </Button>
              </ActionButtonWrapper>
            ))}
        </InputWrapper>
      )}

      {onFilterClick && (
        <>
          {(search || selectInput) && !isMobile && <Divider />}
          <InputContainer isTablet={isTablet} style={filtersConditionStyle}>
            <Input
              name="fromDate"
              placeholder="Desde"
              showLabel
              onChange={(value) => setFromDate(value)}
              type="date"
            />
            <Input
              name="toDate"
              placeholder="Hasta"
              showLabel
              onChange={(value) => setToDate(value)}
              type="date"
            />
          </InputContainer>
          <StyledButton
            isMobile={isMobile}
            onClick={() =>
              onFilterClick({
                fromDate,
                toDate,
                selectInputValue,
              })
            }
          >
            Filtrar
          </StyledButton>
        </>
      )}

      {exportReport && (
        <ReportWrapper isMobile={isMobile}>
          {!isMobile && <Divider />}
          <Body type="caption" color="white">
            Exportar:
          </Body>
          {/* <StyledButton isMobile={isMobile} onClick={() => window.print()} background="alert">

          </StyledButton> */}
          {csvHeaders && csvData && (
            <StyledButton isMobile={isMobile} onClick={() => handleExportXLSX()} background="alert">
              XLSX
            </StyledButton>
          )}

          {csvHeaders && csvData && (
            <CSVLink
              className="link-reset"
              style={{
                textDecoration: 'none',
                color: 'inherit',
                width: isMobile ? '100%' : 'unset',
              }}
              filename={`${filename}.csv`}
              data={csvData}
              headers={csvHeaders}
            >
              <StyledButton background="success">CSV</StyledButton>
            </CSVLink>
          )}
        </ReportWrapper>
      )}

      {!search &&
        !selectInput &&
        !selectLocationItems &&
        (onActionClick || ActionButtonElement) && (
          <ActionButtonWrapper>
            {ActionButtonElement !== null ? (
              ActionButtonElement
            ) : (
              <Button onClick={onActionClick} width="100%">
                {label}
              </Button>
            )}
          </ActionButtonWrapper>
        )}
    </Wrapper>
  )
}

export default FilterHeader
