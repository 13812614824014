// dependencies.
import styled from 'styled-components'
// components.
import { Body, Label, Pill } from '../../../Typography'
import { Icon } from '../../../Icon'
// utils.
import { getNameFull } from '../../../../js/usernameUtils'
import { theme } from '../../../../styleguide'
// constants.

// helpers
const baseUnit = theme.fontSize.x16

// partials.
const Wrapper = styled.li({
  backgroundColor: theme.colors.white,
  border: `1px solid ${theme.opacity.ash30}`,
  borderRadius: baseUnit / 2,
  display: 'flex',
  filter: `drop-shadow(${theme.shadows.smaller})`,
  flexDirection: 'column',
  gap: baseUnit / 2,
  minHeight: baseUnit * 4,
  padding: baseUnit / 2,
  width: '100%',
})

const Header = styled.div({
  alignItems: 'center',
  borderBottom: `1px solid ${theme.opacity.ash30}`,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingBottom: baseUnit / 2 - 1,
  width: '100%',
})

const Content = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
})

const ContentLeft = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: baseUnit / 2 - 2,
  justifyContent: 'flex-start',
})

const ContentRight = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
})

const Footer = styled.div({
  alignItems: 'center',
  border: `1px solid ${theme.opacity.ash50}`,
  borderRadius: baseUnit / 2,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  margin: 0,
  padding: '2px',
  width: '100%',
})

const StyledBody = styled(Body)({
  alignItems: 'center',
  display: 'flex',
  gap: baseUnit / 2,
})

const StyledEdit = styled(Label)({
  background: (props) => (props.disabled ? theme.colors.smoke : theme.colors.warning),
  border: (props) =>
    props.disabled ? `1px solid ${theme.colors.white}` : `1px solid ${theme.colors.warning}`,
  borderRadius: baseUnit / 2 - 2,
  display: 'flex',
  fontSize: theme.fontSize.x12,
  gap: 4,
  padding: '3px 6px 3px 8px',
  width: 'fit-content',
})

const StyledRecover = styled(Label)({
  background: (props) => (props.disabled ? theme.colors.smoke : theme.colors.success),
  border: (props) =>
    props.disabled ? `1px solid ${theme.colors.white}` : `1px solid ${theme.colors.success}`,
  borderRadius: baseUnit / 2 - 2,
  display: 'flex',
  fontSize: theme.fontSize.x12,
  gap: 4,
  padding: '3px 6px 3px 8px',
  width: 'fit-content',
})

// main component.
const UsersMobileItem = ({ item, onEdit, onForgotPassword }) => {
  // only show if there's items.
  if (!item) return null

  const { firstname, lastname, document, access, email, isActive, pendingActivation } = item
  const enableEdit = Boolean(onEdit)

  // return content.
  return (
    <Wrapper>
      <Header>
        <Body size="smaller" nowrap>
          Nombre completo: {getNameFull(firstname, lastname)}
        </Body>
      </Header>

      <Content>
        <ContentLeft>
          <StyledBody size="smaller" nowrap>
            {/* <Icon name="user" color="black" size={16} /> */}
            <span>Documento: {document}</span>
          </StyledBody>
          <StyledBody size="smaller" nowrap>
            {/* <Icon name="user" color="black" size={16} /> */}
            <span>Email: {email}</span>
          </StyledBody>
          <StyledBody size="smaller" nowrap>
            {/* <Icon name="user" color="black" size={16} /> */}
            <span>
              Acceso:{' '}
              {access === 'superadmin'
                ? 'Super admin'
                : access === 'admin'
                  ? 'Administrador'
                  : 'Funcionario'}
            </span>
          </StyledBody>
        </ContentLeft>

        <ContentRight>
          <Pill type={isActive === false ? 0 : pendingActivation ? 1 : 2}>
            {isActive === false ? 'Inactivo' : pendingActivation ? 'Pendiente' : 'Activo'}
          </Pill>
        </ContentRight>
      </Content>

      <Footer>
        <StyledRecover
          color="white"
          disabled={!isActive || pendingActivation}
          onClick={onForgotPassword}
        >
          Restablecer contraseña
          <Icon name="user" size={16} color={!isActive ? 'ash' : 'white'} />
        </StyledRecover>
        <StyledEdit color="white" disabled={!enableEdit} onClick={enableEdit && onEdit}>
          Editar
          <Icon name="edit" size={16} color={!enableEdit ? 'ash' : 'white'} />
        </StyledEdit>
      </Footer>
    </Wrapper>
  )
}

export default UsersMobileItem
