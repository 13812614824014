// dependencies.
import styled from 'styled-components'
// components.
import { Body } from '../../../Typography'
// utils.
import { theme } from '../../../../styleguide'
// constants.
import { HEADER_HEIGHT_DESKTOP } from '../../../../constants'

// helpers
const baseUnit = theme.fontSize.x16

// partials.
const ScrollWrapper = styled.div(
  {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    margin: 0,
    minHeight: `${HEADER_HEIGHT_DESKTOP * 2}px`,
    overflowX: 'scroll',
    transition: 'all 200ms ease',
    width: '100%',
  },
  `@media print { margin: 0; width: 100%; }`
)

const ContentWrapper = styled.ul({
  backgroundColor: theme.colors.white,
  display: 'flex',
  flexDirection: 'column',
  listStyle: 'none',
  margin: 0,
  minWidth: '100%',
  paddingBottom: baseUnit * 2,
  paddingHorizontal: 0,
  paddingTop: baseUnit * 2,
  position: 'relative',
  width: 'fit-content',
})

// main component.
const AnomaliesDesktopWrapper = ({ isEmpty, children }) => (
  <ScrollWrapper>
    <ContentWrapper>
      {isEmpty ? (
        <Body color="grey" align="center">
          No se encontraron resultados.
        </Body>
      ) : (
        children
      )}
    </ContentWrapper>
  </ScrollWrapper>
)

export default AnomaliesDesktopWrapper
