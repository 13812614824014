import { collection, orderBy, query } from 'firebase/firestore'
import { db } from '../firebase/firebase'
import { createSlice } from '@reduxjs/toolkit'
import { createFetchThunk } from '../store/fetch'

const q = query(collection(db, 'audits'), orderBy('deadline', 'asc'))
const access = ['superadmin', 'admin', 'operator', 'developer']

export const fetchAudits = createFetchThunk('audits', q, access)

const initialState = {
  // fromDate: dateFormatUS(twoMonthAgo),
  fromDate: '',
  toDate: '',
  locationId: '',
  searchTerm: '',
  auditorId: '',
  personId: '',
  isContractor: 'all',
  monthlyNegativeAuditsByPerson: {
    personId: 'all',
    itemId: 'all',
    eppId: 'epp',
  },
  sort: {
    key: 'deadline',
    direction: 'caret-up',
  },
}
const auditsSlice = createSlice({
  name: 'audits',
  initialState: {
    data: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    filters: {
      ...initialState,
    },
  },
  reducers: {
    auditUpdated: (state, action) => {
      const index = state.data.findIndex((audit) => audit.id === action.payload.id)
      if (index !== -1) {
        if (action.payload.status === 2) {
          state.data.push(action.payload)
          state.data.splice(index, 1)
        } else {
          state.data[index] = action.payload
        }
      }
    },
    setFilters: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      }
    },
    setMonthlyNegativeAuditsByPersonFilters: (state, action) => {
      state.filters.monthlyNegativeAuditsByPerson = {
        ...state.filters.monthlyNegativeAuditsByPerson,
        ...action.payload,
      }
    },
    clearFilters: (state) => {
      state.filters = initialState
    },
    removeFilter: (state, action) => {
      const { payload } = action
      if (payload === 'fromDate' || payload === 'toDate') {
        // preserve date filters
        // delete state.filters.fromDate
        // delete state.filters.toDate
      } else {
        delete state.filters[payload]
      }
    },
    reload: (state) => {
      state.status = 'idle'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAudits.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchAudits.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.data = action.payload
      })
      .addCase(fetchAudits.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  },
})

export const {
  auditUpdated,
  setFilters,
  setMonthlyNegativeAuditsByPersonFilters,
  clearFilters,
  removeFilter,
  reload,
} = auditsSlice.actions
export default auditsSlice.reducer
