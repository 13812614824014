// dependencies.
import styled from 'styled-components'
// components.
import { Body } from '../Typography'
import { Input } from '../Form'
// utils.
import { useDimensions } from '../../js/hooks'

/************************/
/*                      */
/*    Search Heading    */
/*                      */
/************************/

// partials.
const Wrapper = styled.div({
  display: 'flex',
  flexDirection: (props) => (props.column ? 'column' : 'row'),
  gap: (props) => (props.isTablet ? '8px' : '16px'),
  width: (props) => (props.column ? '100%' : 'fit-content'),
})
const personOptions = [
  { name: 'all', label: 'Todos' },
  { name: 'int', label: 'CCU' },
  { name: 'ext', label: 'Contratista' },
]

// main component.
const FilterPerson = ({ label, defaultValue, onPersonChange = () => {} }) => {
  const { isMobile, isTablet } = useDimensions()

  // ------------------------------------------------------------------------------------------- //
  // CONTENT                                                                                     //
  // ------------------------------------------------------------------------------------------- //
  // return content.
  return (
    <Wrapper column={!!label} isTablet={isTablet}>
      {label && (
        <Body type="caption" color="grey">
          {label}
        </Body>
      )}
      <Input
        isMobile={isMobile}
        options={personOptions}
        name="isContractor"
        iconL="user"
        placeholder="Observados"
        defaultValue={defaultValue || 'all'}
        onChange={(value) => onPersonChange(value)}
      />
    </Wrapper>
  )
}

export default FilterPerson
