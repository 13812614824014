// dependencies.
import styled from 'styled-components'
// utils.
import { theme } from '../../../../styleguide'
import { Body } from '../../../Typography'
import { useDimensions } from '../../../../js/hooks'

// helpers
const baseUnit = theme.fontSize.x16

const StyledWrapper = styled.table({
  borderCollapse: 'collapse',
  marginTop: baseUnit / 2,
  width: '100%',
})

const StyledHeader = styled.th({
  borderBottom: `2px solid ${theme.opacity.ash80}`,
  fontFamily: 'Roboto, sans-serif',
  fontSize: `${theme.fontSize.x14}px`,
  padding: `${baseUnit / 2}px`,
  textAlign: (props) => (props.align ? props.align : 'center'),
})

const StyledCell = styled.td({
  borderBottom: (props) => (props.isLast ? 'none' : `1px solid ${theme.opacity.ash80}`),
  fontFamily: 'Roboto, sans-serif',
  fontSize: `${theme.fontSize.x16}px`,
  padding: baseUnit / 2,
  textAlign: (props) => (props.align ? props.align : 'center'),
})

const StyledNumber = styled(Body)({
  alignItems: 'center',
  background: (props) => props.background,
  borderRadius: baseUnit,
  display: 'flex',
  filter: `drop-shadow(${theme.shadows.smaller})`,
  height: baseUnit * 1.5,
  justifyContent: 'center',
  margin: '0 auto',
  maxHeight: baseUnit * 1.5,
  minWidth: baseUnit * 1.5,
  padding: `0 ${baseUnit / 2}px`,
  width: 'fit-content',
})

function AuditorDeadlinesTable({ auditors = [] }) {
  const { isTablet, width } = useDimensions()

  if (!auditors.length) return null

  return (
    <StyledWrapper>
      <thead>
        <tr>
          <StyledHeader align="left">Auditor</StyledHeader>
          <StyledHeader>Ppto.</StyledHeader>
          <StyledHeader>Hechas</StyledHeader>
          <StyledHeader>En tiempo</StyledHeader>
          {width <= 1440 ? null : (
            <>
              <StyledHeader>Total</StyledHeader>
              <StyledHeader>Pend.</StyledHeader>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {auditors.map((auditor, i) => {
          const isLast = i === auditors.length - 1
          return (
            <tr key={`${auditor.uid}-${i}`}>
              <StyledCell align="left" isLast={isLast}>
                <Body size={isTablet ? 'smallest' : 'smaller'}>{auditor.name}</Body>
              </StyledCell>
              <StyledCell isLast={isLast}>
                <StyledNumber
                  background={
                    auditor.done === auditor.budget
                      ? theme.opacity.success10
                      : auditor.done === 0
                        ? theme.opacity.alert10
                        : theme.opacity.warning10
                  }
                >
                  {auditor.budget}
                </StyledNumber>
              </StyledCell>
              <StyledCell isLast={isLast}>
                <StyledNumber
                  background={
                    auditor.done === auditor.budget
                      ? theme.opacity.success10
                      : auditor.done === 0
                        ? theme.opacity.alert10
                        : theme.opacity.warning10
                  }
                >
                  {auditor.done}
                </StyledNumber>
              </StyledCell>
              <StyledCell isLast={isLast}>
                <StyledNumber weight="bold" color={auditor.onTime > 0 ? 'success' : 'alert'}>
                  {auditor.onTime}
                </StyledNumber>
              </StyledCell>
              {width <= 1440 ? null : (
                <>
                  <StyledCell isLast={isLast}>
                    <StyledNumber color="grey">{auditor.total}</StyledNumber>
                  </StyledCell>
                  <StyledCell isLast={isLast}>
                    <StyledNumber color="grey">{auditor.pending}</StyledNumber>
                  </StyledCell>
                </>
              )}
            </tr>
          )
        })}
      </tbody>
    </StyledWrapper>
  )
}

export default AuditorDeadlinesTable
